<div class="min-w-102 mt-0 md:mt-4 relative rounded shadow-sm overflow-hidden border-primary border">
  <button
    (click)="minus($event)"
    class="absolute left-0 top-0 bg-primary h-34px w-34px inline-flex items-center justify-center hover:bg-secondary cursor-pointer"
  >
    <svg class="text-white h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path fill-rule="evenodd" d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd"></path>
    </svg>
  </button>
  <input
    tabindex="-1"
    [id]="articleId"
    [formControl]="formQuantity"
    (change)="setNewQuantity()"
    type="number"
    name="quantity"
    class="js-number border-0 text-center px-40px py-1 block w-full h-34px overflow-hidden text-sm placeholder:text-gray-700"
  />
  <button
    (click)="plus($event)"
    class="absolute right-0 top-0 bg-primary h-34px w-34px inline-flex items-center justify-center group hover:bg-secondary cursor-pointer"
  >
    <svg class="text-white h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path
        fill-rule="evenodd"
        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
        clip-rule="evenodd"
      ></path>
    </svg>
  </button>
</div>
