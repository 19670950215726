import { Inject, Injectable } from '@angular/core';
import { Label, LABEL_PRINT_CONFIG, LabelMapperInterface, LabelPrintConfig, MapperAbstract } from '@lobos/label-print';
import {
  Article,
  AuthService,
  CrudService,
  FavoriteItem,
  FavoriteList,
  FavoriteService,
  Price,
  PriceQuery,
  PriceService,
  User,
} from '@lobos/library';
import { TranslocoDatePipe, TranslocoDecimalPipe, TranslocoLocaleService } from '@ngneat/transloco-locale';
import { combineLatest, forkJoin, iif, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ArticleHelperService } from '../../catalog/article-helper.service';
import { ImageHelperService } from '../../catalog/image-helper.service';

export interface CustomerArticleID {
  lngCustomerID: number;
  sArticleID: string;
  sArticleName: string;
  sBranchKey: string;
  sCustomerArticleID: string;
  shtPListVariant: number;
  sInfo: string;
  sItemText: string;
}

@Injectable()
export class SuedoFavoriteMapper extends MapperAbstract implements LabelMapperInterface {
  constructor(
    @Inject(LABEL_PRINT_CONFIG) private config: LabelPrintConfig,
    private date: TranslocoDatePipe,
    private decimal: TranslocoDecimalPipe,
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
    private imageHelper: ImageHelperService,
    private articleHelper: ArticleHelperService,
    private priceService: PriceService,
    private priceQuery: PriceQuery,
    private translocoLocaleService: TranslocoLocaleService,
    private crudService: CrudService,
    private authService: AuthService,
  ) {
    super();
  }

  public filename(id: string): Observable<string> {
    return this.favoriteService
      .getFavoriteListById(id)
      .pipe(map((list: FavoriteList | undefined) => list!.sListname + '.pdf' || 'print.pdf'));
  }

  public map(id: string): Observable<Label[]> {
    return this.favoriteService.getFavoriteItems(id).pipe(
      switchMap((favorites: FavoriteItem[]) =>
        forkJoin(
          favorites.map((favorite: FavoriteItem) =>
            combineLatest([
              of(favorite),
              of(undefined).pipe(
                switchMap(() =>
                  iif(
                    () => this.priceQuery.hasEntity(favorite.oArticle?.sArticleID),
                    this.priceQuery.selectEntity(favorite.oArticle?.sArticleID),
                    this.articleHelper
                      .getPriceFromApi(favorite.oArticle!)
                      .pipe(switchMap(() => this.priceQuery.selectEntity(favorite.oArticle?.sArticleID))),
                  ),
                ),
                first(),
              ),
              this.authService.authUser$.pipe(
                switchMap((user: User | undefined) =>
                  this.crudService.getAll<CustomerArticleID>('/customer-article-ids', {
                    lngCustomerID: user?.lngCustomerID,
                    sArticleID: favorite.oArticle?.sArticleID,
                  }),
                ),
                first(),
              ),
            ]).pipe(
              map(([favorite, price, customerArticleIds]: [FavoriteItem, Price | undefined, any]) =>
                this.mapFavoriteToLabel(favorite, price, customerArticleIds[0]),
              ),
            ),
          ),
        ),
      ),
    );
  }

  private mapFavoriteToLabel(
    favorite: FavoriteItem,
    priceInfo: Price | undefined,
    customerArticleID: CustomerArticleID | undefined,
  ): Label {
    const article: Article = favorite.oArticle!;
    const price = this.translocoLocaleService.localizeNumber(
      priceInfo?.decAmountNetFC || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );
    const discountedFrom = priceInfo?.decSpecialPriceNet ? this.decimal.transform(priceInfo.decBasePriceNet) : undefined;

    const inclNet = this.translocoLocaleService.localizeNumber(
      priceInfo?.decAmountGrossFC || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    const inclGross = this.translocoLocaleService.localizeNumber(
      priceInfo?.decBasePriceGross || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    const exclGross = this.translocoLocaleService.localizeNumber(
      priceInfo?.decBasePriceNet || 0,
      'currency',
      this.translocoLocaleService.getLocale(),
      { currency: priceInfo?.sCurrencyCode },
    );

    return {
      data: {
        code: article.sEAN,
        sku: article.sArticleID as string,
        title: article.sName,
        description: article.sDescription,
        price,
        discountedFrom,
        date: this.date.transform(Date.now()),
        unit: `${priceInfo?.lngSalesPriceUnit} ${priceInfo?.sQuantityUnit}`,
        currency: priceInfo?.sCurrencyCode,
        logoPath: this.config.logoPath,
        imgPath: this.imageHelper.getArticleMainImage(article)?.toURL(),
        customFields: {
          inclNet: inclNet,
          inclGross: inclGross,
          exclGross: exclGross,
          customerArticleID: customerArticleID?.sCustomerArticleID ? customerArticleID.sCustomerArticleID : '',
          articleType: article.sArticleCode1,
        },
      },
      amount: 1,
      print: false,
      type: 'data',
    };
  }
}
