import { Pipe, PipeTransform } from '@angular/core';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { ImageHelperService } from '../../services/catalog/image-helper.service';
import { Product } from '@lobos/library';

@Pipe({
  name: 'ProductManufacturerLogo',
})
export class ProductManufacturerLogoPipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(product: Product): CloudinaryImage {
    return this.imageHelperService.getProductManufacturerLogo(product.oResources)!;
  }
}
