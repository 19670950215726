import { Component } from '@angular/core';
import { CmsFooterContent } from '../footer.component';
import { Observable } from 'rxjs';
import { CmsUiDynamicContentQuery } from '@lobos/library';

@Component({
  selector: 'app-footer-address',
  templateUrl: './footer-address.component.html',
  styleUrls: ['./footer-address.component.scss'],
})
export class FooterAddressComponent {
  footer$: Observable<CmsFooterContent> = this.cmsQuery.footer$ as any;
  constructor(private cmsQuery: CmsUiDynamicContentQuery) {}
}
