<div class="mt-4" *transloco="let t; scope: 'checkout'">
  <label class="block text-sm font-bold mb-1"> {{ t("checkout.address.form.delivery-date") }} * </label>
  <mat-form-field id="delivery-date-field">
    <ng-container *ngIf="form" [formGroup]="form"
      ><input
        matInput
        [matDatepicker]="$any(deliveryDatePicker)"
        [formControlName]="name"
        [name]="name"
        [matDatepickerFilter]="boundDisableWeekend"
        [min]="getNextBusinessDay()"
        (click)="deliveryDatePicker.open()"
    /></ng-container>
    <mat-datepicker-toggle matSuffix [for]="deliveryDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #deliveryDatePicker></mat-datepicker>
    <mat-error *ngIf="hasError && errorText">
      <p class="font-primary text-sm text-error">{{ errorText }}</p>
    </mat-error>
  </mat-form-field>
</div>
