import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Article, CartItem, PriceService, SalesCreditNoteItem, SalesOfferItem, SalesOrderItem } from '@lobos/library';
import { Observable, of } from 'rxjs';
import { SuedoPrice } from './model/suedo-price';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ArticleHelperService {
  constructor(private priceService: PriceService<SuedoPrice>, @Inject(PLATFORM_ID) private _platformId: any) {}

  public static itemIsMaster(item: CartItem | SalesOrderItem | SalesOfferItem | SalesCreditNoteItem): boolean {
    if (item.shtFixedItemIDItemGroup) {
      return item.shtFixedItemIDItemGroup === 0 || item.shtFixedItemIDItemGroup === item.shtFixedItemID;
    } else {
      return true;
    }
  }

  public static itemIsChildOf(
    child: CartItem | SalesOrderItem | SalesOfferItem | SalesCreditNoteItem,
    master: CartItem | SalesOrderItem | SalesOfferItem | SalesCreditNoteItem,
  ): boolean {
    if (child.shtFixedItemIDItemGroup) {
      return (
        child.shtFixedItemIDItemGroup !== 0 &&
        child.shtFixedItemIDItemGroup === master.shtFixedItemIDItemGroup &&
        child.shtFixedItemIDItemGroup !== child.shtFixedItemID
      );
    } else {
      return false;
    }
  }

  public getPriceFromApi(article: Article): Observable<SuedoPrice | undefined> {
    if (isPlatformServer(this._platformId) || !article) {
      return of(undefined);
    }

    return this.priceService.get(
      article.sArticleID,
      article.sQuantityUnitSales,
      'CHF',
      article.lngSalesPriceUnit > 0 ? article.lngSalesPriceUnit : 1,
      article.decFactor1,
      article.decFactor2,
      article.decFactor3,
    );
  }
}
