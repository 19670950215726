import { Injectable } from '@angular/core';
import {
  AuthChangedHook,
  CartHeader,
  CartHeaderLocalStore,
  CartHeaderStore,
  CartItem,
  CartItemLocalStore,
  CartItemStore,
  Contact,
  ContactStore,
  HookEvent,
  HookHandlerAbstract,
  HookPriority,
  PriceStore,
  RequestDebounceStore,
  SalesCreditNote,
  SalesCreditNoteStore,
  SalesOffer,
  SalesOfferStore,
  SalesOrder,
  SalesOrderStore,
  ShippingAddress,
  ShippingAddressStore,
  User,
  UserStore,
} from '@lobos/library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

type type = AuthChangedHook;

/**
 * This hook resets the Price-Store, whenever the user changes.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationChangedResetStoresHandler extends HookHandlerAbstract<type, Observable<User>> {
  constructor(
    private priceStore: PriceStore,
    private requestDebounceStore: RequestDebounceStore,
    private userStore: UserStore<User>,
    private shippingAddressStore: ShippingAddressStore<ShippingAddress>,
    private salesOrderStore: SalesOrderStore<SalesOrder>,
    private salesOfferStore: SalesOfferStore<SalesOffer>,
    private salesCreditNoteStore: SalesCreditNoteStore<SalesCreditNote>,
    private contactStore: ContactStore<Contact>,
    private cartItemLocalStore: CartItemLocalStore<CartItem>,
    private cartHeaderLocalStore: CartHeaderLocalStore<CartHeader>,
    private cartItemStore: CartItemStore<CartItem>,
    private cartHeaderStore: CartHeaderStore<CartHeader>,
  ) {
    super();
  }

  ids(): type[] {
    return [{ type: 'AuthChangedHook' }];
  }

  override priority(): number {
    return HookPriority.HIGHEST;
  }

  override after(event: HookEvent<type, Observable<User>>, result: Observable<User>): Observable<User> {
    return super.after(event, result).pipe(
      tap(() => {
        this.priceStore.reset();
        this.requestDebounceStore.reset();
        this.userStore.reset();
        this.shippingAddressStore.reset();
        this.salesOrderStore.reset();
        this.salesOfferStore.reset();
        this.salesCreditNoteStore.reset();
        this.contactStore.reset();
        this.cartItemLocalStore.reset();
        this.cartHeaderLocalStore.reset();
        this.cartItemStore.reset();
        this.cartHeaderStore.reset();
      }),
    );
  }
}
