import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/ng';
import { A11yModule, AuthModule, LocalizeUrlPathModule } from '@lobos/library';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgFloorPipeModule, NgRoundPipeModule, NgWherePipeModule } from 'angular-pipes';
import { MarkdownModule } from 'ngx-markdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { ArticleAvailabilityComponent } from './article-availability/article-availability.component';
import { ArticleFavoriteComponent } from './article-favorite/article-favorite.component';
import { ArticleItemComponent } from './article-item/article-item.component';
import { ArticlePriceComponent } from './article-price/article-price.component';
import { ArticlePromoPriceComponent } from './article-promo-price/article-promo-price.component';
import { BreadcrumbOutletComponent } from './breadcrumb/breadcrumb-outlet.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { IconButtonComponent } from './button/icon-button/icon-button.component';
import { TextButtonComponent } from './button/text-button/text-button.component';
import { CardCategoryComponent } from './card-category/card-category.component';
import { FooterAddressComponent } from './footer/footer-address/footer-address.component';
import { FooterBottomComponent } from './footer/footer-bottom/footer-bottom.component';
import { FooterLogosComponent } from './footer/footer-logos/footer-logos.component';
import { FooterMenuComponent } from './footer/footer-menu/footer-menu.component';
import { FooterMenu2Component } from './footer/footer-menu2/footer-menu2.component';
import { FooterComponent } from './footer/footer.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { InputComponent } from './form/input/input.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { ToggleSwitchComponent } from './form/toggle-switch/toggle-switch.component';
import { HeaderComponent } from './header/header.component';
import { MainNavigationComponent } from './header/main-navigation/main-navigation.component';
import { MobileNavigationComponent } from './header/mobile-navigation/mobile-navigation.component';
import { SearchComponent } from './header/search/search.component';
import { CartFlyoutComponent } from './header/service-navigation/cart-flyout/cart-flyout.component';
import { ComparisonFlyoutComponent } from './header/service-navigation/comparison-flyout/comparison-flyout.component';
import { FavoritesFlyoutComponent } from './header/service-navigation/favorites-flyout/favorites-flyout.component';
import { ServiceNavigationComponent } from './header/service-navigation/service-navigation.component';
import { UserFlyoutComponent } from './header/service-navigation/user-flyout/user-flyout.component';
import { TopbarNavigationComponent } from './header/topbar-navigation/topbar-navigation.component';
import { LinkComponent } from './link/link.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { QuantityInputComponent } from './quantity-input/quantity-input.component';
import { ShellComponent } from './shell/shell.component';
import { FavoriteItemComponent } from './header/service-navigation/favorites-flyout/favorite-item/favorite-item.component';
import { ArticleCompareItemComponent } from './article-compare/article-compare-item/article-compare-item.component';
import { SalesRepBannerComponent } from './sales-rep-banner/sales-rep-banner.component';
import { ImageComponent } from './image/image.component';
import { SearchItemComponent } from './header/search-item/search-item.component';
import { ArticleScaledDiscountComponent } from './article-scaled-discount/article-scaled-discount.component';
import { ScannerComponent } from './scanner/scanner/scanner.component';
import { ScannerResultsComponent } from './scanner/scanner-results/scanner-results.component';
import { ScannerButtonComponent } from './scanner/scanner-button/scanner-button.component';
import { QtyModalComponent } from './qty-modal/qty-modal.component';
import { ToxinModalComponent } from './toxin-modal/toxin-modal.component';
import { CustomSelectComponent } from './form/custom-select/custom-select.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ArticleTranslatedQuantityUnitSalesComponent } from './article-translated-quantity-unit-sales/article-translated-quantity-unit-sales.component';
import { PasswordResetModalComponent } from './password-reset-modal/password-reset-modal.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { QtyModalInputComponent } from './quantity-input/qty-modal-input/qty-modal-input.component';
import { DatepickerComponent } from './form/datepicker/datepicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { CartErrorModalComponent } from './cart-error-modal/cart-error-modal.component';

const components: any[] = [
  ShellComponent,
  FooterComponent,
  HeaderComponent,
  BreadcrumbComponent,
  BreadcrumbOutletComponent,
  TopbarNavigationComponent,
  SearchComponent,
  ServiceNavigationComponent,
  MainNavigationComponent,
  MobileNavigationComponent,
  LoginModalComponent,
  InputComponent,
  TextButtonComponent,
  ArticleItemComponent,
  QuantityInputComponent,
  IconButtonComponent,
  LinkComponent,
  FooterAddressComponent,
  FooterMenuComponent,
  FooterLogosComponent,
  FooterBottomComponent,
  FooterMenu2Component,
  CardCategoryComponent,
  CheckboxComponent,
  ProductItemComponent,
  CustomSelectComponent,
  ToggleSwitchComponent,
  TextareaComponent,
  LoaderComponent,
  CartFlyoutComponent,
  UserFlyoutComponent,
  ComparisonFlyoutComponent,
  FavoritesFlyoutComponent,
  PaginationComponent,
  ArticlePriceComponent,
  ArticlePromoPriceComponent,
  ArticleAvailabilityComponent,
  ArticleFavoriteComponent,
  ModalConfirmComponent,
  FavoriteItemComponent,
  ArticleCompareItemComponent,
  SalesRepBannerComponent,
  ImageComponent,
  SearchItemComponent,
  ArticleScaledDiscountComponent,
  ScannerComponent,
  ScannerResultsComponent,
  ScannerButtonComponent,
  QtyModalComponent,
  QtyModalInputComponent,
  ToxinModalComponent,
  ArticleTranslatedQuantityUnitSalesComponent,
  PasswordResetModalComponent,
  CookieBannerComponent,
  DatepickerComponent,
  CartErrorModalComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    ReactiveFormsModule,
    PipesModule,
    TranslocoLocaleModule,
    NgFloorPipeModule,
    FormsModule,
    MarkdownModule,
    LocalizeUrlPathModule,
    CloudinaryModule,
    NgxPaginationModule,
    NgRoundPipeModule,
    A11yModule,
    DirectivesModule,
    NgWherePipeModule,
    NgxSkeletonLoaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    AuthModule,
  ],
  exports: components,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: '' }],
})
export class ComponentsModule {}
