import { Component } from '@angular/core';
import { AuthService, Catch, User } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { LanguageHelperService } from '../../../services/language/language-helper.service';
import { SettingsInterface, SettingsService } from '../../../services/settings';
import { LoginModalComponent } from '../../login-modal/login-modal.component';

@Component({
  selector: 'app-topbar-navigation',
  templateUrl: './topbar-navigation.component.html',
  styleUrls: ['./topbar-navigation.component.scss'],
})
export class TopbarNavigationComponent {
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public user$: Observable<User | undefined> = this.authService.authUser$;
  public alternative$ = this.languageHelper.alternative$.pipe(shareReplay(1));
  public currentVersionKey$ = this.transloco.langChanges$;

  constructor(
    private authService: AuthService,
    private languageHelper: LanguageHelperService,
    private settingsService: SettingsService<SettingsInterface>,
    private transloco: TranslocoService,
    private modal: SimpleModalService,
  ) {}

  openLoginModal(): void {
    this.modal.addModal(LoginModalComponent);
  }

  @Catch({ label: 'TopbarNavigationComponent.changeVersion()' })
  public async changeVersion(versionKey: string): Promise<void> {
    await await this.languageHelper.changeVersion(versionKey);
    window.location.reload();
  }
}
