import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ACCESS_ID, AuthService, Catch, UrlHelperService, User } from '@lobos/library';
import { SimpleModalService } from 'ngx-simple-modal';
import { Observable } from 'rxjs';
import { LoginModalComponent } from '../../../login-modal/login-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-flyout',
  templateUrl: './user-flyout.component.html',
  styleUrls: ['./user-flyout.component.scss'],
})
export class UserFlyoutComponent {
  @Input()
  public classes: string = '';

  @Output() userItemClicked: EventEmitter<any> = new EventEmitter<any>();

  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public user$: Observable<User | undefined> = this.authService.authUser$;

  accessId: typeof ACCESS_ID = ACCESS_ID;

  constructor(
    private authService: AuthService,
    private modal: SimpleModalService,
    private router: Router,
    private urlHelper: UrlHelperService,
  ) {}

  openLoginModal(): void {
    this.modal.addModal(LoginModalComponent);
  }

  @Catch({ label: 'HeaderComponent.logout()' })
  public async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate([this.urlHelper.localizeUrl('')]);
  }
}
