<div class="flex py-2">
  <a (click)="articleRouterLinkClicked($event)" [routerLink]="['article' | localizeUrlPathAsync | async, article.sArticleID]">
    <app-image [image]="article | articleMainImage" classes="w-62px" class="w-62px"></app-image>
  </a>
  <div class="w-full flex justify-between items-center flex-wrap md:flex-nowrap ml-1">
    <div class="md:max-w-230px w-full md:w-auto flex-1">
      <div>
        <a
          class="group"
          (click)="articleRouterLinkClicked($event)"
          [routerLink]="['article' | localizeUrlPathAsync | async, article.sArticleID]"
        >
          <div
            [ngClass]="article.sName.length >= 30 && article.sName.indexOf(' ') <= 2 ? 'break-all' : ''"
            class="font-bold group-hover:text-primary"
          >
            {{ article.sName }}
          </div>
          <div class="group-hover:text-primary">{{ article.sUniformNumber }}</div>
          <div class="group-hover:text-primary">
            <p>
              <app-article-price [article]="article" (zeroPrice)="setPriceIsZero($event)" [showPromotion]="true"></app-article-price>
            </p>
          </div>
        </a>
      </div>
    </div>
    <ng-container *ngIf="article.shtStatus !== 2 && !priceIsZero">
      <div class="w-1/2 md:w-28">
        <app-quantity-input (onQuantityChange)="quantityChanged($event)" [quantity]="quantity" [article]="article"></app-quantity-input>
      </div>
      <div class="relative group flex items-center md:mt-3.5">
        <app-article-scaled-discount [article]="article"></app-article-scaled-discount>
      </div>
      <button
        (click)="addArticleToCart(article)"
        [ngClass]="(isLoading$ | async) ? 'pointer-events-none' : ''"
        class="js-add-to-cart hover:text-primary p-2 md:mt-3.5"
      >
        <ng-container *ngIf="isLoading$ | async; else notLoading">
          <span class="loader"></span>
        </ng-container>

        <ng-template #notLoading>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 pointer-events-none"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </ng-template>
      </button>
    </ng-container>
  </div>
</div>
