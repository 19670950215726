import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Category } from '@lobos/library';

@Component({
  selector: 'app-card-category',
  templateUrl: './card-category.component.html',
  styleUrls: ['./card-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCategoryComponent {
  @Input() category!: Category;
}
