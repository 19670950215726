import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roundSwissFranc' })
export class RoundSwissFrancPipe implements PipeTransform {
  transform(value: number): number {
    if (value) {
      const result = (Math.round(value * 20) / 20).toFixed(2);
      return parseFloat(result);
    }
    return 0;
  }
}
