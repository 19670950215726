<ng-container *ngIf="crumbs$ | async as crumbs">
  <nav *ngIf="crumbs.length" class="js-breadcrumb bg-white border-b border-gray-500 flex fixed ios-fix w-full z-50" aria-label="Breadcrumb">
    <div class="max-w-screen-xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <ol role="list" class="flex space-x-4 overflow-x-auto scrollbar">
        <li class="flex">
          <div class="flex items-center">
            <a [routerLink]="['/' | localizeUrlPathAsync | async]" class="text-gray-700 hover:text-primary">
              <svg
                class="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                ></path>
              </svg>
              <span class="sr-only">Home</span>
            </a>
          </div>
        </li>
        <li *ngFor="let crumb of crumbs" class="flex">
          <ng-container *transloco="let t; scope: crumb.translate ? crumb.label.split('.')[0] : ''">
            <div class="flex items-center">
              <svg
                class="flex-shrink-0 w-6 h-full text-gray-500"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"></path>
              </svg>
              <a [routerLink]="crumb.url" class="ml-4 text-sm text-gray-700 hover:text-primary w-12 md:w-full truncate">{{
                crumb.translate ? t(crumb.label) : crumb.label
              }}</a>
            </div>
          </ng-container>
        </li>
      </ol>
    </div>
  </nav>
</ng-container>
