import { Pipe, PipeTransform } from '@angular/core';
import { ReferenceLink } from '@lobos/library';

@Pipe({
  name: 'articleDocuments',
})
export class ArticleDocumentsPipe implements PipeTransform {
  public transform(resources: ReferenceLink[]): ReferenceLink[] {
    return resources.filter(
      (ressource: ReferenceLink) => ressource.sTypeCode !== 'BILD' && ressource.sTypeCode !== 'LOGO' && ressource.sTypeCode !== 'YOUTUBE',
    );
  }
}
