import {
  Cart,
  CartHeader,
  CartItem,
  CartService,
  CreateCartItemInterface,
  HookEvent,
  HookHandlerAbstract,
  WorkflowCreateHook,
} from '@lobos/library';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { HandleQtyInput } from './util/car-item-qty-input-handler-util';

type type = WorkflowCreateHook<'CreateCartItemHook'>;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CartItemCreatedQtyInputHandlerHandler extends HookHandlerAbstract<type, Observable<Cart<CartHeader, CartItem> | undefined>> {
  constructor(private modal: SimpleModalService, private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateCartItemHook' }];
  }

  override before(
    event: HookEvent<type, Observable<Cart<CartHeader, CartItem> | undefined>>,
    _: HookEvent<type, Observable<Cart<CartHeader, CartItem> | undefined>>,
  ): () => Observable<Cart<CartHeader, CartItem> | undefined> {
    const oCartItem: CreateCartItemInterface = event.params[0];
    const callable = event.callable;
    return HandleQtyInput(oCartItem, callable, this.modal, this.cartService, true);
  }
}
