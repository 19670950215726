<div class="js-box group h-full md:min-h-350px relative z-10" data-minheight="350" data-hover-height="52">
  <div
    class="w-full js-box-height transition duration-200 relative md:absolute h-full overflow-hidden bg-white border border-gray-500 w-fullshadow-sm rounded-md flex justify-between flex-col md:block group-hover:shadow-xl"
  >
    <a [routerLink]="product.sUrlPath | urlPath" [queryParams]="product.sUrlPath | urlFilterParams" class="js-link flex flex-col">
      <div class="bg-white flex items-center justify-center py-4">
        <div class="sm:h-48 w-7/12 md:w-full flex">
          <app-image
            [image]="product | productMainImage"
            class="sm:w-auto sm:h-auto md:h-full sm:max-h-full mx-auto self-center"
            classes="sm:w-auto sm:h-auto md:h-full sm:max-h-full mx-auto self-center"
          ></app-image>
        </div>
      </div>
      <div class="flex-1 p-4 pb-2 space-y-2 flex flex-col">
        <h3
          class="min-h-74px font-bold leading-snug max-w-10/12 md:max-w-full text-lg font-bold line-clamp-none md:line-clamp-3 hyphens-auto transition duration-200 group-hover:text-primary"
        >
          {{ product.sTitle }}
        </h3>
        <div class="hidden md:block h-6"></div>
        <p class="text-base font-bold self-end" *transloco="let t; scope: 'shared'">{{ t("shared.product-item.multiple-variations") }}</p>
      </div>
    </a>
    <form class="js-box-hover -mt-2.5 py-4 px-4 flex flex-col md:hidden">
      <a
        [routerLink]="product.sUrlPath | urlPath"
        [queryParams]="product.sUrlPath | urlFilterParams"
        class="w-auto self-start inline-flex items-center px-2.5 md:px-3.5 py-1.5 rounded shadow-sm bg-white text-primary border border-primary hover:border-secondary hover:text-secondary focus:border-secondary"
      >
        <span class="text-base font-bold mx-auto" *transloco="let t; scope: 'shared'">{{ t("shared.product-item.button-title") }}</span>
      </a>
    </form>
  </div>
</div>
