<!-- start topbar navigation -->
<ng-container *transloco="let t; scope: 'core'">
  <div class="hidden md:block js-close-subnav bg-gray-200">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-1 flex justify-between items-center flex-col md:flex-row" aria-label="Topbar">
      <div class="hidden md:items-center w-full md:w-auto md:flex"></div>
      <div class="flex items-end justify-end w-full md:w-auto">
        <div *ngIf="currentVersionKey$ | async as currentVersionKey" class="ml-0 md:ml-10 space-x-4 flex items-center">
          <span *ngIf="alternative$ | async as alternativeVersions" class="relative z-0 inline-flex -ml-2 md:ml-0">
            <button
              *ngFor="let version of alternativeVersions; let last = last"
              (click)="changeVersion(version.key)"
              type="button"
              [class.border-r.border-black]="!last"
              [class.text-primary]="version.key === currentVersionKey"
              class="relative inline-flex items-center px-2 border-r border-black text-sm md:text-xs hover:text-primary focus:outline-none focus:text-primary"
            >
              {{ version.title }}
            </button>
          </span>

          <a [routerLink]="[t('core.header.download.link') | localizeUrlPathAsync | async]" class="text-xs hover:text-primary">
            {{ t("core.header.download.text") }}
          </a>
          <a
            *ngIf="(isLoggedIn$ | async) === false; else isAuthenticated"
            (click)="openLoginModal()"
            class="text-xs hover:text-primary cursor-pointer"
          >
            {{ t("core.header.menu.login") }}
          </a>
          <ng-template #isAuthenticated>
            <a
              [routerLink]="'profile/my-profile' | localizeUrlPathAsync | async"
              *ngIf="user$ | async as user"
              class="text-xs hover:text-primary"
              >{{ user.sFirstName + " " + user.sLastName }}</a
            >
          </ng-template>
        </div>
      </div>
    </nav>
  </div>
</ng-container>
<!-- end topbar navigation -->
