<div class="mt-4 md:mt-0 border-b border-white pb-4 md:pb-0 md:border-b-0">
  <ul class="flex flex-wrap">
    <li class="basis-1/2 mb-2">
      <a href="https://www.suedo.ch/de-de">
        <img src="../assets/images/layout/suedo-logo-weiss.png" alt="Südo AG" />
      </a>
    </li>
    <li class="basis-1/2 mb-2">
      <a href="https://www.suedo.ch/">
        <img src="../assets/images/layout/agl-logo-weiss.png" alt="AGL" />
      </a>
    </li>
    <li class="basis-1/2 mb-2">
      <a href="https://www.jasa-ag.ch/">
        <img src="../assets/images/layout/jasa-logo-weiss.png" alt="Jasa AG" />
      </a>
    </li>
    <li class="basis-1/2 mb-2">
      <a href="https://www.carsystem-ag.ch/">
        <img src="../assets/images/layout/carsystem-logo-weiss.png" alt="Carsystem AG" />
      </a>
    </li>
  </ul>
</div>
