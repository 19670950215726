<ng-container *transloco="let t; scope: 'core'"
  ><!-- start mobile navigation -->
  <div
    class="js-mobile-nav h-screen translate-x-full transition ease-in-out duration-300 transform xl:transition-none flex xl:hidden w-full min-h-screen fixed z-60 top-0 left-0 rounded-md shadow"
  >
    <div
      (click)="closeMobileMenu.emit($event)"
      class="js-close-nav-bg absolute block xl:hidden inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-0"
      aria-hidden="true"
    ></div>
    <div class="ml-auto w-10/12 mb-6 flex flex-col relative z-10 h-screen overflow-hidden">
      <!-- navigation -->
      <nav
        class="js-mobile-nav-height px-3 lg:px-8 lg:pt-3.5 pb-10 lg:pb-0 flex flex-col bg-white overflow-auto lg:overflow-hidden min-h-screen md:min-h-0"
      >
        <div class="flex items-center justify-end">
          <div class="-mr-2">
            <button
              (click)="closeMobileMenu.emit($event)"
              type="button"
              class="js-close-mobile-nav bg-white p-2 inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary"
            >
              <span class="sr-only">Close menu</span>
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
        </div>
        <div class="mt-2 sm:mt-4">
          <nav>
            <div>
              <app-service-navigation
                class="py-4 mb-2 flex flex-wrap justify-center space-x-6 z-40 relative"
                [cssClass0]="'block sm:hidden'"
                [cssClass1]="'block sm:hidden'"
                [cssClass2]="'block sm:hidden'"
                [cssClass3]="'block sm:hidden'"
                [cssClass4]="'block sm:hidden'"
                (serviceItemClicked)="closeMobileMenu.emit($event)"
              ></app-service-navigation>
            </div>
            <div class="flex lg:hidden flex-1 justify-center">
              <app-search
                class="relative z-30 w-full h-full md:h-46px js-header-search"
                (searchItemClicked)="closeMobileMenu.emit($event)"
              ></app-search>
            </div>
            <div *ngIf="currentVersionKey$ | async as currentVersionKey" class="mt-4 mb-8">
              <span *ngIf="alternative$ | async as alternativeVersions" class="relative z-0 inline-flex -ml-2 md:ml-0">
                <button
                  *ngFor="let version of alternativeVersions; let last = last"
                  (click)="changeVersion(version.key)"
                  type="button"
                  [class.border-r.border-black]="!last"
                  [class.text-primary]="version.key === currentVersionKey"
                  class="relative inline-flex items-center px-2 border-r border-black text-sm md:text-xs hover:text-primary focus:outline-none focus:text-primary"
                >
                  {{ version.title }}
                </button>
              </span>
            </div>
            <div class="mt-4 flex flex-col mb-14">
              <div class="mb-4" *ngFor="let navigation of navigationMenu">
                <div
                  [appToggleMenu]="{ subnav: '.js-mobile-level1', border: true }"
                  class="js-show-mobile-level1 flex items-center border-b border-black pb-4"
                >
                  <a [routerLink]="navigation.url" (click)="closeMobileMenu.emit($event)" class="inline-block hover:text-primary">
                    <div class="text-sm font-bold pointer-events-none">{{ navigation.title }}</div>
                  </a>
                  <button class="ml-auto hover:text-primary transform inline-block">
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path
                        fill-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div class="pl-4 js-mobile-level1 hidden">
                  <div class="mb-2" *ngFor="let secondLevelNavigation of navigation?.subNavigation">
                    <div [appToggleMenu]="{ subnav: '.js-mobile-level2', border: false }" class="js-show-mobile-level2 flex items-center">
                      <a
                        [routerLink]="secondLevelNavigation.url"
                        (click)="closeMobileMenu.emit($event)"
                        class="py-1 inline-block w-auto hover:text-primary"
                      >
                        <span class="text-sm font-bold">{{ secondLevelNavigation.title }}</span>
                      </a>
                      <button
                        class="ml-auto hover:text-primary transform inline-block"
                        *ngIf="secondLevelNavigation.subNavigation && secondLevelNavigation.subNavigation.length > 0"
                      >
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div class="pl-4 js-mobile-level2 hidden">
                      <a
                        *ngFor="let thirdLevelNavigation of secondLevelNavigation?.subNavigation"
                        [routerLink]="thirdLevelNavigation.url"
                        (click)="closeMobileMenu.emit($event)"
                        class="py-1 inline-block w-full hover:text-primary"
                      >
                        <span class="text-sm">{{ thirdLevelNavigation.title }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-4">
                <div class="js-show-mobile-level1 flex items-center border-b border-black pb-4">
                  <a
                    [routerLink]="[t('core.header.download.link') | localizeUrlPathAsync | async]"
                    (click)="closeMobileMenu.emit($event)"
                    class="inline-block hover:text-primary"
                  >
                    <div class="text-sm font-bold pointer-events-none">{{ t("core.header.download.text") }}</div>
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </nav>
    </div>
  </div>
  <!-- end mobile navigation --></ng-container
>
