<svg [style.width]="width + 'px'" viewBox="0 0 221 218" xmlns="http://www.w3.org/2000/svg">
  <g fill="#E53238" fill-rule="nonzero">
    <path
      style="transform: scale3d(1, 1, 1); transform-origin: center center; animation: breath 1.5s linear 0s infinite normal forwards"
      d="M166 1.2c-15.7 2.5-30.3 15.2-35 30.5-2.6 8.5-2.7 20-.1 28.2 9.2 29.5 44.4 41.1 70 23.1 11-7.8 17.8-20 18.8-34 2.1-29.5-23.8-52.6-53.7-47.8Z"
    />
    <path
      d="M9.3 41.1c-1.7.5-4.3 2.3-5.7 4L1 48.2v80.4c0 68.4.2 80.9 1.5 83.3 2.9 5.7 1 5.6 87.5 5.6 75.4 0 79.7-.1 82.4-1.8 5.5-3.7 5.6-4.8 5.4-50.6l-.3-42-5.2 3.3c-11.9 7.5-29.8 10.4-43.7 7.2-11.3-2.7-19.1-7.1-28.2-16.1-13.2-13.1-18-25.5-17.2-44.6.5-11.4 2.6-18.9 7.8-27.2 1.7-2.7 3-5 3-5.3 0-.8-81.6-.2-84.7.7Z"
    />
  </g>

  <style>
    @keyframes breath {
      0% {
        animation-timing-function: cubic-bezier(0.9647, 0.2413, -0.0705, 0.7911);
        transform: scale3d(1, 1, 1);
      }
      51% {
        animation-timing-function: cubic-bezier(0.9226, 0.2631, -0.0308, 0.7628);
        transform: scale3d(0.8, 0.8, 0.8);
      }
      100% {
        transform: scale3d(1, 1, 1);
      }
    }
  </style>
</svg>
