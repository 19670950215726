<div
  [ngClass]="disabled ? 'cursor-not-allowed opacity-50' : ''"
  #toggleSwitchEl
  (click)="!disabled ? toggleSwitch() : null"
  class="js-toggle flex items-center cursor-pointer"
>
  <button
    type="button"
    [ngClass]="{
      'cursor-not-allowed': disabled,
      'js-active bg-primary': checked,
      'js-inactive bg-gray-500': !checked
    }"
    class="group-hover:bg-gray-700 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    role="switch"
    aria-checked="false"
  >
    <span
      [ngClass]="[checked ? 'translate-x-5' : 'translate-x-0']"
      class="js-toggle-bar pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
    >
      <span
        [ngClass]="[checked ? 'opacity-0' : 'opacity-100']"
        class="js-transition-inactive opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
        aria-hidden="true"
      >
        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path
            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
      <span
        [ngClass]="[checked ? 'opacity-100' : 'opacity-0']"
        class="js-transition-active ease-in duration-200 opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
        aria-hidden="true"
      >
        <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
          <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
          ></path>
        </svg>
      </span>
    </span>
  </button>
  <span class="ml-3" *ngIf="label.length">
    <span [class.text-primary]="checked && !disableCheckedStyle" class="text-sm font-bold">{{ label }}</span>
  </span>
</div>
