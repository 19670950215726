<ng-container *transloco="let t; scope: 'core'">
  <div *ngIf="footer$ | async as footer" class="text-sm border-b border-white pb-4 md:pb-0 md:border-b-0">
    <p class="text-white mb-2">{{ footer.openHours }}</p>
    <p>
      <a href="tel:{{ footer.phone }}" class="text-white hover:text-primary">{{ t("core.footer.phone") }} {{ footer.phone }}</a>
    </p>
    <p class="text-white mb-2">{{ t("core.footer.fax") }} {{ footer.fax }}</p>
    <p>
      <a href="mailto:{{ footer.mail }}" class="text-white hover:text-primary">{{ footer.mail }}</a>
    </p>

    <p *ngIf="footer.moreInfos">
      {{ footer.moreInfos }}
    </p>
  </div>
</ng-container>
