<button
  type="button"
  class="
  text-{{ textColor }}
  bg-{{ background }}
  hover:bg-{{ hover }}
  inline-flex items-center justify-between
  w-full
  rounded shadow-sm pr-1.5 pl-2.5 md:pl-3.5 md:pr-2.5 py-1.5"
>
  <!-- icon left-->
  <ng-container *ngIf="iconPosition === 'left'">
    <ng-container *ngIf="icon === 'cart'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="ml-1 mr-4 h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
        ></path>
      </svg>
    </ng-container>

    <ng-container *ngIf="icon === 'loading'">
      <span class="loader"></span>
    </ng-container>

    <ng-container *ngIf="icon === 'mail'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="ml-1 mr-4 h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        ></path>
      </svg>
    </ng-container>

    <ng-container *ngIf="icon === 'note'">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
        ></path>
      </svg>
    </ng-container>
  </ng-container>

  <!-- btn text -->
  <span
    class="font-bold text-base"
    [ngClass]="{ 'mr-1.5 md:mr-2.5': extraMargin && iconPosition === 'right', 'ml-1.5 md:ml-2.5': extraMargin && iconPosition === 'left' }"
    >{{ text }}</span
  >

  <!-- icon right -->
  <ng-container *ngIf="iconPosition === 'right'">
    <ng-container *ngIf="icon === 'cart'">
      <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
        ></path>
      </svg>
    </ng-container>

    <ng-container *ngIf="icon === 'loading'">
      <span class="loader"></span>
    </ng-container>

    <ng-container *ngIf="icon === 'mail'">
      <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        ></path>
      </svg>
    </ng-container>

    <ng-container *ngIf="icon === 'note'">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
        ></path>
      </svg>
    </ng-container>
  </ng-container>
</button>
