import {
  AuthService,
  Cart,
  CartHeader,
  CartItem,
  CartService,
  CreateCartItemInterface,
  HookEvent,
  HookHandlerAbstract,
  WorkflowCreateHook,
} from '@lobos/library';
import { iif, Observable, throwError } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { switchMap } from 'rxjs/operators';
import { ToxinModalComponent } from '../../../components/toxin-modal/toxin-modal.component';
import { SuedoArticle } from '../../../services/catalog/model/suedo-article';
import { LoginModalComponent } from '../../../components/login-modal/login-modal.component';

type type = WorkflowCreateHook<'CreateCartItemHook'>;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CartItemCreatedToxinsHandlerHandler extends HookHandlerAbstract<type, Observable<Cart<CartHeader, CartItem>>> {
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  constructor(
    private modal: SimpleModalService,
    private authService: AuthService,
    private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
  ) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateCartItemHook' }];
  }

  override before(
    event: HookEvent<type, Observable<Cart<CartHeader, CartItem>>>,
    _: HookEvent<type, Observable<Cart<CartHeader, CartItem>>>,
  ): () => Observable<Cart<CartHeader, CartItem>> {
    const oCartItem: CreateCartItemInterface = event.params[0];
    const callable = event.callable;
    return this.handleToxins(oCartItem, callable, this.modal);
  }

  private handleToxins(
    oCartItem: CreateCartItemInterface,
    callable: (params?: any[]) => Observable<Cart<CartHeader, CartItem>>,
    modal: SimpleModalService,
  ) {
    if (
      (oCartItem.emitHook === undefined || oCartItem.emitHook) &&
      this.isToxin(oCartItem) &&
      (!this.authService.isAuthorized() || (this.authService.isAuthorized() && this.authService.getAuthUser()?.oCustomer.bPrivateCustomer))
    ) {
      return () =>
        modal.addModal<CreateCartItemInterface | CartItem, string>(ToxinModalComponent, oCartItem).pipe(
          switchMap((returnValue: string) => {
            if (returnValue === 'cancel') {
              return throwError(() => 'Canceled add to cart of toxin');
            }

            return this.modal.addModal(LoginModalComponent).pipe(
              switchMap((result: string | null) =>
                iif(
                  () => result === 'success',
                  this.cartService.createCartItem(oCartItem),
                  throwError(() => 'Canceled add to cart of toxin'),
                ),
              ),
            );
          }),
        );
    } else {
      return callable;
    }
  }

  private isToxin(oCartItem: CreateCartItemInterface): boolean {
    const oSuedoArticle = { ...(oCartItem.oArticle as SuedoArticle) };
    return oSuedoArticle?.shtPL1WebNotAvailable ? oSuedoArticle?.shtPL1WebNotAvailable > 0 : false;
  }
}
