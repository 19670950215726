import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentQuery } from '@lobos/library';

@Component({
  selector: 'app-footer-menu2',
  templateUrl: './footer-menu2.component.html',
  styleUrls: ['./footer-menu2.component.scss'],
})
export class FooterMenu2Component {
  cmsMenu$: Observable<CmsUiDynamicContentMenu> | undefined = this.cmsQuery.select('cmsMainMenu');

  constructor(private cmsQuery: CmsUiDynamicContentQuery) {}
}
