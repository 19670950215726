import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '@lobos/library';
import { Observable } from 'rxjs';
import { ImageHelperService } from '../../services/catalog/image-helper.service';

@Pipe({
  name: 'categoryPhotoCollage',
})
export class CategoryPhotoCollagePipe implements PipeTransform {
  constructor(private imageHelperService: ImageHelperService) {}

  transform(category: Category): Observable<string> {
    return this.imageHelperService.getCategoryCollectionImage(category);
  }
}
