import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import {
  Article,
  ArticleQuery,
  ArticleService,
  AuthService,
  CartHeader,
  CartItem,
  CartItemQuery,
  CartService,
  CreateCartItemInterface,
  PriceQuery,
  User,
} from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { DomHelperService } from '../../services/dom/dom-helper.service';
import { SuedoArticle } from '../../services/catalog/model/suedo-article';
import { SuedoPrice } from '../../services/catalog/model/suedo-price';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';
import { BreadcrumbHelperService } from '../../services/util/breadcrumb-helper.service';

@UntilDestroy()
@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss'],
})
export class ArticleItemComponent implements OnInit {
  @Input() article?: SuedoArticle;
  @Input() articleId?: string;
  @Input() quantity = 1;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuantityChange = new EventEmitter();

  article$?: Observable<SuedoArticle | undefined>;

  replaceArticles$: Observable<SuedoArticle[]> = this.articleQuery.selectAll({
    filterBy: [
      (suedoArticle: SuedoArticle) => suedoArticle.sForerunner === this.articleId || suedoArticle.sForerunner === this.article?.sArticleID,
    ],
  });

  authUser$: Observable<User | undefined> = this.authService.authUser$;

  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  private currentBoxHeight: number = 0;

  priceIsZero: boolean = false;

  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);

  oPrice$: Observable<SuedoPrice | undefined> = combineLatest([this.authService.isLoggedIn$, this.articleChanged$]).pipe(
    switchMap(() =>
      iif(
        () => this.priceQuery.hasEntity(this.article?.sArticleID),
        this.priceQuery.selectEntity(this.article?.sArticleID),
        this.articleHelper.getPriceFromApi(this.article!).pipe(switchMap(() => this.priceQuery.selectEntity(this.article?.sArticleID))),
      ),
    ),
  );

  @HostListener('mouseenter')
  onMouseEnter = () => {
    const element = this.elementRef.nativeElement;
    const box = element.querySelector('.js-box');
    const cartEl = box.querySelector('.js-box-hover');

    if (parseInt(box.getAttribute('data-minheight')!) == this.currentBoxHeight && window.innerWidth > 767) {
      const getAdditionalHeight = parseInt(box.getAttribute('data-hover-height')!);
      const jsBoxHeightWithCurrent = box.querySelector('.js-box-height') as HTMLElement | null;
      jsBoxHeightWithCurrent!.style.height = this.currentBoxHeight + getAdditionalHeight + 'px';
      box.classList.remove('z-10');
      box.classList.add('z-40');
      cartEl?.classList.remove('md:hidden');
    }
  };

  @HostListener('mouseleave')
  onMouseLeave = () => {
    const element = this.elementRef.nativeElement;
    const box = element.querySelector('.js-box');
    const cartEl = box.querySelector('.js-box-hover');

    if (parseInt(box.getAttribute('data-minheight')!) == this.currentBoxHeight && window.innerWidth > 767) {
      const jsBoxHeight = box.querySelector('.js-box-height') as HTMLElement | null;
      jsBoxHeight!.style.height = '';
      box.classList.add('z-10');
      box.classList.remove('z-40');
      cartEl?.classList.add('md:hidden');
    }
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translocoService: TranslocoService,
    private elementRef: ElementRef,
    private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
    private articleQuery: ArticleQuery<SuedoArticle>,
    private articleService: ArticleService<SuedoArticle>,
    private articleHelper: ArticleHelperService,
    private priceQuery: PriceQuery,
    private authService: AuthService,
    private cartItemQuery: CartItemQuery<CartItem>,
    private breadcrumbHelperService: BreadcrumbHelperService,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngOnInit() {
    this.article$ = iif(
      () => !!this.article,
      // true
      of(this.article),
      // false
      of(this.articleId).pipe(
        switchMap((id?: string) =>
          iif(
            () => this.articleQuery.hasEntity(id!),
            this.articleQuery.selectEntity(id!),
            this.articleService.getArticleByID(id!).pipe(switchMap(() => this.articleQuery.selectEntity(id!))),
          ),
        ),
      ),
    ).pipe(
      tap(() => {
        if (isPlatformBrowser(this.platformId)) {
          this.showCartBtnOnHover();
        }
      }),
      tap((article) => this.loadReplaceArticles(article!)),
      tap((article) => (this.quantity = article && article?.decQuantityPackage > 0 ? article?.decQuantityPackage : 1)),
      tap((article) => (this.quantity = article && article?.decQuantityPackage > 0 ? article?.decQuantityPackage : 1)),
    );
  }

  showCartBtnOnHover() {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      const box = element.querySelector('.js-box');
      const innerBoxEl = box.querySelector('.js-box-height');
      const cartEl = box.querySelector('.js-box-hover');
      this.currentBoxHeight = parseInt(getComputedStyle(innerBoxEl!).height) + 4;

      if (DomHelperService.is_touch_enabled()) {
        if (typeof window !== 'undefined' && window.innerWidth > 767) {
          cartEl.classList.add('js-touch');
          cartEl.classList.remove('md:hidden');
          box.style.minHeight = '';
          box.querySelector('.js-box-height')!.classList.remove('md:absolute');
          box.querySelector('.js-box-height')!.classList.remove('md:block');
          box.classList.remove('min-h-0');
          box.classList.remove('md:min-h-350px');
        }
      } else {
        DomHelperService.checkBoxMinHeight(box, this.currentBoxHeight);
      }
    }, 0);
  }

  getArticlePath(): string {
    return '/' + this.translocoService.getActiveLang() + '/article/';
  }

  quantityChanged($event: any) {
    this.quantity = $event;
  }

  isLoading$: Observable<boolean> = this.cartItemQuery.selectLoading();

  addToCart(article: SuedoArticle) {
    if (!article || this.quantity <= 0) {
      return;
    }

    this.cartService
      .createCartItem({
        decQuantity: this.quantity,
        sArticleID: article.sArticleID as string,
        sArticleName: article.sName,
        sQuantityUnit: article.sQuantityUnitSales,
        oArticle: article,
        sItemText: '',
      })
      .pipe(first(), untilDestroyed(this))
      .subscribe();
  }

  private loadReplaceArticles(article: SuedoArticle) {
    this.articleService.getArticleReplaceBy(article.sArticleID.toString()).pipe(untilDestroyed(this)).subscribe();
  }

  public setPreviousUrl() {
    this.breadcrumbHelperService.setPreviousUrl(this.location.path());
  }
}
