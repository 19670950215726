import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Article, CompareService } from '@lobos/library';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
import { SuedoArticle } from '../../../../services/catalog/model/suedo-article';

@UntilDestroy()
@Component({
  selector: 'app-comparison-flyout',
  templateUrl: './comparison-flyout.component.html',
  styleUrls: ['./comparison-flyout.component.scss'],
})
export class ComparisonFlyoutComponent implements OnInit {
  @Input()
  public classes: string = '';
  allItems$: Observable<SuedoArticle[]> | undefined;
  public mobile: boolean | undefined;

  articles$: Observable<SuedoArticle[]> | undefined;

  constructor(private compareService: CompareService<Article>, @Inject(PLATFORM_ID) private platformId: string) {
    this.articles$ = this.compareService.items$;
  }

  ngOnInit() {
    this.allItems$ = this.compareService.items$.pipe();

    if (isPlatformBrowser(this.platformId) && window.screen.width < 1279) {
      this.mobile = true;
    }
  }

  remove(article: Article) {
    this.compareService.remove(article).pipe(take(1), untilDestroyed(this)).subscribe();
  }
}
