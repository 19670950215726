import { EnvironmentInterface } from './environment.interface';
import { VERSION } from './version';

export const environment: EnvironmentInterface = {
  name: 'PRODUCTION',
  host: 'https://www.suedo.ch',
  apiUrl: '/p48apihost',
  ssrPort: 4400,
  version: VERSION,
  production: true,
  debugging: false,
  defaultLocale: 'de',
  availableLang: ['de', 'fr'],
  loadTranslationFromServer: true,
  auth: {
    routes: {
      home: '/',
      login: '',
    },
  },
  api: {
    host: 'https://www.suedo.ch',
    branchKey: 'H1L1',
    webshopId: 3,
  },
  cms: {
    host: 'http://localhost:1338',
    apiUrl: '/cms/v4',
    homepage: 'home-suedo',
    footer: 'footer-suedo',
    cmsMainMenu: 'menu-suedo',
    error: 'error-suedo',
  },
  sentry: {
    active: false,
    dsn: 'https://767317662e0f49d68b7284279ba47fd7@o920539.ingest.sentry.io/6379650',
  },
  tracking: {
    gtm: '',
  },
  images: {
    categoryImage: 302,
    productDetailImages: [302],
    manufacturerLogo: 304,
    labelImages: 104,

    cloudinary: {
      active: true,
      cloudName: 'dvt5mo0bf',
      fetchImageUrl: 'https://images.suedo.ch/images',
    },
  },
  logoPath: '/assets/images/layout/Logo_Suedo_Jasa_55px-h_Web.png',
  brandFeatureId: 699,
};
