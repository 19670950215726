import { Pipe, PipeTransform } from '@angular/core';
import { Role, RoleQuery, RoleService } from '@lobos/library';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Pipe({
  name: 'roleHumanReadable',
})
export class RoleHumanReadablePipe implements PipeTransform {
  constructor(private roleQuery: RoleQuery<Role>, private roleService: RoleService<Role>) {}

  public transform(roleId: number): Observable<string> {
    return of(roleId).pipe(
      switchMap((roleId: number) =>
        iif(
          () => this.roleQuery.getHasCache(),
          this.roleQuery.selectEntity(roleId),
          this.roleService.get().pipe(switchMap(() => this.roleQuery.selectEntity(roleId))),
        ),
      ),
      map((role: Role | undefined) => role?.sRoleName || ''),
    );
  }
}
