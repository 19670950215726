import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentPage, Footer } from '@lobos/library';

export interface CmsSuedoUiDynamicContentState extends EntityState<CmsUiDynamicContentPage> {
  homePages: CmsUiDynamicContentPage[];
  footers: Footer[];
  menus: CmsUiDynamicContentMenu[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cms-suedo' })
export class CmsSuedoUiDynamicContentStore extends EntityStore<CmsSuedoUiDynamicContentState> {
  constructor() {
    super({
      homePages: undefined,
      footers: undefined,
      menus: undefined,
    });
  }
}
