import { Component, Input } from '@angular/core';
import { Article, AuthService, PriceQuery } from '@lobos/library';
import { BehaviorSubject, combineLatest, iif, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';
import { SuedoPrice } from '../../services/catalog/model/suedo-price';
import { SuedoUser } from '../../interfaces';

@Component({
  selector: 'app-article-promo-price',
  templateUrl: './article-promo-price.component.html',
  styleUrls: ['./article-promo-price.component.scss'],
})
export class ArticlePromoPriceComponent {
  private _article: Article | undefined;
  @Input() set article(value: Article | undefined) {
    this._article = value;
    this.articleChanged$.next(value);
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get article(): Article | undefined {
    return this._article;
  }

  authUser$: Observable<SuedoUser | undefined> = this.authService.authUser$;

  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);
  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  oPrice$: Observable<SuedoPrice | undefined> = combineLatest([this.authService.isLoggedIn$, this.articleChanged$]).pipe(
    switchMap(() =>
      iif(
        () => this.priceQuery.hasEntity(this.article?.sArticleID),
        this.priceQuery.selectEntity(this.article?.sArticleID),
        this.articleHelper.getPriceFromApi(this.article!).pipe(switchMap(() => this.priceQuery.selectEntity(this.article?.sArticleID))),
      ),
    ),
  );

  constructor(private articleHelper: ArticleHelperService, private priceQuery: PriceQuery, private authService: AuthService<SuedoUser>) {}
}
