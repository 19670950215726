<app-header></app-header>

<main class="relative overflow-hidden min-h-full lg:min-h-main bg-gray-200">
  <app-breadcrumb></app-breadcrumb>
  <ng-content></ng-content>
</main>

<app-sales-rep-banner></app-sales-rep-banner>

<app-footer></app-footer>
