<ng-container *transloco="let t; scope: 'core'">
  <div class="flex space-x-6 md:order-2">
    <a target="_blank" href="https://www.facebook.com/suedoag/" class="text-xl text-white group hover:text-primary">
      <span class="sr-only">{{ t("core.footer.facebook") }}</span>
      <svg viewBox="0 0 1792 1792" class="h-5 w-5 fill-white group-hover:fill-primary" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"
        ></path>
      </svg>
    </a>

    <a target="_blank" href="https://www.linkedin.com/company/s%C3%BCdo-ag/about/" class="text-xl text-white group hover:text-primary">
      <span class="sr-only">{{ t("core.footer.linkedin") }}</span>
      <svg viewBox="0 0 1792 1792" class="h-5 w-5 fill-white group-hover:fill-primary" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"
        ></path>
      </svg>
    </a>

    <a target="_blank" href="https://www.youtube.com/channel/UCCyprrwsasgqinW_1ccdzSw" class="text-xl text-white group hover:text-primary">
      <span class="sr-only">{{ t("core.footer.youtube") }}</span>
      <svg viewBox="0 0 1792 1792" class="h-5 w-5 fill-white group-hover:fill-primary" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1099 1244v211q0 67-39 67-23 0-45-22v-301q22-22 45-22 39 0 39 67zm338 1v46h-90v-46q0-68 45-68t45 68zm-966-218h107v-94h-312v94h105v569h100v-569zm288 569h89v-494h-89v378q-30 42-57 42-18 0-21-21-1-3-1-35v-364h-89v391q0 49 8 73 12 37 58 37 48 0 102-61v54zm429-148v-197q0-73-9-99-17-56-71-56-50 0-93 54v-217h-89v663h89v-48q45 55 93 55 54 0 71-55 9-27 9-100zm338-10v-13h-91q0 51-2 61-7 36-40 36-46 0-46-69v-87h179v-103q0-79-27-116-39-51-106-51-68 0-107 51-28 37-28 116v173q0 79 29 116 39 51 108 51 72 0 108-53 18-27 21-54 2-9 2-58zm-608-913v-210q0-69-43-69t-43 69v210q0 70 43 70t43-70zm719 751q0 234-26 350-14 59-58 99t-102 46q-184 21-555 21t-555-21q-58-6-102.5-46t-57.5-99q-26-112-26-350 0-234 26-350 14-59 58-99t103-47q183-20 554-20t555 20q58 7 102.5 47t57.5 99q26 112 26 350zm-998-1276h102l-121 399v271h-100v-271q-14-74-61-212-37-103-65-187h106l71 263zm370 333v175q0 81-28 118-38 51-106 51-67 0-105-51-28-38-28-118v-175q0-80 28-117 38-51 105-51 68 0 106 51 28 37 28 117zm335-162v499h-91v-55q-53 62-103 62-46 0-59-37-8-24-8-75v-394h91v367q0 33 1 35 3 22 21 22 27 0 57-43v-381h91z"
        ></path>
      </svg>
    </a>

    <a target="_blank" href="https://www.instagram.com/suedoag/" class="text-xl text-white group hover:text-primary">
      <span class="sr-only">{{ t("core.footer.instagram") }}</span>
      <svg viewBox="0 0 1792 1792" class="mt-0.5 h-5 w-5 fill-white group-hover:fill-primary" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1152 896q0-106-75-181t-181-75-181 75-75 181 75 181 181 75 181-75 75-181zm138 0q0 164-115 279t-279 115-279-115-115-279 115-279 279-115 279 115 115 279zm108-410q0 38-27 65t-65 27-65-27-27-65 27-65 65-27 65 27 27 65zm-502-220q-7 0-76.5-.5t-105.5 0-96.5 3-103 10-71.5 18.5q-50 20-88 58t-58 88q-11 29-18.5 71.5t-10 103-3 96.5 0 105.5.5 76.5-.5 76.5 0 105.5 3 96.5 10 103 18.5 71.5q20 50 58 88t88 58q29 11 71.5 18.5t103 10 96.5 3 105.5 0 76.5-.5 76.5.5 105.5 0 96.5-3 103-10 71.5-18.5q50-20 88-58t58-88q11-29 18.5-71.5t10-103 3-96.5 0-105.5-.5-76.5.5-76.5 0-105.5-3-96.5-10-103-18.5-71.5q-20-50-58-88t-88-58q-29-11-71.5-18.5t-103-10-96.5-3-105.5 0-76.5.5zm768 630q0 229-5 317-10 208-124 322t-322 124q-88 5-317 5t-317-5q-208-10-322-124t-124-322q-5-88-5-317t5-317q10-208 124-322t322-124q88-5 317-5t317 5q208 10 322 124t124 322q5 88 5 317z"
        ></path>
      </svg>
    </a>
  </div>
  <p class="mt-8 text-sm text-white md:mt-0 md:order-1">{{ t("core.footer.copyright") }}</p>
</ng-container>
