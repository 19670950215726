import { Component, Input } from '@angular/core';
import { Article } from '@lobos/library';

@Component({
  selector: 'app-article-translated-quantity-unit-sales',
  templateUrl: './article-translated-quantity-unit-sales.component.html',
  styleUrls: ['./article-translated-quantity-unit-sales.component.scss'],
})
export class ArticleTranslatedQuantityUnitSalesComponent {
  @Input() article!: Article;
}
