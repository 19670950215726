import { Component } from '@angular/core';
import { CmsUiDynamicContentMenu, CmsUiDynamicContentQuery } from '@lobos/library';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss'],
})
export class FooterMenuComponent {
  cmsMenu$: Observable<CmsUiDynamicContentMenu> | undefined = this.cmsQuery.select('cmsMainMenu');
  constructor(private cmsQuery: CmsUiDynamicContentQuery) {}
}
