import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SuedoArticle } from '../../services/catalog/model/suedo-article';
import { FormControl } from '@angular/forms';
import { SimpleModalService } from 'ngx-simple-modal';
import { QtyModalInputComponent } from './qty-modal-input/qty-modal-input.component';

@UntilDestroy()
@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss'],
})
export class QuantityInputComponent implements OnChanges {
  @Input() quantity = 1;
  @Input() articleId = '';

  private _article: SuedoArticle | undefined;
  @Input() set article(value: SuedoArticle | undefined) {
    this._article = value;
    this.maxNumber = this.decQtyRoundUp(this.maxNumber);
  }

  get article(): SuedoArticle | undefined {
    return this._article;
  }

  formQuantity: FormControl = new FormControl(1);
  @Input() disabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuantityChange = new EventEmitter<number>();

  private maxNumber: number = 999999;
  private minNumber: number = 1;
  public isLoading: boolean = false;

  private quantitySubject: Subject<number> = new Subject<number>();

  constructor(private simpleModalService: SimpleModalService) {
    this._article = {} as SuedoArticle;
    this.quantitySubject
      .asObservable()
      .pipe(
        switchMap((quantity: number) => iif(() => quantity > this.maxNumber, of(this.maxNumber), of(quantity))),
        switchMap((quantity: number) => iif(() => quantity < this.minNumber, of(this.minNumber), of(quantity))),
        untilDestroyed(this),
      )
      .subscribe((quantity: number) => {
        if (this.isRoundNeeded(quantity)) {
          this.simpleModalService
            .addModal(QtyModalInputComponent, { quantity: quantity, article: this.article })
            .pipe()
            .subscribe((number: number | undefined) => {
              this.formQuantity.setValue(number);
              this.onQuantityChange.emit(number);
              this.isLoading = true;
            });
        } else {
          this.formQuantity.setValue(quantity);
          this.onQuantityChange.emit(quantity);
          this.isLoading = true;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('quantity') && changes.hasOwnProperty('article')) {
      if (this.article !== undefined && this.article?.decQuantityPackage > 1 && this.quantity === 1) {
        this.formQuantity.setValue(this.article.decQuantityPackage);
        this.onQuantityChange.emit(this.formQuantity.value);
      } else {
        this.formQuantity.setValue(this.quantity);
      }
    }
  }

  setNewQuantity() {
    // if (newQuantity > this.quantity) {
    //   newQuantity = this.decQtyRoundUp(newQuantity);
    // } else {
    //   newQuantity = this.decQtyRoundDown(newQuantity);
    // }
    this.quantitySubject.next(this.formQuantity.value);
  }

  plus(event: Event) {
    event.preventDefault();
    this.quantitySubject.next(this.decQtyRoundUp(this.formQuantity.value + 1));
  }

  minus(event: Event) {
    event.preventDefault();
    this.quantitySubject.next(this.decQtyRoundDown(this.formQuantity.value - 1));
  }

  decQtyRoundUp(decQty: number): number {
    // Aufrunden gemäss decQuantityPackage
    if (this.article && this.article.decQuantityPackage) {
      if (decQty % this.article.decQuantityPackage !== 0) {
        decQty = decQty + (this.article.decQuantityPackage - (decQty % this.article.decQuantityPackage));
      }
    }
    return decQty;
  }

  decQtyRoundDown(decQty: number): number {
    // Abrunden gemäss decQuantityPackage
    if (this.article !== undefined && this.article.decQuantityPackage) {
      if (decQty < this.article.decQuantityPackage) {
        decQty = this.article.decQuantityPackage;
      } else if (decQty % this.article.decQuantityPackage !== 0) {
        decQty = decQty - (decQty % this.article.decQuantityPackage);
      }
    }
    return decQty;
  }

  isRoundNeeded(quantity: number): boolean {
    if (this.article !== undefined && this.article.decQuantityPackage) {
      return quantity % this.article.decQuantityPackage !== 0;
    }
    return false;
  }
}
