import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { AuthService, CartItem, CreateCartItemInterface } from '@lobos/library';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-qty-modal',
  templateUrl: './toxin-modal.component.html',
  styleUrls: ['./toxin-modal.component.scss'],
})
export class ToxinModalComponent extends SimpleModalComponent<CreateCartItemInterface | CartItem, string> {
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  oCartItem!: CreateCartItemInterface | CartItem;

  constructor(private authService: AuthService) {
    super();
  }

  override mapDataObject(data: CreateCartItemInterface | CartItem) {
    super.mapDataObject(data);
    this.oCartItem = data;
  }

  cancel() {
    this.result = 'cancel';
    this.close();
  }

  openLoginModal(): void {
    this.result = 'login';
    this.close();
  }
}
