import { UrlHelperService } from '@lobos/library';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomUrlHelperService extends UrlHelperService {
  override normalize(path: string): string {
    path = path.toLowerCase();
    path = path.replace(/ä/g, 'ae');
    path = path.replace(/ö/g, 'oe');
    path = path.replace(/ü/g, 'ue');
    path = path.replace(/ß/g, 'ss');
    path = path.replace(/é/g, 'e');
    path = path.replace(/è/g, 'e');
    path = path.replace(/ê/g, 'e');
    path = path.replace(/â/g, 'a');
    path = path.replace(/à/g, 'a');
    path = path.replace(/[^a-z0-9]/gi, '-');
    path = path.replace(/-+/g, '-');
    path = path.replace(/^-/g, '');
    path = path.replace(/-$/g, '');

    return path;
  }
}
