import { Injectable } from '@angular/core';
import { CmsUiDynamicContentStore, HookEvent, HookHandlerAbstract, HookPriority, LanguageChangedHook } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CmsSuedoService } from '../../../services/cms/cms.service';

type type = LanguageChangedHook;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class LanguageChangedCmsStoresHandler extends HookHandlerAbstract<type, Observable<string>> {
  constructor(private cmsStore: CmsUiDynamicContentStore, private cmsSuedoService: CmsSuedoService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'LanguageChangedHook' }];
  }

  override priority(): number {
    return HookPriority.LOW;
  }

  override after(event: HookEvent<type, Observable<string>>, result: Observable<string>): Observable<string> {
    this.cmsStore.reset();

    forkJoin([
      this.cmsSuedoService.getHomePage(),
      this.cmsSuedoService.getErrorPage(),
      this.cmsSuedoService.getFooter(),
      this.cmsSuedoService.getCmsMainMenu(),
    ])
      .pipe(first(), untilDestroyed(this))
      .subscribe();

    return super.after(event, result);
  }
}
