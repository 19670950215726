import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CATALOG_CONFIG, CatalogConfig, ElasticSearchService, SearchResponse, SearchStore } from '@lobos/library';
import { buildMultiSearchQuery } from './suedo-search.util';

@Injectable({ providedIn: 'root' })
export class SuedoSearchService {
  /**
   * Creates an instance of search service.
   */
  constructor(
    protected store: SearchStore,
    private elasticSearch: ElasticSearchService,
    @Inject(CATALOG_CONFIG) private catalogConfig: CatalogConfig,
  ) {}

  search(searchTerm: string): Observable<SearchResponse<any>> {
    const query = buildMultiSearchQuery(
      searchTerm,
      undefined,
      this.elasticSearch.localizedIndex('article'),
      this.elasticSearch.localizedIndex('category'),
    );
    this.store.setLoading(true);
    return this.elasticSearch
      .execute<SearchResponse<any>>(this.catalogConfig.apiUrl + '/es/msearch', {
        index: this.elasticSearch.localizedIndex('article'),
        query: query.map((entry) => JSON.stringify(entry)).join('\n') + '\n',
      })
      .pipe(
        tap((response: any) => {
          const articles = response.responses[0].hits.hits.map((article: any) => {
            return article._source;
          });
          const categories = response.responses[1].hits.hits.map((category: any) => category._source);
          this.store.update({
            articles: articles,
            totalArticles: response.responses[0].hits.total.value,
            categories: categories,
            searchTerm: searchTerm,
          });
          this.store.setLoading(false);
        }),
        catchError((error: Error) => {
          this.store.setLoading(false);
          return throwError(() => error);
        }),
      );
  }

  resetStore() {
    this.store.reset();
  }
}
