import { Injectable } from '@angular/core';
import { Article, Category, ElasticSearchService, Product, ReferenceLink, Ressource, SearchResponse } from '@lobos/library';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { environment } from '../../../environments/environment';
import { iif, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { pad } from '@cloudinary/url-gen/actions/resize';
import { format } from '@cloudinary/url-gen/actions/delivery';
import { auto } from '@cloudinary/url-gen/qualifiers/format';

export interface ImageGalleryItem {
  sortId: number;
  id: string;
  image: string;
}

@Injectable({
  providedIn: 'root',
})
export class ImageHelperService {
  constructor(private elasticSearch: ElasticSearchService) {}

  public getCategoryCollectionImage(category: Category): Observable<string> {
    return of(category).pipe(
      map((category: Category) => this.getResources(category.oResources)),
      switchMap((resources: Ressource[]) => {
        if (resources.length === 1) {
          return of(
            this.buildCloudinaryUrl(this.buildImageUrl(resources[0].sResourceKey), {
              width: 400,
              height: 400,
            }).toURL(),
          );
        }

        if (resources.length > 1) {
          return this.buildPhotoCollageLink(resources);
        }

        return this.getChildProducts(category).pipe(
          switchMap((products: Product[]) =>
            iif(
              () => products.length > 1,
              this.buildPhotoCollageLink(
                products.map((product: Product) => this.getFirstMatchingResource(product.oResources)!).filter((item) => item),
              ),
              of(this.getFirstMatchingResource(products[0].oResources)).pipe(
                filter((res): res is Ressource => !!res),
                map((res) =>
                  this.buildCloudinaryUrl(this.buildImageUrl(res.sResourceKey), {
                    width: 400,
                    height: 400,
                  }).toURL(),
                ),
              ),
            ),
          ),
        );
      }),
    );
  }

  public getProductImages(product: Product): ImageGalleryItem[] {
    let images: ImageGalleryItem[] = [];
    const resources: Ressource[] = product.oResources.filter((resource: Ressource) =>
      [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal),
    );

    images = resources.map((resource: Ressource) => ({
      id: resource.sResourceName,
      image: this.buildCloudinaryUrl(this.buildImageUrl(resource.sResourceKey)).toURL(),
      sortId: resource.lngItemID,
    }));

    images.sort((a, b) => a.sortId - b.sortId);

    if (images.length === 0 && product.oArticles) {
      const articleImage = product.oArticles[0].oReferenceLink.find((link: ReferenceLink) => link.sTypeCode === 'BILD');
      if (articleImage) {
        images.push({
          id: product.oArticles[0].sArticleID.toString(),
          image: this.buildCloudinaryUrl(this.buildImageUrl(articleImage.sLinkReference)).toURL(),
          sortId: product.oArticles[0].lngItemID,
        });
      } else {
        images.push(this.addFallbackToGallery());
      }
    }

    if (images.length === 0) {
      images.push(this.addFallbackToGallery());
    }

    images.sort((a, b) => a.sortId - b.sortId);

    return images;
  }

  public getProductMainImage(product: Product): CloudinaryImage | null {
    const matchingResources: Ressource[] = product.oResources.filter(
      (resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.categoryImage,
    );

    if (matchingResources.length === 0) {
      return this.returnFallbackImage();
    }

    const smallestMatchingResource = matchingResources.reduce((smallestResource: Ressource, currentResource: Ressource) => {
      return currentResource.lngItemID < smallestResource.lngItemID ? currentResource : smallestResource;
    }, matchingResources[0]);

    return this.buildCloudinaryUrl(this.buildImageUrl(smallestMatchingResource.sResourceKey));
  }

  public getArticleMainImage(article: Article): CloudinaryImage | null {
    let image: ReferenceLink | Ressource | undefined;

    if (article.oReferenceLink.some((link: ReferenceLink) => link.sReferenceDetail)) {
      image = article.oReferenceLink
        .filter((link: ReferenceLink) => link.sTypeCode === 'BILD')
        .reduce((a: ReferenceLink, b: ReferenceLink) => (Number(a.sReferenceDetail) < Number(b.sReferenceDetail) ? a : b));
    } else {
      image = article.oReferenceLink.find((link: ReferenceLink) => link.sTypeCode === 'BILD');
    }

    if (!image) {
      image = article.oProductInfo[0]?.oResources?.find((resource: Ressource) => resource.shtResourceGroupIDInternal === 1);
    } else {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sLinkReference));
    }

    if (image) {
      return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourceKey));
    }
    return this.returnFallbackImage();
  }

  public getArticleImages(article: Article): ImageGalleryItem[] {
    let images: ImageGalleryItem[] = [];
    const referenceLinks: ReferenceLink[] = article.oReferenceLink
      .filter((link: ReferenceLink) => link.sTypeCode === 'BILD')
      .sort((a: ReferenceLink, b: ReferenceLink) => Number(a.sReferenceDetail) - Number(b.sReferenceDetail));

    images = referenceLinks.map((link: ReferenceLink) => ({
      id: link.sLinkID,
      image: this.buildCloudinaryUrl(this.buildImageUrl(link.sLinkReference), {
        width: 1000,
        height: 1000,
      }).toURL(),
      sortId: link.dtEntryDate,
    }));

    if (!images.length && article.oProductInfo[0]) {
      return this.getProductImages(article.oProductInfo[0]);
    }
    if (!images.length && !article.oProductInfo[0]) {
      images.push(this.addFallbackToGallery());
    }
    return images;
  }

  private buildImageUrl(imagePath: string): string {
    return `https://images.suedo.ch/images/${imagePath}`;
  }

  private buildCloudinaryUrl(
    imageUrl: string,
    params?: {
      width: number;
      height: number;
    },
  ): CloudinaryImage {
    const cloudinaryImage = new CloudinaryImage(imageUrl, { cloudName: 'dvt5mo0bf' }).setDeliveryType('fetch').delivery(format(auto()));

    if (params) {
      cloudinaryImage.resize(pad().width(params.width).height(params.height));
    }

    return cloudinaryImage;
  }

  private getChildProducts(category: Category): Observable<Product[]> {
    const query = {
      query: {
        bool: {
          must: [
            {
              prefix: {
                'sUrlPath.keyword': category.sUrlPath,
              },
            },
            {
              term: {
                'sType.keyword': 'P',
              },
            },
          ],
        },
      },
      size: 9,
    };
    return this.elasticSearch
      .execute<SearchResponse<any>>(environment.host + environment.apiUrl + '/es/search', {
        index: this.elasticSearch.localizedIndex('category'),
        query: JSON.stringify(query),
      })
      .pipe(map((response: SearchResponse<Product[]>) => response.hits.hits.map((hit: any) => hit._source)));
  }

  buildPhotoCollageLink(resources: Ressource[]): Observable<string> {
    let image = 'https://res.cloudinary.com/dvt5mo0bf/image/fetch/h_120,w_120,c_fill,bo_1px_solid_white,x_0,y_0/';
    for (let i = 1; i < 9; i++) {
      if (resources[i] && resources[i].sResourceKey.length) {
        image += `l_fetch:${btoa(
          this.buildImageUrl(resources[i].sResourceKey),
        )},w_120,h_120,bo_1px_solid_white/fl_layer_apply,g_north_west,x_${i % 3 === 0 ? 0 : i % 3 === 1 ? 120 : 240},y_${
          i < 3 ? 0 : i < 6 ? 120 : 240
        }/`;
      } else {
        image += `l_white_idspcs_i0ujot.jpg,w_120,h_120,bo_1px_solid_white/fl_layer_apply,g_north_west,x_${
          i % 3 === 0 ? 0 : i % 3 === 1 ? 120 : 240
        },y_${i < 3 ? 0 : i < 6 ? 120 : 240}/`;
      }
    }
    image += this.buildImageUrl(resources[0]?.sResourceKey);

    return of(image);
  }

  getResources(resources: Ressource[]): Ressource[] {
    return resources
      .filter((resource: Ressource) => [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal))
      .sort((a: Ressource, b: Ressource) => a.lngItemID - b.lngItemID);
  }

  getFirstMatchingResource(resources: Ressource[]): Ressource | undefined {
    return resources.find((resource: Ressource) =>
      [...environment.images.productDetailImages].includes(resource.shtResourceGroupIDInternal),
    );
  }

  getProductManufacturerLogo(resources: Ressource[]): CloudinaryImage | null {
    const image = resources.find((resource: Ressource) => resource.shtResourceGroupIDInternal === environment.images.manufacturerLogo);

    if (!image) {
      return null;
    }

    return this.buildCloudinaryUrl(this.buildImageUrl(image.sResourceKey));
  }

  getArticleManufacturerLogo(referenceLinks: ReferenceLink[]): CloudinaryImage | null {
    const image = referenceLinks.find((link: ReferenceLink) => link.sTypeCode === 'LOGO');

    if (!image) {
      return null;
    }

    return this.buildCloudinaryUrl(this.buildImageUrl(image.sLinkReference));
  }

  addFallbackToGallery(): ImageGalleryItem {
    if (environment.api.webshopId === 3) {
      return {
        id: '',
        image: this.buildCloudinaryUrl('https://images.suedo.ch/images/suedo_placeholder.gif').toURL(),
        sortId: 0,
      };
    } else if (environment.api.webshopId === 2) {
      return {
        id: '',
        image: this.buildCloudinaryUrl('https://images.suedo.ch/images/jasa_placeholder.gif').toURL(),
        sortId: 0,
      };
    } else {
      return {
        id: '',
        image: this.buildCloudinaryUrl('https://images.suedo.ch/images/suedo_placeholder.gif').toURL(),
        sortId: 0,
      };
    }
  }

  returnFallbackImage(): CloudinaryImage {
    if (environment.api.webshopId === 3) {
      return this.buildCloudinaryUrl('https://images.suedo.ch/images/suedo_placeholder.gif');
    }
    if (environment.api.webshopId === 2) {
      return this.buildCloudinaryUrl('https://images.suedo.ch/images/jasa_placeholder.gif');
    }
    return this.buildCloudinaryUrl('https://images.suedo.ch/images/suedo_placeholder.gif');
  }
}
