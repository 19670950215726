import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() public form: UntypedFormGroup | undefined;
  @Input() public value: boolean = false;
  @Input() public hasError: boolean = false;
  @Input() public disabled: boolean = false;

  @Output() checkboxChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  changeValue() {
    this.value = !this.value;
  }
}
