import { Injectable } from '@angular/core';
import { Catalog, Parameter, StringHelper, UrlHelperService, UrlPathHelperService } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { sortBy } from 'lodash';
import { NavigationInterface } from '../../components/header/header.component';

@Injectable({
  providedIn: 'root',
})
export class CatalogHelperService {
  constructor(private translocoService: TranslocoService, private urlHelper: UrlHelperService) {}

  public localizedUrlPath(params: Parameter, path: string, language: string): string {
    const catalogName = this.urlHelper.normalize(params.sCatalogName);
    const urlPath = StringHelper.trim(path.replace(catalogName, language), '/');
    return `/${urlPath}`;
  }

  public buildNavigationFromAll(params: Parameter, catalog: Catalog[]): NavigationInterface[] {
    const parents: { [key: string]: Catalog[] } = {};
    for (const group of catalog) {
      const parentGroupUrlPath = group.sUrlPath.replace(/\/[^\/]*\/$/, '/');
      if (!parents[parentGroupUrlPath]) {
        parents[parentGroupUrlPath] = [];
      }
      parents[parentGroupUrlPath].push(group);
    }

    const root: Catalog | undefined = catalog.find((group: Catalog) => group.lngGroup === params.lngProductRootGroup);
    if (!root) {
      return [];
    }

    return this.getGroupsForParent(root.sUrlPath, parents);
  }

  private getGroupsForParent(parentUrlPath: string, parents: { [key: string]: Catalog[] }): NavigationInterface[] {
    const group: Catalog[] = parents[parentUrlPath] || [];

    return this.mapGroupToNav(sortBy(group, 'lngItemId'), parents);
  }

  private mapGroupToNav(catalog: Catalog[], parents: { [key: number]: Catalog[] }): NavigationInterface[] {
    return catalog.map((group: Catalog) => ({
      title: group.sTitle,
      url: this.getCatalogUrlPath(group),
      subNavigation: this.getGroupsForParent(group.sUrlPath, parents),
    }));
  }

  private getCatalogUrlPath(category: Catalog) {
    return UrlPathHelperService.getUrlPath(category?.sUrlPath, this.translocoService.getActiveLang());
  }
}
