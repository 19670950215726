import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Cart } from '@lobos/common';
import { CART_CONFIG, CartConfig, CartHeader, CartHeaderQuery, CartItem, CartItemStore, FavoriteItem } from '@lobos/library';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CartBulkAddService {
  constructor(
    private cartHeaderQuery: CartHeaderQuery<CartHeader>,
    protected cartItemStore: CartItemStore<CartItem>,
    protected http: HttpClient,
    @Inject(CART_CONFIG) protected cartConfig: CartConfig,
  ) {}

  public async createCartItems(cartItems: any[], cartId: number | string | null | undefined): Promise<Cart<CartHeader, CartItem>> {
    return lastValueFrom(
      this.http.post<Cart<CartHeader, CartItem>>(this.cartConfig.apiUrl + '/so-carts/' + cartId + '/items/coll', cartItems),
    );
  }

  async addAllToCart(items: FavoriteItem[]): Promise<Cart<CartHeader, CartItem>> {
    const cartId: number | string | null | undefined = this.cartHeaderQuery.getActiveId();
    const data: Cart<CartHeader, CartItem> | any = await this.createCartItems(
      items.map((favoriteItem: FavoriteItem) => ({
        decQuantity: favoriteItem.decQuantity,
        sArticleID: favoriteItem.oArticle!.sArticleID as string,
        sArticleName: favoriteItem.oArticle!.sName,
        sQuantityUnit: favoriteItem.oArticle!.sQuantityUnitSales,
        oArticle: favoriteItem.oArticle!,
      })),
      cartId,
    );
    if (data.oSalesItemList.length) {
      this.cartItemStore.set(data.oSalesItemList);
    }

    return data;
  }
}
