<ng-container *transloco="let t; scope: 'core'">
  <div class="md:relative" [ngClass]="[classes, mobile ? '' : 'js-show-info']">
    <a
      [routerLink]="'/comparison/comparison-page' | localizeUrlPathAsync | async"
      class="text-xl hover:text-primary flex items-center relative"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
      </svg>
      <span class="text-xs font-bold text-white absolute -translate-y-1/2 top-1/2 -right-3 bg-primary px-1 rounded-full">{{
        (allItems$ | async)?.length
      }}</span>
    </a>
    <div class="js-info absolute translate-y-0 md:top-full md:-mr-32 lg:mr-0 -right-3 z-30 hidden">
      <ng-container *ngIf="(allItems$ | async)?.length; else empty">
        <div class="mt-4 max-h-500px md:max-h-50vh shadow rounded-md overflow-auto">
          <div class="w-83vw sm:w-470px md:w-640px p-4 bg-white">
            <div class="sm:pl-3">
              <div class="flex justify-between mb-4">
                <p class="font-bold text-lg">{{ t("core.header.flyout.comparison.heading") }}</p>
              </div>
            </div>
            <div class="sm:pl-3 md:my-4">
              <table *ngIf="articles$ | async as articles" class="w-full my-4 md:my-0">
                <thead class="bg-gray-200 hidden md:table-header-group">
                  <tr>
                    <td class="p-2 text-xs md:text-sm font-bold">{{ t("core.header.flyout.comparison.article") }}</td>
                    <td class="p-2 text-xs md:text-sm font-bold"></td>
                    <td class="p-2 text-xs md:text-sm font-bold"></td>
                    <td class="p-2 text-xs md:text-sm font-bold">{{ t("core.header.flyout.comparison.price") }}</td>
                    <td class="p-2 text-xs md:text-sm font-bold"></td>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr *ngFor="let article of articles">
                    <td class="p-2 align-middle text-xs md:text-sm">
                      <p class="font-bold">
                        <a class="hover:text-primary" [routerLink]="['article' | localizeUrlPathAsync | async, article.sArticleID]">{{
                          article.sName
                        }}</a>
                      </p>
                      <p>{{ article.sUniformNumber }}</p>
                    </td>
                    <td></td>
                    <td></td>
                    <td class="p-2 align-middle text-xs md:text-sm">
                      <p class="font-bold">
                        <app-article-price [article]="article"></app-article-price>
                      </p>
                    </td>
                    <td>
                      <button (click)="remove(article)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="w-full bg-white bottom-0 left-0 sm:px-4 pb-2 flex">
              <div class="w-auto">
                <button
                  [routerLink]="'/comparison/comparison-page' | localizeUrlPathAsync | async"
                  type="button"
                  class="w-full mt-2 inline-flex items-center px-1.5 md:px-2.5 py-1.5 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-primary hover:bg-secondary focus:bg-secondary"
                >
                  <span class="text-base font-bold text-white">{{ t("core.header.flyout.comparison.button") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #empty>
        <div class="w-83vw sm:w-368px p-4 bg-white">
          <p class="text-sm text-right">{{ t("core.header.flyout.comparison.empty") }}</p>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
