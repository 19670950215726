import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface CmsFooterContent {
  openHours: string;
  phone: string;
  fax: string;
  mail: string;
  moreInfos: any;
}

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {}
