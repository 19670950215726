import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LanguageRootComponent } from './services/language/language-root-component.service';
import { LanguageResolver } from './services/language/language.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LanguageRootComponent,
    children: [],
  },
  {
    path: '',
    loadChildren: () => import('./features/core/core.module').then((m) => m.CoreModule),
  },
  {
    path: ':lang',
    resolve: [LanguageResolver],
    children: [
      {
        path: 'registration',
        loadChildren: () => import('./features/registration/registration.module').then((m) => m.RegistrationModule),
      },
      {
        path: 'checkout',
        loadChildren: () => import('./features/checkout/checkout.module').then((m) => m.CheckoutModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./features/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'downloads',
        loadChildren: () => import('./features/cms/cms-features/downloads/downloads.module').then((m) => m.DownloadsModule),
      },
      {
        path: 'comparison',
        loadChildren: () => import('./features/compare/compare.module').then((m) => m.CompareModule),
      },
      {
        path: 'sales-rep',
        loadChildren: () => import('./features/sales-rep/sales-rep.module').then((m) => m.SalesRepModule),
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./features/cms/cms-features/home/home-page.module').then((m) => m.HomePageModule),
      },
      {
        path: 'cms',
        loadChildren: () => import('./features/cms/cms-features/page/page.module').then((m) => m.PageModule),
      },
      {
        path: 'blog',
        loadChildren: () => import('./features/cms/cms-features/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: 'not-found',
        loadChildren: () => import('./features/cms/cms-features/not-found/not-found.module').then((m) => m.NotFoundModule),
      },
      {
        path: '',
        loadChildren: () => import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
      },
      {
        path: 'product/:id',
        loadChildren: () => import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
      },
      {
        path: 'page/:id',
        loadChildren: () => import('./features/catalog/catalog.module').then((m) => m.CatalogModule),
      },
      {
        path: '**',
        loadChildren: () => import('./features/core/core.module').then((m) => m.CoreModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
