import { Pipe, PipeTransform } from '@angular/core';
import { Article } from '@lobos/library';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'articleBrandLink',
})
export class ArticleBrandLinkPipe implements PipeTransform {
  transform(article: Article): string {
    let link: string | undefined;

    link = article.oFeatures.find((feature) => feature.lngFeatureID === environment.brandFeatureId)?.sValue;

    if (!link) {
      link = article.oReferenceLink
        .find((referenceLink) => referenceLink.sTypeCode === 'LOGO')
        ?.sLinkReference.split('.')[0]
        .toUpperCase();
    }
    return '/brand/' + link;
  }
}
