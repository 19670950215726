import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DomHelperService } from '../../../services/dom/dom-helper.service';

@Component({
  selector: 'app-service-navigation',
  templateUrl: './service-navigation.component.html',
  styleUrls: ['./service-navigation.component.scss'],
})
export class ServiceNavigationComponent implements AfterViewInit {
  @Input() cssClass0: string = 'block xl:hidden';
  @Input() cssClass1: string = '';
  @Input() cssClass2: string = '';
  @Input() cssClass3: string = '';
  @Input() cssClass4: string = '';

  @Output() serviceItemClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit() {
    if (typeof window === 'undefined') {
      return;
    }

    // eslint-disable-next-line angular/timeout-service
    setTimeout(() => {
      //show info in service navigation below 1281 on click, all other on mouseenter/leave
      [].forEach.call(this.document.querySelectorAll('.js-show-info'), (infoLink: any) => {
        if (window.innerWidth < 1280) {
          infoLink.addEventListener('click', (e: Event) => {
            e.preventDefault();
            if (infoLink.querySelector('.js-info').classList.contains('block')) {
              DomHelperService.showContent(infoLink.querySelector('.js-info'));
            } else {
              DomHelperService.hideContent(infoLink.querySelector('.js-info'));
            }
          });
        }

        if (window.innerWidth < 768) {
          infoLink.addEventListener('click', (e: Event) => {
            e.preventDefault();
            if (infoLink.querySelector('.js-info').classList.contains('block')) {
              DomHelperService.showContent(infoLink.querySelector('.js-info'));
              infoLink.querySelector('.js-info').classList.add('hidden');
              infoLink.querySelector('.js-info').classList.remove('block');
            } else {
              DomHelperService.hideContent(infoLink.querySelector('.js-info'));
              infoLink.querySelector('.js-info').classList.remove('block');
            }
          });
        }

        DomHelperService.delay(infoLink, function () {
          DomHelperService.showContent(infoLink.querySelector('.js-info'));
        });

        infoLink.addEventListener('mouseleave', () => {
          if (this.document.querySelector('.js-info.block') !== null) {
            DomHelperService.hideContent(this.document.querySelector('.js-info.block'));
          }
        });
      });

      if (window.innerWidth < 1280) {
        [].forEach.call(this.document.querySelectorAll('.js-mobile-nav .js-cart .w-screen'), (cartEl: Element) => {
          cartEl.classList.remove('w-screen');
          cartEl.classList.add('w-83vw');
        });

        const jsCart: Element | null = this.document.querySelector('.js-close-subnav .js-cart');
        if (jsCart) {
          jsCart.classList.remove('-right-3');
          jsCart.classList.add('right-0');
        }
      }
    }, 750);
  }

  closeMenuByInnerComponentClick() {
    if (this.document.querySelector('.js-info.block') !== null) {
      DomHelperService.hideContent(this.document.querySelector('.js-info.block'));
      this.serviceItemClicked.emit();
    }
  }
}
