<ng-container *transloco="let t; scope: 'catalog'">
  <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async, authUser: authUser$ | async } as context">
    <ng-container *ngIf="oPrice$ | async as oPrice">
      <svg
        *ngIf="
          context.isLoggedIn &&
          !context.authUser?.oCustomer?.bPrivateCustomer &&
          (context.authUser?.oCustomer?.shtPL1EcommDefaultPrice === 0 || !context.authUser?.oCustomer?.shtPL1EcommDefaultPrice) &&
          oPrice &&
          oPrice.decPL1PromotionPrice &&
          oPrice.decPL1PromotionPrice !== 0
        "
        version="1.1"
        id="svg2"
        class="h-10 w-10 text-primary hover:text-secondary"
        width="158.99867"
        height="158.99867"
        viewBox="0 0 158.99867 158.99867"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs id="defs6">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath18">
            <path d="M 0,119.249 H 119.249 V 0 H 0 Z" id="path16" />
          </clipPath>
        </defs>
        <g id="g10" inkscape:groupmode="layer" inkscape:label="Page 1" transform="matrix(1.3333333,0,0,-1.3333333,0,158.99867)">
          <g id="g12">
            <g id="g14" clip-path="url(#clipPath18)">
              <g id="g20" transform="translate(110.7449,59.6244)">
                <path
                  d="m 0,0 c 0,-28.233 -22.887,-51.12 -51.12,-51.12 -28.234,0 -51.121,22.887 -51.121,51.12 0,28.233 22.887,51.12 51.121,51.12 C -22.887,51.12 0,28.233 0,0"
                  style="fill: #e53338; fill-opacity: 1; fill-rule: nonzero; stroke: none"
                  id="path22"
                />
              </g>
              <g id="g24" transform="translate(41.4945,34.9659)">
                <path
                  d="M 0,0 34.532,46.736"
                  style="
                    fill: none;
                    stroke: #ffffff;
                    stroke-width: 4;
                    stroke-linecap: round;
                    stroke-linejoin: miter;
                    stroke-miterlimit: 10;
                    stroke-dasharray: none;
                    stroke-opacity: 1;
                  "
                  id="path26"
                />
              </g>
              <g id="g28" transform="translate(46.0114,83.2235)">
                <path
                  d="m 0,0 v 0 c -4.498,0 -8.398,-3.206 -9.273,-7.621 -0.491,-2.479 0.012,-5 1.418,-7.1 1.405,-2.099 3.544,-3.526 6.022,-4.017 0.608,-0.121 1.228,-0.182 1.844,-0.182 4.498,0 8.398,3.206 9.273,7.622 C 10.298,-6.182 6.96,-1.195 1.844,-0.181 1.236,-0.061 0.616,0 0,0 m 0,-4 c 0.352,0 0.708,-0.034 1.067,-0.105 2.957,-0.586 4.879,-3.459 4.293,-6.416 -0.515,-2.599 -2.796,-4.399 -5.349,-4.399 -0.351,0 -0.708,0.034 -1.066,0.105 -2.958,0.586 -4.88,3.459 -4.294,6.416 C -4.834,-5.8 -2.553,-4 0,-4"
                  style="fill: #ffffff; fill-opacity: 1; fill-rule: nonzero; stroke: none"
                  id="path30"
                />
              </g>
              <g id="g32" transform="translate(71.0121,51.3976)">
                <path
                  d="m 0,0 v 0 c -4.498,0 -8.398,-3.206 -9.273,-7.621 -1.013,-5.116 2.324,-10.104 7.44,-11.117 0.608,-0.121 1.228,-0.182 1.845,-0.182 4.497,0 8.397,3.206 9.272,7.622 C 10.298,-6.182 6.96,-1.195 1.844,-0.181 1.237,-0.061 0.616,0 0,0 m 0,-4 c 0.352,0 0.708,-0.034 1.067,-0.105 2.957,-0.586 4.879,-3.459 4.293,-6.416 -0.515,-2.599 -2.796,-4.399 -5.348,-4.399 -0.352,0 -0.709,0.034 -1.067,0.105 -2.958,0.586 -4.88,3.459 -4.294,6.416 C -4.834,-5.8 -2.553,-4 0,-4"
                  style="fill: #ffffff; fill-opacity: 1; fill-rule: nonzero; stroke: none"
                  id="path34"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </ng-container>
  </ng-container>
</ng-container>
