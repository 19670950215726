import { Pipe, PipeTransform } from '@angular/core';
import { CartItem } from '@lobos/library';
import { ID } from '@datorama/akita';

@Pipe({ name: 'cartItemQuantitySum' })
export class CartItemQuantitySumPipe implements PipeTransform {
  transform(items: CartItem[], id: ID): number {
    return items
      .filter((item) => item.sArticleID === id)
      .reduce((value, item) => {
        return value + item.decQuantityOrdered;
      }, 0);
  }
}
