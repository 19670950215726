import { Pipe, PipeTransform } from '@angular/core';
import { CartItem, SalesCreditNoteItem, SalesOfferItem, SalesOrderItem } from '@lobos/library';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';

@Pipe({
  name: 'cartItemIsChild',
})
export class CartItemIsChildPipe implements PipeTransform {
  transform<T extends CartItem | SalesOrderItem | SalesOfferItem | SalesCreditNoteItem>(items: T[], parent: T): T[] {
    return items ? items.filter((item: T) => ArticleHelperService.itemIsChildOf(item, parent)) : [];
  }
}
