import { Pipe, PipeTransform } from '@angular/core';
import { ShippingAddress } from '@lobos/library';
import { AddressHelperService } from '../../services/catalog/address-helper.service';

@Pipe({
  name: 'addressSummary',
})
export class AddressSummaryPipe implements PipeTransform {
  constructor(private addressHelperService: AddressHelperService) {}

  public transform(address: ShippingAddress): string {
    return this.addressHelperService.summary(address);
  }
}
