import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input } from '@angular/core';
import { DomHelperService } from '../../services/dom/dom-helper.service';

@Directive({
  selector: '[appHoverMenu]',
})
export class HoverMenuDirective implements AfterViewInit {
  @Input() appHoverMenu!: {
    subnav: string;
    hideSubnav: string;
    closeSubnav: string;
    activeSelector: string;
  };

  constructor(@Inject(DOCUMENT) private document: Document, private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const item: HTMLElement = this.elementRef.nativeElement;
    DomHelperService.delay(item, () => {
      const currentActiveSubnavEl = this.document.querySelector(`${this.appHoverMenu.subnav}${this.appHoverMenu.activeSelector}`);
      const subnavToBeActivatedEl = item.parentNode!.querySelector(this.appHoverMenu.subnav);

      if (currentActiveSubnavEl !== null) {
        DomHelperService.hideContent(currentActiveSubnavEl);
      }

      DomHelperService.showContent(subnavToBeActivatedEl);
    });

    if (this.document.querySelector(this.appHoverMenu.subnav) !== null) {
      [].forEach.call(this.document.querySelectorAll(this.appHoverMenu.subnav), (subnav: Element) => {
        subnav.addEventListener('mouseleave', () => {
          const currentActiveSubnavEl = this.document.querySelector(`${this.appHoverMenu.subnav}${this.appHoverMenu.activeSelector}`);
          if (currentActiveSubnavEl !== null) {
            DomHelperService.hideContent(currentActiveSubnavEl);
          }
        });
      });

      [].forEach.call(this.document.querySelectorAll(this.appHoverMenu.hideSubnav), (subnav: Element) => {
        subnav.addEventListener('mouseenter', () => {
          const currentActiveSubnavEl = this.document.querySelector(`${this.appHoverMenu.subnav}${this.appHoverMenu.activeSelector}`);
          if (currentActiveSubnavEl !== null) {
            DomHelperService.hideContent(currentActiveSubnavEl);
          }
        });
      });
    }

    //if mouseenter on service- or topnavigation close current shown subnav
    [].forEach.call(this.document.querySelectorAll(this.appHoverMenu.closeSubnav), (closenav: Element) => {
      closenav.addEventListener('mouseenter', () => {
        if (this.document.querySelector(`${this.appHoverMenu.subnav}${this.appHoverMenu.activeSelector}`) !== null) {
          DomHelperService.hideContent(this.document.querySelector(`${this.appHoverMenu.subnav}${this.appHoverMenu.activeSelector}`));
        }
      });
    });
  }
}
