import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { CartItem, CreateCartItemInterface } from '@lobos/library';

@Component({
  selector: 'app-qty-modal',
  templateUrl: './qty-modal.component.html',
  styleUrls: ['./qty-modal.component.scss'],
})
export class QtyModalComponent extends SimpleModalComponent<
  CreateCartItemInterface | CartItem,
  CreateCartItemInterface | CartItem | undefined
> {
  oCartItem!: CreateCartItemInterface | CartItem;
  decQuantityDown: number = 0;
  decQuantityUp: number = 0;
  decQuantityDefault: number = 1;

  constructor() {
    super();
  }

  override mapDataObject(data: CreateCartItemInterface | CartItem) {
    super.mapDataObject(data);
    this.oCartItem = data;
    this.setData();
  }

  setData() {
    if (this.oCartItem.oArticle.decQuantityPackage <= 0) this.oCartItem.oArticle.decQuantityPackage = 1;

    // rounding down
    //	this.decQuantityDown = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
    this.decQuantityDown = Math.floor(this.oCartItem.decQuantity / this.oCartItem.oArticle.decQuantityPackage);
    this.decQuantityDown = this.decQuantityDown * this.oCartItem.oArticle.decQuantityPackage;

    // rounding up
    //	this.decQuantityUp = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
    this.decQuantityUp = Math.ceil(this.oCartItem.decQuantity / this.oCartItem.oArticle.decQuantityPackage);
    this.decQuantityUp = this.decQuantityUp * this.oCartItem.oArticle.decQuantityPackage;

    this.decQuantityDefault = this.oCartItem.oArticle.decQuantityPackage > 0 ? this.oCartItem.oArticle.decQuantityPackage : 1;
  }

  roundUp() {
    this.result = { ...this.oCartItem, decQuantity: this?.oCartItem?.decQuantity ? this.decQuantityUp : this.decQuantityDefault };
    this.close();
  }

  roundDown() {
    this.result = { ...this.oCartItem, decQuantity: this?.oCartItem?.decQuantity ? this.decQuantityDown : this.decQuantityDefault };
    this.close();
  }

  cancel() {
    this.result = undefined;
    this.close();
  }
}
