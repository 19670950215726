<ng-container *transloco="let t">
  <app-scanner-button (click)="serviceItemClicked.emit()" [classes]="cssClass0"></app-scanner-button>
  <app-user-flyout [classes]="cssClass1" (userItemClicked)="closeMenuByInnerComponentClick()"></app-user-flyout>
  <app-comparison-flyout (click)="serviceItemClicked.emit()" [classes]="cssClass2"></app-comparison-flyout>
  <app-favorites-flyout
    (click)="serviceItemClicked.emit()"
    [classes]="cssClass3"
    (closeMenu)="closeMenuByInnerComponentClick()"
  ></app-favorites-flyout>
  <app-cart-flyout
    (click)="serviceItemClicked.emit()"
    [classes]="cssClass4"
    (closeMenu)="closeMenuByInnerComponentClick()"
  ></app-cart-flyout>
</ng-container>
