import { Component, Input } from '@angular/core';
import { Article, Price, PriceQuery } from '@lobos/library';
import { BehaviorSubject, iif, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ArticleHelperService } from '../../services/catalog/article-helper.service';

@Component({
  selector: 'app-article-scaled-discount',
  templateUrl: './article-scaled-discount.component.html',
  styleUrls: ['./article-scaled-discount.component.scss'],
})
export class ArticleScaledDiscountComponent {
  private _article: Article | undefined;
  @Input() set article(value: Article | undefined) {
    this._article = value;
    this.articleChanged$.next(value);
  }

  get article(): Article | undefined {
    return this._article;
  }

  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);

  oPrice$: Observable<Price | undefined> = this.articleChanged$.pipe(
    switchMap(() =>
      iif(
        () => this.priceQuery.hasEntity(this.article?.sArticleID),
        this.priceQuery.selectEntity(this.article?.sArticleID),
        this.articleHelper.getPriceFromApi(this.article!).pipe(switchMap(() => this.priceQuery.selectEntity(this.article?.sArticleID))),
      ),
    ),
  );

  public isOpen: boolean = false;

  constructor(private articleHelper: ArticleHelperService, private priceQuery: PriceQuery) {}

  open(event: Event) {
    event.stopPropagation();
    this.isOpen = true;
  }

  close(_: Event) {
    this.isOpen = false;
  }
}
