<ng-container *transloco="let t; scope: 'core'">
  <div class="fixed z-60 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-lg leading-6 font-bold" id="modal-title">{{ title }}</h3>
            <div class="mt-2">
              <p class="text-sm">{{ message }}</p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex space-x-4">
          <app-text-button [text]="t('core.modal-confirm.confirm')" (click)="confirm()"></app-text-button>
          <button
            (click)="cancel()"
            type="button"
            class="w-auto mt-2 inline-flex items-center px-1.5 md:px-3.5 py-1.5 border border-transparent text-xs font-bold rounded shadow-sm text-primary bg-primary text-white hover:bg-secondary group focus:bg-secondary"
          >
            <span class="text-sm xl:text-base font-bold">{{ t("core.modal-confirm.cancel") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
