import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HookEvent, HookHandlerAbstract, WorkflowCreateHook } from '@lobos/library';
import { Cart, CartHeader, CartItem } from '@lobos/common';
import { catchError } from 'rxjs/operators';
import { CartErrorModalComponent } from '../../../components/cart-error-modal/cart-error-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';

type type = WorkflowCreateHook<'CreateCartItemHook'>;

@Injectable({
  providedIn: 'root',
})
export class CartErrorHandler extends HookHandlerAbstract<type, Observable<Cart<CartHeader, CartItem>>> {
  constructor(private modalService: SimpleModalService) {
    super();
  }

  ids(): type[] {
    return [{ type: 'WorkflowCreateHook', entity: 'CreateCartItemHook' }];
  }

  override after(
    event: HookEvent<type, Observable<Cart<CartHeader, CartItem>>>,
    result: Observable<Cart<CartHeader, CartItem>>,
  ): Observable<Cart<CartHeader, CartItem>> {
    return super.after(event, result).pipe(
      catchError((e: any) => {
        if (e !== 'Canceled add to cart of toxin') {
          this.modalService.addModal(CartErrorModalComponent, { closeOnClickOutside: true, closeOnEscape: true });
        }

        return [];
      }),
    );
  }
}
