<ng-container *transloco="let t; scope: 'core'">
  <div class="md:relative" [ngClass]="[classes, mobile ? '' : 'js-show-info']" *ngIf="activeFavoriteItems$ | async as activeFavoriteItems">
    <ng-container *ngIf="mobile; else notMobile">
      <a
        [routerLink]="
          (activeFavoriteList$ | async)
            ? ['profile/favorites/detail/' + (activeFavoriteList$ | async)!.gListID | localizeUrlPathAsync | async]
            : []
        "
        class="cursor-pointer text-xl hover:text-primary flex items-center relative"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
        <span class="text-xs font-bold text-white absolute -translate-y-1/2 top-1/2 -right-3 bg-primary px-1 rounded-full">{{
          (activeFavoriteItems$ | async)?.length
        }}</span>
        <span class="sr-only">{{ t("core.header.flyout.favorite.title") }}</span>
      </a>
    </ng-container>

    <ng-template #notMobile>
      <a
        [routerLink]="
          (activeFavoriteList$ | async)
            ? ['profile/favorites/detail/' + (activeFavoriteList$ | async)!.gListID | localizeUrlPathAsync | async]
            : []
        "
        class="cursor-pointer text-xl hover:text-primary flex items-center relative"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
        <span class="text-xs font-bold text-white absolute -translate-y-1/2 top-1/2 -right-3 bg-primary px-1 rounded-full">{{
          (activeFavoriteItems$ | async)?.length
        }}</span>
        <span class="sr-only">{{ t("core.header.flyout.favorite.title") }}</span>
      </a>
    </ng-template>

    <div class="js-info absolute translate-y-0 md:top-full md:-mr-12 lg:mr-0 -right-3 z-30 bg-white hidden">
      <ng-container *ngIf="(activeFavoriteItems$ | async)?.length; else empty">
        <div class="mt-4 max-h-500px md:max-h-50vh shadow rounded-md overflow-auto pb-62px">
          <div class="w-83vw sm:w-470px md:w-640px p-4 bg-white">
            <div class="sm:pl-3" *ngIf="activeFavoriteList$ | async as favoriteList">
              <div class="flex justify-between mb-4">
                <p class="font-bold text-lg">{{ t("core.header.flyout.favorite.title") }}</p>
                <div class="flex space-x-4 items-start">
                  <a
                    (click)="closeMenu.emit()"
                    [routerLink]="'profile/favorites' | localizeUrlPathAsync | async"
                    class="text-sm font-bold hover:text-primary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </a>
                  <button (click)="addFavoriteList()" class="js-show-bookmark text-left text-sm font-bold hover:text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <app-custom-select
                [value]="favoriteList.gListID.toString()"
                (valueChange)="setActiveFavoriteList($event)"
                *ngIf="favoriteLists$ | async as favorites"
                [options]="favorites"
              ></app-custom-select>
            </div>
            <div class="sm:pl-3 md:my-4">
              <table class="w-full my-4 md:my-0">
                <thead class="bg-gray-200 hidden md:table-header-group">
                  <tr>
                    <td class="w-table-article p-2 text-xs md:text-sm font-bold">{{ t("core.header.flyout.favorite.article") }}</td>
                    <td class="w-table-total p-2 text-xs md:text-sm font-bold">{{ t("core.header.flyout.favorite.total") }}</td>
                    <td class="w-130px p-2 text-xs md:text-sm font-bold"></td>
                    <td class="w-160px p-2 text-xs md:text-sm font-bold"></td>
                    <td class="w-40px p-2 text-xs md:text-sm font-bold"></td>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr
                    app-favorites-flyout-item
                    *ngFor="let item of activeFavoriteItems$ | async"
                    [favoriteItem]="item"
                    class="flex flex-wrap md:table-row relative py-4 md:py-0"
                  ></tr>
                </tbody>
              </table>
            </div>
            <div class="w-full bg-white rounded-md bottom-0 left-0 fixed px-4 sm:px-8 pb-4 flex">
              <div class="w-auto">
                <app-text-button
                  [disabled]="bulkAddLoading"
                  [isLoading]="bulkAddLoading"
                  [primary]="true"
                  [text]="t('core.header.flyout.favorite.button.add-all-to-cart')"
                  (click)="bulkAddToCart(activeFavoriteItems)"
                >
                </app-text-button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #empty>
        <div class="w-83vw sm:w-368px p-4 bg-white">
          <p class="text-sm text-right">{{ t("core.header.flyout.favorite.empty") }}</p>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
