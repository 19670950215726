import { Injectable } from '@angular/core';
import { ShippingAddress } from '@lobos/library';

@Injectable({
  providedIn: 'root',
})
export class AddressHelperService {
  /**
   * Creates a summary string of a shipping address
   */
  public summary(address: ShippingAddress): string {
    const parts: string[] = [];
    parts.push(address.sCompany1);
    parts.push(address.sStreet);
    parts.push(`${address.sZipCode} ${address.sCity}`);
    parts.push(address.sCountryCode);

    return parts.filter((part: string) => !!part).join(', ');
  }

  /**
   * Checks if two addresses are the same
   */
  public isEqual(address1: ShippingAddress, address2: ShippingAddress): boolean {
    if (!address1 || !address2) {
      return false;
    }

    if (address1.lngAddressID && address2.lngAddressID) {
      return address1.lngAddressID === address2.lngAddressID;
    }

    return (
      address1.sCompany1 === address2.sCompany1 &&
      address1.sCity === address2.sCity &&
      address1.sStreet === address2.sStreet &&
      address1.sZipCode === address2.sZipCode &&
      address1.sCountryCode === address2.sCountryCode
    );
  }
}
