import { Component, Input } from '@angular/core';
import {
  Article,
  createFavoriteItem,
  FavoriteItem,
  FavoriteItemQuery,
  FavoriteList,
  FavoriteListQuery,
  FavoriteService,
} from '@lobos/library';
import { first, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-article-favorite',
  templateUrl: './article-favorite.component.html',
  styleUrls: ['./article-favorite.component.scss'],
})
export class ArticleFavoriteComponent {
  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);
  private _article: Article | undefined;
  @Input() set article(value: Article | undefined) {
    if (value) {
      this._article = value;
      this.articleChanged$.next(value);
    }
  }

  get article(): Article | undefined {
    return this._article;
  }

  @Input() showAsButton: boolean = false;
  @Input() onArticlePage = false;

  isFavorite$: Observable<boolean> = this.articleChanged$.pipe(
    switchMap(() => this.favoriteListQuery.selectActiveId()),
    switchMap((id: any) => this.favoriteItemQuery.selectFavoriteItems(id)),
    map((items: FavoriteItem[]) => {
      return !!items.find((item) => item.oArticle?.sArticleID === this.article?.sArticleID);
    }),
  );

  constructor(
    private favoriteItemQuery: FavoriteItemQuery<FavoriteItem>,
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
    private favoriteListQuery: FavoriteListQuery<FavoriteList>,
  ) {}

  addArticleToFavoriteList(article: Article) {
    const activeFavoriteListId = this.favoriteListQuery.getActiveId();
    const newFavoriteItem = createFavoriteItem({
      gListID: activeFavoriteListId as any,
      oArticle: article as any,
      decQuantity: 1,
    });
    this.favoriteService
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .createFavoriteItem(newFavoriteItem, activeFavoriteListId!)
      .pipe(take(1), untilDestroyed(this))
      .subscribe();
  }

  removeArticleFromFavoriteList(article: Article) {
    this.favoriteItemQuery
      .selectEntity(
        (item: FavoriteItem) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          item.oArticle?.sArticleID === article.sArticleID && item.gListID === this.favoriteListQuery.getActiveId(),
      )
      .pipe(
        first(),
        switchMap((favoriteItem: FavoriteItem | undefined) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.favoriteService.deleteFavoriteItem(favoriteItem!),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
