import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '@lobos/library';

@Pipe({
  name: 'roleList',
})
export class RoleListPipe implements PipeTransform {
  public transform(roles: Role[] | null): { key: number | string; value: string }[] {
    const normalizedRoles: { key: number | string; value: string }[] = [];
    if (!roles?.length) {
      return normalizedRoles;
    }

    // Filter roles based on IDs 10 and 20
    const filteredRoles = roles.filter((role) => role.lngAPIAuthorizationGroupID === 10 || role.lngAPIAuthorizationGroupID === 20);

    for (const role of filteredRoles) {
      normalizedRoles.push({
        key: role.lngAPIAuthorizationGroupID,
        value: role.sRoleName,
      });
    }

    return normalizedRoles;
  }
}
