import { Pipe, PipeTransform } from '@angular/core';
import { Article, Product, ReferenceLink, Ressource } from '@lobos/library';

@Pipe({
  name: 'videoListArticle',
})
export class VideoListArticlePipe implements PipeTransform {
  public article: Article | undefined;
  public product: Product | undefined;

  public transform(article: Article): string[] {
    let referenceLinks: ReferenceLink[] = [];

    if (article.oReferenceLink.length > 0) {
      referenceLinks = article.oReferenceLink.filter((item: ReferenceLink) => item.sTypeCode === 'YOUTUBE');
    }

    let resourceLinks: Ressource[] = [];

    if (article.oProductInfo.length > 0) {
      resourceLinks = article.oProductInfo[0]?.oResources?.filter((item: Ressource) => item.shtResourceGroupIDInternal === 306);
    }

    let articleYoutubeLinks: string[] = [];
    if (referenceLinks.length > 0) {
      articleYoutubeLinks = referenceLinks?.map((link) => link.sLinkReference);
    }

    let productYoutubeLinks: string[] = [];
    if (resourceLinks.length > 0) {
      productYoutubeLinks = resourceLinks?.map((link) => link.sResourceName);
    }

    const youtubeLinks: string[] = [...articleYoutubeLinks, ...productYoutubeLinks];

    if (youtubeLinks.length > 0) {
      return [...new Set(youtubeLinks)];
    } else {
      return [];
    }
  }
}
