import { CommonModule } from '@angular/common';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { HookHandlerFactoryService, InjectorClass } from '@lobos/library';
import { AuthorizationChangedResetStoresHandler } from './handler/authorization-changed-reset-stores.handler';
import { AuthorizationChangedUpdateSentryHandler } from './handler/authorization-changed-update-sentry.handler';
import { AuthorizedActivateCartHandler } from './handler/authorized-activate-cart.handler';
import { AuthorizedActivateFavoriteListHandler } from './handler/authorized-activate-favorite-list.handler';
import { AuthorizedConvertLocalCartHandler } from './handler/authorized-convert-local-cart.handler';
import { AuthorizedConvertLocalFavoritesHandler } from './handler/authorized-convert-local-favorites.handler';
import { ErrorLogToConsoleHandler } from './handler/error-log-to-console.handler';
import { ErrorSendToSentryHandler } from './handler/error-send-to-sentry.handler';
import { ErrorSendToTrackingHandler } from './handler/error-send-to-tracking.handler';
import { LanguageChangedChangeElasticSearchIndexHandler } from './handler/language-changed-change-elasticsearch-index.handler';
import { LanguageChangedCmsStoresHandler } from './handler/language-changed-cms-stores.handler';
import { LaunchedLoadActiveCartHandler } from './handler/launched-load-active-cart.handler';
import { LaunchedLoadActiveFavoritesHandler } from './handler/launched-load-active-favorites.handler';
import { LanguageChangedCatalogMenuHandler } from './handler/language-changed-catalog-menu.handler';
import { CartItemCreatedQtyInputHandlerHandler } from './handler/cart-item-created-qty-input-handler';
import { ComponentsModule } from '../../components/components.module';
import { CartItemUpdatedQtyInputHandlerHandler } from './handler/cart-item-updated-qty-input-handler';
import { CartItemCreatedToxinsHandlerHandler } from './handler/cart-item-created-toxins-handler';
import { CartErrorHandler } from './handler/cart-error.handler';

const hookHandlers: any[] = [
  LanguageChangedCatalogMenuHandler,
  LaunchedLoadActiveCartHandler,
  LaunchedLoadActiveFavoritesHandler,
  ErrorLogToConsoleHandler,
  ErrorSendToSentryHandler,
  LanguageChangedCmsStoresHandler,
  LanguageChangedChangeElasticSearchIndexHandler,
  ErrorSendToTrackingHandler,
  AuthorizationChangedUpdateSentryHandler,
  AuthorizedConvertLocalCartHandler,
  AuthorizedConvertLocalFavoritesHandler,
  AuthorizedActivateCartHandler,
  AuthorizedActivateFavoriteListHandler,
  AuthorizationChangedResetStoresHandler,
  CartItemCreatedQtyInputHandlerHandler,
  CartItemUpdatedQtyInputHandlerHandler,
  CartItemCreatedToxinsHandlerHandler,
  CartErrorHandler,
];

@NgModule({
  imports: [CommonModule, ComponentsModule],
})
export class HookHandlerModule {
  constructor(injector: Injector, hookHandlerFactory: HookHandlerFactoryService) {
    InjectorClass.set(injector);

    for (const hookHandler of hookHandlers) {
      hookHandlerFactory.register(injector.get<any>(hookHandler));
    }
  }

  static forRoot(): ModuleWithProviders<HookHandlerModule> {
    return {
      ngModule: HookHandlerModule,
      providers: [],
    };
  }
}
