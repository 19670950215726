import { Pipe, PipeTransform } from '@angular/core';
import { ArticleCount, Product } from '@lobos/library';

@Pipe({
  name: 'categoryFilterList',
})
export class CategoryFilterListPipe implements PipeTransform {
  transform(products: Product[], articleCount: ArticleCount[] | null): { product: Product; articleInfo: ArticleCount }[] {
    const result: { product: Product; articleInfo: ArticleCount }[] = [];
    products.forEach((product) => {
      result.push({
        product,
        articleInfo: articleCount?.find((articleCount) => articleCount.lngGroupId === product.lngGroup) || {
          count: 2,
          lngGroupId: 0,
          sArticleID: '',
        },
      });
    });
    return result.filter(
      (article, index, self) => index === self.findIndex((t) => t.articleInfo?.sArticleID === article.articleInfo?.sArticleID),
    );
  }
}
