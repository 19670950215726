import { Component, Input } from '@angular/core';
import { Article } from '@lobos/library';

@Component({
  selector: 'app-scanner-results',
  templateUrl: './scanner-results.component.html',
  styleUrls: ['./scanner-results.component.scss'],
})
export class ScannerResultsComponent {
  @Input() articles: Article[] = [];
}
