<!-- start footer -->
<footer class="bg-secondary" aria-labelledby="footer-heading">
  <h2 id="footer-heading" class="sr-only">Footer</h2>
  <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div class="xl:grid xl:grid-cols-2 xl:gap-8">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-8 xl:col-span-2">
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <app-footer-address></app-footer-address>
          <app-footer-menu></app-footer-menu>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <app-footer-menu2></app-footer-menu2>
          <app-footer-logos></app-footer-logos>
        </div>
      </div>
    </div>
    <app-footer-bottom class="md:mt-8 md:border-t border-white pt-8 block md:flex md:items-center md:justify-between"></app-footer-bottom>
  </div>
</footer>
<!-- end footer -->
