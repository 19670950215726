import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { EqualValidator } from '../../validators/equal.validator';

@UntilDestroy()
@Component({
  selector: 'app-password-change-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: ['./password-reset-modal.component.scss'],
})
export class PasswordResetModalComponent extends SimpleModalComponent<null, null> {
  guiId: string | undefined;

  showPasswordChange = false;
  public form: FormGroup = this.fb.group(
    {
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required]],
    },
    {
      validators: [EqualValidator('password', 'passwordConfirm')],
    },
  );

  constructor(private fb: FormBuilder, private authService: AuthService) {
    super();
  }

  public async changePassword(): Promise<void> {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    of(this.form.value)
      .pipe(
        switchMap((formValues: Record<string, string>) =>
          from(
            this.authService.resetPassword({
              sNewPassword: formValues['password'],
              sRegID: this.guiId!,
            }),
          ),
        ),
        catchError((error) => {
          this.form.controls['password'].setErrors({ backend: true });
          this.form.controls['passwordConfirm'].setErrors({ backend: true });
          return error;
        }),
        untilDestroyed(this),
      )
      .subscribe(() => this.close());
  }
}
