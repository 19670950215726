import { Component, Input, OnInit } from '@angular/core';
import { Article, CompareService } from '@lobos/library';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-article-compare-item',
  templateUrl: './article-compare-item.component.html',
  styleUrls: ['./article-compare-item.component.scss'],
})
export class ArticleCompareItemComponent implements OnInit {
  @Input() article!: Article;
  @Input() showAsButton: boolean = false;

  isCompare$: Observable<boolean> | undefined;

  constructor(private compareService: CompareService<Article>) {}

  ngOnInit(): void {
    this.isCompare$ = this.compareService.items$.pipe(
      map((items: Article[]) => {
        return !!items.find((item: Article) => item.sArticleID === this.article.sArticleID);
      }),
    );
  }

  addToCompareList(article: Article): void {
    this.compareService.add(article).pipe(take(1)).subscribe();
  }

  removeFromCompareList(article: Article): void {
    this.compareService.remove(article).pipe(take(1)).subscribe();
  }
}
