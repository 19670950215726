import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { LabelPrintModule, MapperAbstract, TemplateRendererFactory } from '@lobos/label-print';
import {
  AuthModule,
  BacklogModule,
  CartModule,
  CatalogModule,
  CatalogStore,
  CmsUiDynamicContentQuery,
  CmsUiDynamicContentService,
  CoreModule,
  CrudModule,
  ElasticsearchModule,
  ErrorInterface,
  ErrorModule,
  FavoriteModule,
  InjectorClass,
  PageMetaModule,
  ParameterModule,
  SalesCreditNoteModule,
  SalesDocumentModule,
  SalesOfferModule,
  SalesOrderModule,
  ScannerAdapterAbstract,
  ScannerModule,
  SearchService,
  SentryModule,
  StrapiCmsModule,
  UrlHelperService,
} from '@lobos/library';
import { ZbarAdapter, ZbarConfig, ZbarSymbologyEnum } from '@lobos/scanner-adapter-zbar';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { CookieModule } from 'ngx-cookie';
import { MarkdownModule } from 'ngx-markdown';
import { defaultSimpleModalOptions, SimpleModalModule } from 'ngx-simple-modal';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { HookHandlerModule } from './hooks/hook-handler/hook-handler.module';
import { HookTriggerModule } from './hooks/hook-trigger/hook-trigger.module';
import { PipesModule } from './pipes/pipes.module';
import { SuedoCmsUiDynamicContentQuery, SuedoCmsUiDynamicContentService } from './services/cms';
import { SuedoFavoriteMapper } from './services/label-print/mapper/suedo-favorite.mapper';
import { SuedoConfigurationExtraInterface } from './services/label-print/model/suedo-configuration-extra.interface';
import { Suedo3x10QrRenderer } from './services/label-print/renderer/suedo3x10_qr.renderer';
import { Suedo3x11QrRenderer } from './services/label-print/renderer/suedo3x11_qr.renderer';
import { Suedo3x17QrRenderer } from './services/label-print/renderer/suedo3x17_qr.renderer';
import { Suedo3x8QrRenderer } from './services/label-print/renderer/suedo3x8_qr.renderer';
import { TranslocoHttpLoaderService } from './services/language/transloco-http-loader.service';
import { BrowserStateInterceptor } from './interceptors/browser-state-interceptor';
import { SuedoSearchService } from './services/catalog/suedo-search.service';
import { SuedoCatalogStore } from './services/catalog/catalog/catalog.store';
import { CustomUrlHelperService } from './services/util/custom-url-helper.service';
import { Observable, of } from 'rxjs';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ErrorModule.forRoot({
      showOutput: !environment.production || environment.debugging,
      disableErrorHandler: environment.debugging,
      filters: [
        {
          filter(error: ErrorInterface): Observable<ErrorInterface> {
            if (error.error.status && (error.error.status === 401 || error.error.status === 429)) {
              return of({ ...error, ignore: true });
            }
            return of(error);
          },
        },
      ],
    }),
    CoreModule.forRoot({
      baseUrl: environment.host,
      branchKey: environment.api.branchKey,
      webshopId: environment.api.webshopId,
      mobileBreakpoint: '1279px',
      tabletBreakpoint: '1279px',
      useConsent: true,
    }),
    SentryModule.forRoot({
      active: environment.sentry.active,
      debug: environment.debugging,
      environment: environment.name,
      dsn: environment.sentry.dsn,
      release: environment.version,
    }),
    HookHandlerModule.forRoot(),
    ElasticsearchModule.forRoot({
      availableLanguages: environment.availableLang,
      defaultLanguage: environment.defaultLocale,
    }),
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AuthModule.forRoot({
      unauthorizedRoute: environment.auth.routes.login,
      authorizedRoute: environment.auth.routes.home,
      apiUrl: environment.apiUrl,
    }),
    LabelPrintModule.forRoot({
      logoPath: '/assets/images/layout/Logo_Suedo_Jasa_55px-h_Web.png',
      images: environment.images,
      mapper: { provide: MapperAbstract, useClass: SuedoFavoriteMapper },
    }),
    AppRoutingModule,
    HttpClientModule,
    CartModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    FavoriteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    TranslocoLocaleModule.forRoot({ langToLocaleMapping: { en: 'en-US', de: 'de-CH', fr: 'fr-CH' } }),
    TranslocoMessageFormatModule.forRoot(),
    StrapiCmsModule.forRoot({ apiUrl: environment.cms.apiUrl }),
    ComponentsModule,
    BrowserTransferStateModule,
    PipesModule,
    PageMetaModule,
    ParameterModule,
    // GoogleTagManagerAdapterModule.forRoot({ id: environment.tracking.gtm }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    CatalogModule.forRoot({
      apiUrl: environment.host + environment.apiUrl,
      sortBy: 'relevance',
      viewMode: 'grid',
      pageIndex: 0,
      pageSize: 12,
    }),
    HookTriggerModule.forRoot(),
    CookieModule.forRoot(),
    SimpleModalModule.forRoot(
      { container: 'modal-container' },
      {
        ...defaultSimpleModalOptions,
        ...{
          closeOnEscape: true,
          closeOnClickOutside: true,
          autoFocus: true,
        },
      },
    ),
    MarkdownModule.forRoot(),
    SalesOfferModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesOrderModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesCreditNoteModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    SalesDocumentModule.forRoot({
      apiUrl: environment.apiUrl,
      useArchive: false,
    }),
    ScannerModule.forRoot<ZbarConfig>({
      adapter: { provide: ScannerAdapterAbstract, useClass: ZbarAdapter },
      scanFrequency: 750,
      adapterConfig: {
        engineLocation: 'assets/wasm/',
        symbology: [ZbarSymbologyEnum.EAN13, ZbarSymbologyEnum.QR, ZbarSymbologyEnum.CODE128],
      },
    }),
    CrudModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
    BacklogModule.forRoot({
      apiUrl: environment.apiUrl,
    }),
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: environment.availableLang,
        defaultLang: environment.defaultLocale,
        fallbackLang: environment.defaultLocale,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: { allowEmpty: true },
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    { provide: CmsUiDynamicContentService, useClass: SuedoCmsUiDynamicContentService },
    { provide: CmsUiDynamicContentQuery, useClass: SuedoCmsUiDynamicContentQuery },
    { provide: SearchService, useClass: SuedoSearchService },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoaderService },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: environment.host + environment.apiUrl },
    },
    {
      provide: CatalogStore,
      useClass: SuedoCatalogStore,
    },
    {
      provide: UrlHelperService,
      useClass: CustomUrlHelperService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    labelPrintTemplateRendererFactory: TemplateRendererFactory<SuedoConfigurationExtraInterface>,
    injector: Injector,
    suedo3x8QrRenderer: Suedo3x8QrRenderer,
    suedo3x10QrRenderer: Suedo3x10QrRenderer,
    suedo3x11QrRenderer: Suedo3x11QrRenderer,
    suedo3x17QrRenderer: Suedo3x17QrRenderer,
  ) {
    InjectorClass.set(injector);
    labelPrintTemplateRendererFactory.register(suedo3x8QrRenderer);
    labelPrintTemplateRendererFactory.register(suedo3x10QrRenderer);
    labelPrintTemplateRendererFactory.register(suedo3x11QrRenderer);
    labelPrintTemplateRendererFactory.register(suedo3x17QrRenderer);
  }
}
