import { NgModule } from '@angular/core';
import { AddressSummaryPipe } from './address-summary/address-summary.pipe';
import { ArticleImagesPipe } from './article-images/article-images.pipe';
import { ArticleMainImagePipe } from './article-main-image/article-main-image.pipe';
import { ArticleManufacturerLogoPipe } from './article-manufacturer-logo/article-manufacturer-logo.pipe';
import { CategoryPhotoCollagePipe } from './category-photo-collage/category-photo-collage.pipe';
import { ContactListPipe } from './contact-list/contact-list.pipe';
import { CountryListPipe } from './country-list/country-list.pipe';
import { FacetOptionFilterPipe } from './facet-option-filter/facet-option-filter.pipe';
import { FilterListPipe } from './filter-list/filter-list.pipe';
import { GetErrorPipe } from './get-error/get-error.pipe';
import { HasErrorPipe } from './has-error/has-error.pipe';
import { LanguageHumanReadablePipe } from './language-human-readable/language-human-readable.pipe';
import { LanguageListPipe } from './language-list/language-list.pipe';
import { OrderByBooleanPipe } from './order-facet-option-by-boolean/order-by-boolean.pipe';
import { ProductFeaturesPipe } from './product-features/product-features.pipe';
import { ProductImagesPipe } from './product-images/product-images.pipe';
import { ProductMainImagePipe } from './product-main-image/product-main-image.pipe';
import { ProductManufacturerLogoPipe } from './product-manufacturer-logo/product-manufacturer-logo.pipe';
import { RoleHumanReadablePipe } from './role-human-readable/role-human-readable.pipe';
import { RoleListPipe } from './role-list/role-list.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { ShippingAddressListPipe } from './shipping-address-list/shipping-address-list.pipe';
import { ShippingTypeListPipe } from './shipping-type-list/shipping-type-list.pipe';
import { UrlFilterParamsPipe } from './url-filter-params/url-filter-params.pipe';
import { UrlPathPipe } from './url-path/url-path.pipe';
import { RouteActivePipe } from './route-active/route-active.pipe';
import { ArticleDocumentsPipe } from './article-documents/article-documents.pipe';
import { CartItemIsChildPipe } from './cart-item-is-child/cart-item-is-child.pipe';
import { CartItemIsMasterPipe } from './cart-item-is-master/cart-item-is-master.pipe';
import { GetValueFromKeyPipe } from './get-value-from-key/get-value-from-key.pipe';
import { ArticleBrandLinkPipe } from './article-brand-link/article-brand-link.pipe';
import { VideoListArticlePipe } from './video-list-article/video-list-article.pipe';
import { VideoListProductPipe } from './video-list-product/video-list-product.pipe';
import { CategoryFilterListPipe } from './category-filter-list/category-filter-list.pipe';
import { CartItemQuantitySumPipe } from './cart-item-quantity-sum/cart-item-quantity-sum.pipe';
import { RoundSwissFrancPipe } from './round-swiss-franc/round-swiss-franc.pipe';

const pipes: any[] = [
  GetErrorPipe,
  HasErrorPipe,
  AddressSummaryPipe,
  ShippingAddressListPipe,
  ShippingTypeListPipe,
  CountryListPipe,
  LanguageListPipe,
  RoleListPipe,
  ContactListPipe,
  LanguageHumanReadablePipe,
  RoleHumanReadablePipe,
  UrlPathPipe,
  UrlFilterParamsPipe,
  OrderByBooleanPipe,
  FacetOptionFilterPipe,
  ProductImagesPipe,
  ProductMainImagePipe,
  CategoryPhotoCollagePipe,
  ArticleMainImagePipe,
  ArticleImagesPipe,
  RoleListPipe,
  SafeUrlPipe,
  FilterListPipe,
  ProductManufacturerLogoPipe,
  ProductFeaturesPipe,
  ArticleManufacturerLogoPipe,
  RouteActivePipe,
  ArticleDocumentsPipe,
  CartItemIsChildPipe,
  CartItemIsMasterPipe,
  GetValueFromKeyPipe,
  ArticleBrandLinkPipe,
  VideoListArticlePipe,
  VideoListProductPipe,
  CategoryFilterListPipe,
  CartItemQuantitySumPipe,
  RoundSwissFrancPipe,
];

@NgModule({
  declarations: pipes,
  imports: [],
  exports: pipes,
})
export class PipesModule {}
