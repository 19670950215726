import { Pipe, PipeTransform } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { NavigationInterface } from 'src/app/components/header/header.component';

@Pipe({
  name: 'routeActive',
})
export class RouteActivePipe implements PipeTransform {
  constructor(private router: Router, private location: Location) {}
  public transform(navigation: NavigationInterface): Observable<boolean> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      shareReplay(1),
      startWith(undefined),
      map(() => this.location.path().startsWith(navigation.url)),
    );
  }
}
