import { Pipe, PipeTransform } from '@angular/core';
import { Ressource } from '@lobos/library';

@Pipe({
  name: 'videoListProduct',
})
export class VideoListProductPipe implements PipeTransform {
  public transform(videoItems: Ressource[]): string[] {
    return videoItems.map((item: Ressource) => item.sResourceName);
  }
}
