import { Component, Inject, Input } from '@angular/core';
import { NavigationInterface } from '../header.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent {
  @Input() navigationMenu: NavigationInterface[] | undefined | null;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  closeDropdown() {
    this.document.querySelector('.js-subnav.block')?.classList.add('hidden');
    this.document.querySelector('.js-subnav.block')?.classList.remove('block');
  }
}
