import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent {
  @Input() public label: string = '';

  @Input() public name: string = '';

  @Input() public placeholder: string = '';

  @Input() public form: UntypedFormGroup | undefined;

  @Input() public required: boolean = false;

  @Input() public hasError: boolean = false;

  @Input() public errorText: string = '';

  @Input() public rows: number = 4;

  @Input() public maxLength: number = 8192;
}
