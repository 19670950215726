import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Product } from '@lobos/library';
import { DomHelperService } from '../../services/dom/dom-helper.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements AfterViewInit {
  @Input() product!: Product;

  private currentBoxHeight: number = 0;

  @HostListener('mouseenter')
  onMouseEnter = () => {
    const element = this.elementRef.nativeElement;
    const box = element.querySelector('.js-box');
    const cartEl = box.querySelector('.js-box-hover');

    if (parseInt(box.getAttribute('data-minheight')!) == this.currentBoxHeight && window.innerWidth > 767) {
      const getAdditionalHeight = parseInt(box.getAttribute('data-hover-height')!);
      const jsBoxHeightWithCurrent = box.querySelector('.js-box-height') as HTMLElement | null;
      jsBoxHeightWithCurrent!.style.height = this.currentBoxHeight + getAdditionalHeight + 'px';
      box.classList.remove('z-10');
      box.classList.add('z-40');
      cartEl.classList.remove('md:hidden');
    }
  };

  @HostListener('mouseleave')
  onMouseLeave = () => {
    const element = this.elementRef.nativeElement;
    const box = element.querySelector('.js-box');
    const cartEl = box.querySelector('.js-box-hover');

    if (parseInt(box.getAttribute('data-minheight')!) == this.currentBoxHeight && window.innerWidth > 767) {
      const jsBoxHeight = box.querySelector('.js-box-height') as HTMLElement | null;
      jsBoxHeight!.style.height = '';
      box.classList.add('z-10');
      box.classList.remove('z-40');
      cartEl.classList.add('md:hidden');
    }
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.showCartBtnOnHover();
    }
  }

  showCartBtnOnHover() {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      const box = element.querySelector('.js-box');
      const innerBoxEl = box.querySelector('.js-link');
      const cartEl = box.querySelector('.js-box-hover');
      this.currentBoxHeight = parseInt(getComputedStyle(innerBoxEl).height) + 4;

      if (DomHelperService.is_touch_enabled()) {
        if (typeof window !== 'undefined' && window.innerWidth > 767) {
          cartEl.classList.remove('md:hidden');
          box.style.minHeight = '';
          box.querySelector('.js-box-height')!.classList.remove('md:absolute');
          box.querySelector('.js-box-height')!.classList.remove('md:block');
          box.classList.remove('min-h-0');
          box.classList.remove('md:min-h-350px');
        }
      } else {
        DomHelperService.checkBoxMinHeight(box, this.currentBoxHeight);
      }
    }, 0);
  }
}
