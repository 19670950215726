import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  Article,
  BlogPost,
  CartHeader,
  CartItem,
  CartService,
  Category,
  CmsUiDynamicContentPage,
  CmsUiDynamicContentQuery,
  CmsUiDynamicContentService,
  CreateCartItemInterface,
  createLocalSearchString,
  FocusableDirective,
  LocalSearchString,
  LocalSearchStringQuery,
  LocalSearchStringService,
  SearchQuery,
  SearchService,
  switchTap,
  UrlHelperService,
} from '@lobos/library';
import { debounceTime, filter, first, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  searchControl: UntypedFormControl = new UntypedFormControl('');
  articles$: Observable<Article[]> = this.searchQuery.articles$;
  categories$: Observable<Category[]> = this.searchQuery.categories$;
  cmsPages$: Observable<CmsUiDynamicContentPage[]> = this.cmsQuery.pageSearch$;
  cmsPosts$: Observable<BlogPost[]> = this.cmsQuery.blogSearch$;
  searchTermHistory$: Observable<LocalSearchString[]> = this.localSearchStringQuery.selectAll({ limitTo: 5 });
  showResults: boolean = false;
  hasFocus: boolean = false;
  @Output() searchItemClicked: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef | undefined;
  @ViewChild(FocusableDirective)
  private focusList: FocusableDirective | undefined;

  constructor(
    private searchService: SearchService,
    private cmsService: CmsUiDynamicContentService,
    private cmsQuery: CmsUiDynamicContentQuery,
    private searchQuery: SearchQuery,
    private localSearchStringQuery: LocalSearchStringQuery<LocalSearchString>,
    private localSearchStringService: LocalSearchStringService<LocalSearchString>,
    private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
    private router: Router,
    private urlHelper: UrlHelperService,
  ) {}

  @HostListener('click')
  clickedInside() {
    this.hasFocus = true;
    this.showResults = true;
  }

  @HostListener('document:click')
  clickOut() {
    if (!this.hasFocus) {
      this.showResults = false;
    }
    this.hasFocus = false;
  }

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        switchTap((searchString: string) =>
          iif(
            () => searchString.length > 0,
            combineLatest([
              this.searchService.search(searchString),
              // this.cmsService.searchBlogPosts(searchString),
              // this.cmsService.searchCMSPages(searchString),
            ]),
            of(undefined).pipe(
              tap(() => {
                this.searchService.resetStore();
                // this.cmsService.resetSearchStore();
              }),
            ),
          ),
        ),
        filter((searchTerm: string) => searchTerm.length > 3),
        debounceTime(300),
        untilDestroyed(this),
      )
      .subscribe();
  }

  updateLocalSearchHistory(searchString: string): void {
    if (
      this.localSearchStringQuery.getAll({
        filterBy: ({ searchTerm }) => searchTerm === searchString,
      }).length === 0
    ) {
      this.localSearchStringService.add(createLocalSearchString({ searchTerm: searchString }));
    }
  }

  addArticleToCart({ article, quantity }: { article: Article; quantity: number }) {
    this.cartService
      .createCartItem({
        decQuantity: quantity,
        sArticleID: article.sArticleID as string,
        sArticleName: article.sName,
        sQuantityUnit: article.sQuantityUnitSales,
        oArticle: article,
      })
      .pipe(first(), untilDestroyed(this))
      .subscribe();
  }

  openSearchDropdown() {
    this.hasFocus = true;
    this.showResults = true;
  }

  focusOut() {
    this.hasFocus = false;
  }

  articleRouterLinkClicked(event: Event) {
    this.searchItemClicked.emit();
    this.closeSearchDropdown(event);
  }

  focusEnterClick(event: Event, article: Article) {
    this.focusOut();
    this.closeSearchDropdown(event);
    this.router.navigate([this.urlHelper.localizeUrl('article'), article.sArticleID]);
  }

  closeSearchDropdown(event: Event) {
    event.stopPropagation();
    if (!this.hasFocus) {
      this.showResults = false;
    }
  }

  searchByHistorySearchTerm(_: any, searchTerm: string) {
    this.searchControl.patchValue(searchTerm);
    //this.searchService.search(searchTerm).pipe(take(1), untilDestroyed(this)).subscribe();
  }

  trackByArticleId(_: number, item: Article) {
    return item.sArticleID;
  }

  searchForSearchTerm(event: Event) {
    this.router.navigate([this.urlHelper.localizeUrl('search'), this.searchControl.value]);
    this.closeSearchDropdown(event);
  }

  focusEvent(event: KeyboardEvent) {
    if (['arrowdown'].includes(event.key.toLowerCase())) {
      this.focusList?.focus();
    }
    if (['enter'].includes(event.key.toLowerCase())) {
      this.searchForSearchTerm(event);
    }
  }
}
