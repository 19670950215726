import { Pipe, PipeTransform } from '@angular/core';
import { FacetOption } from '@lobos/library';

@Pipe({
  name: 'orderOptionsByDisabled',
})
export class OrderByBooleanPipe implements PipeTransform {
  public transform(options: FacetOption[]): FacetOption[] {
    const sortedOptions = [...options];
    return sortedOptions.sort((x, y) => Number(x.disabled) - Number(y.disabled));
  }
}
