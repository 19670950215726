import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, startWith } from 'rxjs/operators';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb, BreadcrumbsService } from '@lobos/library';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input()
  public isBackOptionShown: boolean = false;

  public crumbs$: Observable<Breadcrumb[]> = this.breadcrumbsService.breadcrumbs$;

  public translate$: Observable<boolean> = this.router.events.pipe(
    startWith('Test'),
    filter((ev) => ev instanceof NavigationEnd),
    map(() => {
      let route = this.route;

      while (route.firstChild) {
        route = route.firstChild;
      }

      return route;
    }),
    filter((route: ActivatedRoute) => route.outlet === 'primary'),
    mergeMap((route: ActivatedRoute) => route.data),
    map((data: Data) => data['title']),
    map((data: any) => !!data.translate),
  );

  public backCrumb$: Observable<Breadcrumb> = this.crumbs$.pipe(
    filter((crumbs: Breadcrumb[]) => crumbs.length >= 2),
    map((crumbs: Breadcrumb[]) => crumbs[crumbs.length - 2]),
  );

  constructor(private breadcrumbsService: BreadcrumbsService, private route: ActivatedRoute, private router: Router) {}
}
