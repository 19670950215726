import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthService, CartHeader, CartService, SteelCartItem, SteelCreateCartItemInterface, User } from '@lobos/library';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartHelperService {
  constructor(
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
    private authService: AuthService,
  ) {}

  public initializeCarts(): Observable<CartHeader[]> {
    return this.authService.authUser$.pipe(
      debounceTime(50), // prevent double trigger
      tap(() => this.cartService.clearCartHeader()),
      switchMap((user: User | undefined) => this.cartService.setActiveId(user)),
      filter((id: number | ID) => !!id),
      switchMap((id: number | ID) => this.cartService.getCartById(id).pipe(catchError(() => of(undefined)))),
      switchMap(() => this.cartService.getCartHeaders()),
    );
  }
}
