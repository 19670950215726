import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '@lobos/library';

@Pipe({
  name: 'countryList',
})
export class CountryListPipe implements PipeTransform {
  public transform(countries: Country[] | null): { key: string; value: string }[] {
    const normalizedCountries: { key: string; value: string }[] = [];
    if (!countries?.length) {
      return normalizedCountries;
    }

    for (const country of countries) {
      normalizedCountries.push({
        key: country.sCountryCode,
        value: country.sName,
      });
    }

    return normalizedCountries;
  }
}
