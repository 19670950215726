<!-- start search -->
<ng-container *transloco="let t; scope: 'core'">
  <div (mouseleave)="closeSearchDropdown($event)">
    <form novalidate="" class="h-10 md:h-46px flex justify-between rounded-md shadow-sm bg-gray-200" (submit)="searchForSearchTerm($event)">
      <input
        #searchInput
        type="text"
        [formControl]="searchControl"
        (focus)="openSearchDropdown()"
        (focusout)="focusOut()"
        (keydown)="focusEvent($any($event))"
        name="q"
        autocomplete="off"
        [placeholder]="t('core.header.search.placeholder')"
        class="js-header-input w-full text-sm placeholder:text-gray-700 border-0 px-4 xl:px-5 py-1 md:py-2 h-full bg-transparent rounded-md js-header-search-input focus:ring-1 focus:ring-primary focus:border-primary"
      />
      <button [disabled]="searchInput.value.length === 0" type="submit" class="js-header-search-submit w-12 md:w-62px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 mx-auto pointer-events-none"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
    </form>
    <div
      [class.hidden]="!showResults"
      class="relative md:absolute z-70 top-0 md:top-full lg:left-1/2 xl:left-0 lg:-translate-x-1/2 xl:translate-x-0 w-full lg:w-640px xl:w-full max-h-500px md:max-h-50vh overflow-auto shadow js-header-search-wrap"
    >
      <div class="mt-2 shadow bg-white w-full rounded-md" [focusList]="{ focusFirst: true, withTypeAhead: true }">
        <div *ngIf="searchTermHistory$ | async as searchTermHistory" class="py-5 pr-6 pl-3">
          <div *ngIf="searchTermHistory.length > 0" class="text-base font-bold text-secondary">
            {{ t("core.header.search.last-searches") }}
          </div>
          <div class="flex flex-col">
            <a
              focusable
              *ngFor="let term of searchTermHistory"
              (click)="closeSearchDropdown($event)"
              (keyup.enter)="searchByHistorySearchTerm($event, term.searchTerm)"
              [routerLink]="['search' | localizeUrlPathAsync | async, term.searchTerm]"
              class="text-black hover:text-primary focus:text-primary focus:outline-primary cursor-pointer"
              >{{ term.searchTerm }}</a
            >
          </div>
        </div>
        <ng-container *ngIf="articles$ | async as articles">
          <div *ngIf="articles.length; else noArticles" class="py-5 pr-6 pl-3 border-t border-black js-header-search-results">
            <div class="font-bold text-secondary">{{ t("core.header.search.articles") }}</div>
            <div class="flex flex-col">
              <app-search-item
                focusable
                *ngFor="let article of articles; trackBy: trackByArticleId"
                [article]="article"
                (buyItemClicked)="addArticleToCart($event)"
                (linkClicked)="articleRouterLinkClicked($event)"
                (keyup.enter)="focusEnterClick($event, article)"
                class="focus:text-primary focus:outline-primary"
              ></app-search-item>
            </div>
          </div>

          <ng-template #noArticles>
            <ng-container *ngIf="searchInput.value.length > 0" class="font-bold text-secondary">
              <div class="py-5 pr-6 pl-3">{{ t("core.header.search.no-articles") }}</div>
            </ng-container>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<!-- end search -->
