import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthService, CartHeader, CartHeaderQuery, CartItem, CartItemQuery, CartService, CreateCartItemInterface } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalService } from 'ngx-simple-modal';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AddEditCartModalComponent } from '../../../../features/profile/profile-features/carts/carts-components/add-edit-cart-modal/add-edit-cart-modal.component';
import { ModalConfirmComponent } from '../../../modal-confirm/modal-confirm.component';
import { isPlatformBrowser } from '@angular/common';
import { SuedoUser } from '../../../../interfaces';

@UntilDestroy()
@Component({
  selector: 'app-cart-flyout',
  templateUrl: './cart-flyout.component.html',
  styleUrls: ['./cart-flyout.component.scss'],
})
export class CartFlyoutComponent implements OnInit {
  @Input()
  public classes: string = '';
  public mobile: boolean = false;

  @Output() closeMenu: EventEmitter<void> = new EventEmitter();

  public carts$: Observable<{ key: string; value: string }[]> = this.cartHeaderQuery.selectAll().pipe(
    map((carts: CartHeader[]) =>
      carts.map((cart: CartHeader) => ({
        key: cart.lngOrderID.toString(),
        value: cart.sCartName,
      })),
    ),
  );
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  public authUser$: Observable<SuedoUser | undefined> = this.authService.authUser$;

  public activeCart$: Observable<CartHeader | undefined> = this.cartHeaderQuery.selectActive();
  public activeCartItems$: Observable<CartItem[]> = this.cartHeaderQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.cartItemQuery.selectCartItems(id!)),
  );

  constructor(
    private cartHeaderQuery: CartHeaderQuery<CartHeader>,
    private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
    private cartItemQuery: CartItemQuery<CartItem>,
    private authService: AuthService<SuedoUser>,
    private translocoService: TranslocoService,
    private modal: SimpleModalService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && window.innerWidth < 1279) {
      this.mobile = true;
    }
  }

  addCart(): void {
    this.modal.addModal(AddEditCartModalComponent);
  }

  changeCart(id: string | number): void {
    this.cartService.changeActiveCart(id).pipe(first(), untilDestroyed(this)).subscribe();
  }

  removeItem(item: CartItem) {
    combineLatest([
      this.translocoService.selectTranslate('core.header.flyout.cart.modal-confirm.title'),
      this.translocoService.selectTranslate('core.header.flyout.cart.modal-confirm.message'),
    ])
      .pipe(
        switchMap(([title, message]) =>
          this.modal.addModal(ModalConfirmComponent, {
            title,
            message,
          }),
        ),
        filter((result: boolean) => result),
        switchMap(() => this.cartService.deleteCartItem(item)),
        first(),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
