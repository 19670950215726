import { Component } from '@angular/core';
import { BreadcrumbsService } from '@lobos/library';

/**
 * This component has the sole purpose,
 * to initialize the breadcrumb.
 *
 * If the breadcrumb would only be initialized on the template
 * level, it might be too late for listening to the route.
 */
@Component({
  selector: 'app-breadcrumb-outlet',
  template: '',
})
export class BreadcrumbOutletComponent {
  constructor(private _: BreadcrumbsService) {}
}
