import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { AuthService, FavoriteList, FavoriteService, SteelFavoriteItemModel, User } from '@lobos/library';
import { iif, Observable, of } from 'rxjs';
import { filter, tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FavoriteHelperService {
  constructor(private favoriteService: FavoriteService<FavoriteList, SteelFavoriteItemModel>, private authService: AuthService) {}

  public initializeFavorites(favoriteId?: ID): Observable<FavoriteList[]> {
    return of(favoriteId).pipe(
      switchMap((id: ID | undefined) =>
        iif(
          () => !id,
          // true
          this.authService.authUser$.pipe(
            tap(() => this.favoriteService.clearFavoriteLists()),
            switchMap((user: User | undefined) => this.favoriteService.setActiveFavorite(user)),
          ),
          // false
          of(favoriteId),
        ),
      ),
      filter((id: ID | undefined) => !!id),
      switchMap((id: ID | undefined) => this.favoriteService.getFavoriteItems(id!)),
      switchMap(() => this.favoriteService.getFavoriteLists()),
    );
  }
}
