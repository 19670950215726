<ng-container *transloco="let t; scope: 'core'">
  <div class="js-show-info md:relative -mr-4" [ngClass]="classes">
    <a
      [routerLink]="(isLoggedIn$ | async) ? ['profile/my-profile' | localizeUrlPath] : null"
      class="text-xl hover:text-primary flex items-center relative"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
      <span
        *ngIf="isLoggedIn$ | async"
        class="font-bold text-xs absolute -bottom-4 whitespace-nowrap left-1/2 -translate-x-1/2 truncate w-32"
        ><ng-container *ngIf="user$ | async as user"> {{ user.sFirstName }} {{ user.sLastName }}</ng-container></span
      >
      <span class="sr-only">{{ t("core.header.menu.my-profile") }}</span>
    </a>
    <div class="js-info md:-mr-40 absolute translate-y-0 md:top-full -right-3 z-30 hidden">
      <div class="mt-4 shadow rounded-md overflow-hidden">
        <!-- @lobos if logged out show this block -->
        <div *ngIf="(isLoggedIn$ | async) === false; else isAuthenticated" class="w-83vw sm:w-368px p-4 bg-white">
          <div class="flex flex-col mobile-xl:flex-row mobile-xl:justify-end space-y-4 mobile-xl:space-y-0 mobile-xl:space-x-4">
            <div class="mobile-xl:-mt-2">
              <button
                (click)="openLoginModal()"
                type="button"
                class="js-show-login self-start mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent rounded shadow-sm bg-primary hover:bg-secondary focus:bg-secondary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="ml-1 mr-4 text-white h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <span class="text-base font-bold text-white mr-3">{{ t("core.header.menu.login") }}</span>
              </button>
            </div>
            <a
              [routerLink]="'/registration' | localizeUrlPathAsync | async"
              class="self-start mt-2 inline-flex items-center px-2.5 py-1.5 rounded shadow-sm group bg-white border border-primary hover:border-secondary focus:border-secondary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="ml-1 mr-4 h-5 w-5 text-primary group-focus:text-secondary group-hover:text-secondary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <span class="text-base font-bold mr-3 text-primary group-focus:text-secondary group-hover:text-secondary">{{
                t("core.header.menu.signup")
              }}</span>
            </a>
          </div>
        </div>
        <!-- @lobos if logged in show this block -->
        <ng-template #isAuthenticated>
          <div class="w-83vw sm:w-390px md:w-470px p-4 bg-white">
            <div class="sm:ml-3 flex items-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-3 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              <p class="font-bold text-lg" *ngIf="user$ | async as user">
                {{
                  t("core.header.menu.profile.title", {
                    firstName: user.sFirstName,
                    lastName: user.sLastName,
                    customerId: user.lngCustomerID
                  })
                }}
              </p>
            </div>
            <ul>
              <li>
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/my-profile' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.my-profile')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.my-profile") }}
                </a>
              </li>
              <li>
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/carts' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.carts')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.carts") }}
                </a>
              </li>
              <li>
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/favorites' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.favorites')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.favorites") }}
                </a>
              </li>
              <li>
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/addresses' | localizeUrlPathAsync | async"
                  [title]="t('header.menu.profile.shipping-addresses')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  {{ t("core.header.menu.profile.shipping-addresses") }}
                </a>
              </li>

              <li *ngIf="accessId.SHOW_OFFERS | hasAccess | async">
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/offers' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.offers')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.offers") }}
                </a>
              </li>

              <li *ngIf="accessId.SHOW_ORDERS | hasAccess | async">
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/orders' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.orders')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                  </svg>
                  {{ t("core.header.menu.profile.orders") }}
                </a>
              </li>
              <li *ngIf="accessId.SHOW_CREDIT_NOTES | hasAccess | async">
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/credits' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.credit-notes')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.credit-notes") }}
                </a>
              </li>
              <li *ngIf="accessId.SHOW_BACKORDER | hasAccess | async">
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/backorders' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.backlog')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.backlog") }}
                </a>
              </li>
              <li *ngIf="accessId.SHOW_USER_MANAGEMENT | hasAccess | async">
                <a
                  (click)="userItemClicked.emit()"
                  [routerLink]="'/profile/users' | localizeUrlPathAsync | async"
                  [title]="t('core.header.menu.profile.users')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.users") }}
                </a>
              </li>
              <ng-container *ngIf="user$ | async as user">
                <li
                  *ngIf="user.lngP48SalesRepID || user.sP48SalesRepForUser"
                  [routerLinkActive]="['bg-secondary']"
                  [title]="t('core.header.menu.profile.sales-rep')"
                  class="group hover:bg-primary rounded-md"
                >
                  <a
                    (click)="userItemClicked.emit()"
                    [routerLink]="'/sales-rep' | localizeUrlPathAsync | async"
                    [routerLinkActive]="['text-white']"
                    class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-3 flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                    {{ t("core.header.menu.profile.sales-rep") }}
                  </a>
                </li>
              </ng-container>
              <li>
                <a
                  (click)="logout()"
                  [title]="t('core.header.menu.profile.logout')"
                  class="flex items-center sm:px-4 py-2 rounded-md hover:bg-primary hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 flex-shrink-0 h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  {{ t("core.header.menu.profile.logout") }}
                </a>
              </li>
            </ul>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
