import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AuthService,
  CartHeader,
  CartItem,
  CartItemQuery,
  CartService,
  CreateCartItemInterface,
  FavoriteItem,
  FavoriteList,
  FavoriteService,
  User,
} from '@lobos/library';
import { lastValueFrom, Observable } from 'rxjs';
import { SuedoFavoriteItem } from '../../../../../interfaces/suedo-favorite-item.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[app-favorites-flyout-item]',
  templateUrl: './favorite-item.component.html',
  styleUrls: ['./favorite-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteItemComponent {
  @Input() favoriteItem!: SuedoFavoriteItem;

  priceIsZero: boolean = false;

  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  authUser$: Observable<User | undefined> = this.authService.authUser$;

  constructor(
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
    private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
    private cartItemQuery: CartItemQuery<CartItem>,
    private authService: AuthService,
  ) {}

  async deleteFavoriteItem(favoriteItem: FavoriteItem) {
    await lastValueFrom(this.favoriteService.deleteFavoriteItem(favoriteItem));
  }

  async onQuantityChange(favoriteItem: FavoriteItem, quantity: number) {
    await lastValueFrom(this.favoriteService.updateFavoriteItem({ ...favoriteItem, decQuantity: quantity }));
  }

  isLoading$: Observable<boolean> = this.cartItemQuery.selectLoading();

  async onAddArticleToCartClick(favoriteItem: FavoriteItem) {
    if (favoriteItem.decQuantity > 0) {
      await lastValueFrom(
        this.cartService.createCartItem({
          decQuantity: favoriteItem.decQuantity,
          sArticleID: favoriteItem.oArticle!.sArticleID as string,
          sArticleName: favoriteItem.oArticle!.sName,
          sQuantityUnit: favoriteItem.oArticle!.sQuantityUnitSales,
          oArticle: favoriteItem.oArticle!,
        }),
      );
    }
  }
}
