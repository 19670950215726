import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { SuedoArticle } from '../../../services/catalog/model/suedo-article';

@Component({
  selector: 'app-qty-modal-input',
  templateUrl: './qty-modal-input.component.html',
  styleUrls: ['./qty-modal-input.component.scss'],
})
export class QtyModalInputComponent extends SimpleModalComponent<
  { quantity: number; article: SuedoArticle | undefined },
  number | undefined
> {
  article!: SuedoArticle;
  quantity!: number;
  decQuantityDown: number = 0;
  decQuantityUp: number = 0;
  decQuantityDefault: number = 1;

  constructor() {
    super();
  }
  override mapDataObject(data: { quantity: number; article: SuedoArticle | undefined }) {
    super.mapDataObject(data);
    this.article = data.article!;
    this.quantity = data.quantity;
    this.setData();
  }

  setData() {
    if (this.article.decQuantityPackage <= 0) this.article.decQuantityPackage = 1;

    // rounding down
    //	this.decQuantityDown = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
    this.decQuantityDown = Math.floor(this.quantity / this.article.decQuantityPackage);
    this.decQuantityDown = this.decQuantityDown * this.article.decQuantityPackage;

    // rounding up
    //	this.decQuantityUp = this.decQuantity / this._oCartItem.oArticle.decQuantityPackage;
    this.decQuantityUp = Math.ceil(this.quantity / this.article.decQuantityPackage);
    this.decQuantityUp = this.decQuantityUp * this.article.decQuantityPackage;

    this.decQuantityDefault = this.article.decQuantityPackage > 0 ? this.article.decQuantityPackage : 1;
  }

  roundUp() {
    this.result = this.quantity ? this.decQuantityUp : this.decQuantityDefault;
    this.close();
  }

  roundDown() {
    this.result = this.quantity ? this.decQuantityDown : this.decQuantityDefault;
    this.close();
  }

  cancel() {
    this.result = undefined;
    this.close();
  }
}
