import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Article, AuthService, StockAvailability, StockAvailabilityQuery, StockAvailabilityService } from '@lobos/library';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-article-availability',
  templateUrl: './article-availability.component.html',
  styleUrls: ['./article-availability.component.scss'],
})
export class ArticleAvailabilityComponent implements OnInit {
  @Input() font: string = 'font-bold';
  @Input() showLabel: boolean = true;
  @Input() quantity: number = 0;
  @Input() showPopoverTop: boolean = true;
  private _article: Article | undefined;
  @Input() set article(value: Article | undefined) {
    this._article = value;
    this.articleChanged$.next(value);
  }

  get article(): Article | undefined {
    return this._article;
  }

  public authUser$ = this.authService.authUser$;

  private articleChanged$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);

  public stockAvailability$: Observable<StockAvailability | undefined> | undefined;

  constructor(
    protected stockAvailabilityService: StockAvailabilityService,
    protected stockAvailabilityQuery: StockAvailabilityQuery,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private _platformId: any,
  ) {}

  ngOnInit() {
    this.stockAvailability$ = this.articleChanged$.pipe(
      switchMap(() =>
        iif(
          () => this.stockAvailabilityQuery.hasEntity(this.article?.sArticleID),
          this.stockAvailabilityQuery.selectEntity(this.article?.sArticleID),
          this.getAvailability(this.article!.sArticleID),
        ),
      ),
    );
  }

  getAvailability(articleId: ID): Observable<StockAvailability | undefined> {
    if (isPlatformServer(this._platformId) || !articleId) {
      return of(undefined);
    }

    return this.stockAvailabilityService
      .get(articleId)
      .pipe(switchMap(() => this.stockAvailabilityQuery.selectEntity(this.article?.sArticleID)));
  }
}
