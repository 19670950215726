import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Article, AuthService, CartItem, CartItemQuery, User } from '@lobos/library';
import { Observable } from 'rxjs';
import { SuedoArticle } from '../../../services/catalog/model/suedo-article';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchItemComponent {
  quantity: number = 1;
  @Input() article!: SuedoArticle;
  @Output() linkClicked: EventEmitter<Event> = new EventEmitter();
  @Output() buyItemClicked: EventEmitter<{ article: Article; quantity: number }> = new EventEmitter();

  priceIsZero: boolean = false;

  isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;
  authUser$: Observable<User | undefined> = this.authService.authUser$;

  constructor(private authService: AuthService, private cartItemQuery: CartItemQuery<CartItem>) {}

  setPriceIsZero(value: boolean): void {
    setTimeout(() => (this.priceIsZero = value), 300);
  }

  articleRouterLinkClicked(event: Event) {
    this.linkClicked.emit(event);
  }

  isLoading$: Observable<boolean> = this.cartItemQuery.selectLoading();

  addArticleToCart(article: Article) {
    this.buyItemClicked.emit({ article, quantity: this.quantity });
  }

  quantityChanged(quantity: number) {
    this.quantity = quantity;
  }
}
