<!-- start modal -->
<ng-container *transloco="let t; scope: 'cart'">
  <div class="js-login-modal fixed z-60 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="block absolute top-0 right-0 pt-4 pr-4 z-10">
          <button
            (click)="close()"
            type="button"
            class="js-close-modal bg-white p-2 inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary rounded"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:text-left">
            <h3 class="text-lg leading-6 font-bold" id="modal-title">{{ t("cart.error-modal.title") }}</h3>
            <div class="mt-2">
              <p class="text-sm">{{ t("cart.error-modal.text") }}</p>
            </div>
            <div class="text-sm border-b border-white pb-4 md:pb-0 md:border-b-0 mt-6">
              <p>
                <a class="hover:text-primary" href="tel:044 439 90 50">{{
                  environment.cms.error.includes("jasa") ? t("cart.error-modal.tel-jasa") : t("cart.error-modal.tel-suedo")
                }}</a>
              </p>
              <p>
                <a class="hover:text-primary" href="mailto:info@suedo.ch">{{
                  environment.cms.error.includes("jasa") ? t("cart.error-modal.mail-jasa") : t("cart.error-modal.mail-suedo")
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- end modal -->
