<div class="mt-4">
  <label [for]="name" class="block text-sm font-bold" [class.text-error]="hasError">{{ label }}</label>
  <div class="mt-1" *ngIf="form" [formGroup]="form">
    <textarea
      [placeholder]="placeholder"
      [required]="required"
      [formControlName]="name"
      [class.border-error]="hasError"
      [name]="name"
      [rows]="rows"
      [id]="name"
      [maxLength]="maxLength"
      class="rounded shadow-sm block w-full sm:text-sm focus:ring-1 focus:ring-primary focus:border-primary"
    ></textarea>
  </div>
</div>
<p *ngIf="hasError && errorText" class="font-primary text-sm text-error my-2">{{ errorText }}</p>
