import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbHelperService {
  previousUrl: string | undefined;

  setPreviousUrl(url: string): void {
    let previousUrl = this.removeLocalizationFromUrl(url);
    previousUrl = this.removeQueryParamsFromUrl(previousUrl);
    this.previousUrl = previousUrl;
  }

  removeLocalizationFromUrl(url: string): string {
    return url.replace(/\/[a-z]{2}\//, '/');
  }

  removeQueryParamsFromUrl(url: string): string {
    return url.split('?')[0];
  }
}
