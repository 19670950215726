import { Injectable } from '@angular/core';
import { Template } from '@lobos/label-print';
import { Suedo3x8QrRenderer } from './suedo3x8_qr.renderer';

@Injectable({
  providedIn: 'root',
})
export class Suedo3x10QrRenderer extends Suedo3x8QrRenderer {
  public override template(): Template {
    return {
      id: 'suedo3x10',
      title: 'profile.label-print.domain.template.suedo3x10_qr.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 29.7 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 10 },
    };
  }
}
