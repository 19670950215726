<div class="relative flex items-center my-1 @@class">
  <div *ngIf="form" class="flex items-center h-5" [formGroup]="form">
    <input
      [id]="name"
      [name]="name"
      type="checkbox"
      [formControlName]="name"
      [checked]="value"
      (click)="changeValue()"
      (change)="checkboxChanged.emit(value)"
      [class.border-error]="hasError"
      [disabled]="disabled"
      [class.opacity-40]="disabled"
      class="shadow-sm h-4 w-4 text-primary border-gray-800 rounded focus:ring-1 focus:ring-primary focus:border-primary"
    />
  </div>
  <div *ngIf="!form" class="flex items-center h-5">
    <input
      [id]="name"
      [name]="name"
      type="checkbox"
      [checked]="value"
      (change)="checkboxChanged.emit(!value)"
      [class.border-error]="hasError"
      [disabled]="disabled"
      [class.opacity-40]="disabled"
      class="shadow-sm h-4 w-4 text-primary border-gray-800 rounded focus:ring-1 focus:ring-primary focus:border-primary"
    />
  </div>
  <div class="ml-3">
    <label [attr.for]="name ? name : undefined" class="text-sm" [class.text-error]="hasError" [class.opacity-40]="disabled">
      {{ label }}</label
    >
  </div>
</div>
