import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { BreakpointService, Catalog, CatalogQuery, Parameter, ParameterQuery } from '@lobos/library';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { CatalogHelperService } from '../../services/catalog/catalog-helper.service';
import { environment } from '../../../environments/environment';

export interface NavigationInterface {
  title: string;
  url: string;
  specialClass?: string;

  isOpen?: boolean;

  subNavigation?: NavigationInterface[];
}

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isMobile$ = this.breakpointService.mobile;

  mobileMenuOpen = false;
  catalogMenu$: Observable<NavigationInterface[]> = combineLatest([this.parameterQuery.params$, this.catalogQuery.selectAll()]).pipe(
    filter(([params, catalog]: [Parameter, Catalog[]]) => !!params && !!catalog.length),
    map(([params, catalog]: [Parameter, Catalog[]]) => this.catalogHelperService.buildNavigationFromAll(params, catalog)),
    shareReplay(1),
  );

  public logoPath: string = environment.logoPath;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private parameterQuery: ParameterQuery,
    private catalogQuery: CatalogQuery,
    private breakpointService: BreakpointService,
    private catalogHelperService: CatalogHelperService,
  ) {}

  private showMobileNav(): void {
    this.document.querySelector('body')!.classList.add('h-screen');
    this.document.querySelector('body')!.classList.add('overflow-hidden');
    this.document.querySelector('.js-mobile-nav')!.classList.add('translate-x-0');
    this.document.querySelector('.js-mobile-nav')!.classList.remove('translate-x-full');
  }

  private hideMobileNav(): void {
    this.document.querySelector('body')!.classList.remove('h-screen');
    this.document.querySelector('body')!.classList.remove('overflow-hidden');
    this.document.querySelector('.js-mobile-nav')!.classList.remove('translate-x-0');
    this.document.querySelector('.js-mobile-nav')!.classList.add('translate-x-full');
  }

  toggleMobileMenu() {
    if (this.mobileMenuOpen) {
      this.hideMobileNav();
      this.mobileMenuOpen = false;
    } else {
      this.showMobileNav();
      this.mobileMenuOpen = true;
    }
  }
}
