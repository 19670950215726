<ng-container *ngIf="{ catalogMenu: catalogMenu$ | async, isMobile: isMobile$ | async } as context">
  <header class="sticky top-0 z-55 bg-white shadow-sm">
    <app-topbar-navigation></app-topbar-navigation>
    <nav class="js-close-subnav max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Service">
      <div class="w-full py-3 lg:py-4 flex items-center justify-between border-b border-black-400 lg:border-none">
        <div class="flex items-center w-full justify-end lg:justify-between">
          <a [routerLink]="['/' | localizeUrlPathAsync | async]" class="mr-auto lg:mr-0 cursor-pointer">
            <img class="h-auto sm:h-10 w-10/12 sm:w-auto" [src]="logoPath" alt="" />
          </a>
          <div class="hidden flex-1 lg:flex justify-center px-2 mx-10">
            <app-search class="relative z-30 w-full h-full md:h-46px js-header-search"></app-search>
          </div>
          <app-service-navigation
            class="mr-4 md:mr-0 sm:space-x-10 md:space-x-12 ml-auto sm:-ml-2.5 xl:ml-0 flex"
            [cssClass1]="'hidden sm:block'"
            [cssClass2]="'hidden sm:block'"
            [cssClass3]="'hidden sm:block'"
          ></app-service-navigation>

          <div class="ml-0 md:ml-8 -mr-2 -my-2 xl:hidden">
            <button
              (click)="toggleMobileMenu()"
              type="button"
              class="js-open-mobile-nav bg-white p-2 inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary"
              aria-expanded="false"
            >
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>

    <!-- start main navigation -->
    <div class="relative z-20 bg-primary hidden xl:block">
      <div class="max-w-7xl mx-auto flex justify-between items-center px-4 py-0 sm:px-6 sm:py-3 lg:px-8 xl:justify-start xl:space-x-10">
        <div class="xl:flex-1 xl:flex xl:items-center xl:justify-between">
          <app-main-navigation
            *ngIf="context.catalogMenu && !context.isMobile"
            [navigationMenu]="context.catalogMenu"
          ></app-main-navigation>
        </div>
      </div>
    </div>
    <!-- end main navigation -->
  </header>
  <app-mobile-navigation
    (closeMobileMenu)="toggleMobileMenu()"
    *ngIf="context.catalogMenu && context.isMobile"
    [navigationMenu]="context.catalogMenu"
  ></app-mobile-navigation>
</ng-container>
