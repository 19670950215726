import { Injectable } from '@angular/core';
import { Label, LabelData, PaddingBoxInterface, RendererAbstract, Template } from '@lobos/label-print';
import { SuedoConfigurationExtraInterface } from '../model/suedo-configuration-extra.interface';
import * as QRCode from 'qrcode';
import { SuedoCustomFieldsInterface } from '../model/suedo-custom-fields.interface';

@Injectable({
  providedIn: 'root',
})
export class Suedo3x8QrRenderer extends RendererAbstract<SuedoConfigurationExtraInterface> {
  public template(): Template {
    return {
      id: 'default',
      title: 'profile.label-print.domain.template.suedo3x8_qr.title',
      format: { width: 210, height: 297 },
      boxSizes: { width: 70, height: 34.4 },
      orientation: 'p',
      numberOfBoxes: { horizontal: 3, vertical: 8 },
    };
  }

  /**
   * Südo 3x8
   *
   * We try to layout the following:
   *
   *
   */

  protected async drawData(label: Label, x: number, y: number): Promise<void> {
    const labelData: LabelData = label.data as SuedoCustomFieldsInterface;
    let paddingBox: PaddingBoxInterface;

    // Image
    if (!this.configuration!.extra?.hideImage) {
      paddingBox = this.addPadding(
        x,
        y,
        this.boxWidth * (1 / 4),
        this.boxHeight * (4 / 5) - this.jspdf.getTextDimensions(labelData.date).h,
      );
      await this.addImage(labelData.imgPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    }

    //date
    await this.addDate(labelData.date, x, y);

    // QR Code
    paddingBox = this.addPadding(
      x + this.boxWidth * (3 / 4),
      y,
      this.boxWidth * (1 / 4),
      this.boxHeight * (4 / 5) - this.jspdf.getTextDimensions(labelData.sku!).h,
    );
    await this.addQrCode(labelData.sku!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);

    //logo oder Kundennummer
    if (!this.configuration!.extra?.hideLogo) {
      // Logo
      paddingBox = this.addPadding(
        x + this.boxWidth * (3 / 4),
        y + this.boxHeight * (4 / 5),
        this.boxWidth * (1 / 4),
        this.boxHeight * (1 / 5),
      );
      await this.addImage(labelData.logoPath!, paddingBox.x, paddingBox.y, paddingBox.width, paddingBox.height);
    } else {
      //customer number
      paddingBox = this.addPadding(
        x + this.boxWidth * (3 / 4),
        y + this.boxHeight * (4 / 5),
        this.boxWidth * (1 / 4),
        this.boxHeight * (1 / 5),
      );
      this.jspdf.drawText(labelData.customFields.customerArticleID, paddingBox.x, paddingBox.y, paddingBox.width, 6, 1, 'bold');
    }

    //article number
    await this.addArticleNumber(labelData.sku!, x, y);

    //article type
    await this.addArticleType(labelData.customFields?.articleType, x, y);

    // Title
    await this.addTitle(labelData.title, x, y);

    // exkl. MwSt.

    await this.addVat(x, y);

    // Amount / Unit
    paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 4),
      y + this.boxHeight * (5 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );

    this.jspdf.drawText(
      this.configuration?.extra?.hidePrice
        ? labelData.unit
        : `${
            this.configuration?.extra?.showInclPrice
              ? this.configuration?.extra?.showGrossPrice
                ? labelData.customFields?.inclGross
                : labelData.customFields?.inclNet
              : this.configuration?.extra?.showGrossPrice
              ? labelData.customFields?.exclGross
              : labelData.price
          } / ${labelData.unit}`,
      paddingBox.x,
      paddingBox.y,
      paddingBox.width,
      6,
      1,
      'normal',
      {
        baseline: 'bottom',
      },
    );
  }

  async addQrCode(code: string, x: number, y: number, maxWidth: number, maxHeight: number): Promise<void> {
    if (!code) {
      return;
    }

    await this.jspdf.drawImage(await QRCode.toDataURL(code), x, y, maxWidth, maxHeight, undefined, 'right');
  }

  async addDate(date: string, x: number, y: number) {
    const paddingBox = this.addPadding(x, y + this.boxHeight * (4 / 5), this.boxWidth * (1 / 4), this.boxHeight * (1 / 5));
    this.jspdf.drawText(date, paddingBox.x, paddingBox.y, paddingBox.width, 6, 1, 'normal');
  }

  async addArticleNumber(articleNumber: string, x: number, y: number) {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideImage ? 0 : 1 / 4),
      y,
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(articleNumber, paddingBox.x, paddingBox.y, paddingBox.width, 8, 1, 'bold');
  }

  async addArticleType(articleType: string | undefined, x: number, y: number) {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideImage ? 0 : 1 / 4),
      y + this.boxHeight * (1 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(articleType ? articleType : '', paddingBox.x, paddingBox.y, paddingBox.width, 8, 1, 'normal');
  }

  async addTitle(title: string, x: number, y: number) {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (this.configuration!.extra?.hideImage ? 0 : 1 / 4),
      y + this.boxHeight * (2 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(title, paddingBox.x, paddingBox.y, paddingBox.width, 8, 2, 'normal');
  }

  async addVat(x: number, y: number) {
    const paddingBox = this.addPadding(
      x + this.boxWidth * (1 / 4),
      y + this.boxHeight * (4 / 6),
      this.boxWidth * (1 / 2),
      this.boxHeight * (1 / 6),
    );
    this.jspdf.drawText(
      this.configuration?.extra?.hidePrice ? '' : this.configuration?.extra?.showInclPrice ? 'inkl. MwSt.' : 'exkl. MwSt.',
      paddingBox.x,
      paddingBox.y,
      paddingBox.width,
      5,
      1,
      'normal',
    );
  }
}
