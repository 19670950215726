<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="stockAvailability$ | async as stockAvailability">
    <ng-container
      *ngIf="
        (authUser$ | async)?.oP48APIAuthorizationGroup?.shtShowOpenItems &&
          (authUser$ | async)!.oP48APIAuthorizationGroup!.shtShowOpenItems === 1;
        else guest
      "
    >
      <div class="flex">
        <p *ngIf="showLabel">
          <span [class]="font">{{ t("core.article-availability.title") }}</span>
          <!-- test -->
        </p>
        <div class="flex ml-2">
          <div class="flex items-center space-x-1 js-availability relative cursor-help">
            <ng-container *ngIf="stockAvailability.decAvailable <= 0">
              <span class="w-1.5 h-4 bg-state1"></span>
              <span class="w-1.5 h-4 bg-gray-700"></span>
              <span class="w-1.5 h-4 bg-gray-700"></span>
            </ng-container>

            <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable < quantity">
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-gray-700"></span>
            </ng-container>

            <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable >= quantity">
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-available"></span>
            </ng-container>

            <p
              [ngClass]="showPopoverTop ? '-translate-y-4 translate-x-1/2' : 'translate-y-12 translate-x-20'"
              class="absolute bg-white z-10 -top-full right-0 text-xs font-bold flex items-center border rounded-md border-secondary shadow-sm py-1 px-2 whitespace-nowrap"
            >
              <ng-container *ngIf="stockAvailability.decAvailable <= 0">
                {{ t("core.article-availability.out-of-stock") }}
              </ng-container>

              <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable < quantity">
                {{ t("core.article-availability.partial-delivery") }}
              </ng-container>

              <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable >= quantity">
                {{
                  t("core.article-availability.available-stock", {
                    amount: stockAvailability.decAvailable,
                    unit: stockAvailability.sQuantityUnit
                  })
                }}
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #guest>
      <div class="flex">
        <p *ngIf="showLabel">
          <span [class]="font">{{ t("core.article-availability.title") }}</span>
          <!-- test -->
        </p>
        <div class="flex ml-2">
          <div class="flex items-center space-x-1 js-availability relative cursor-help">
            <ng-container *ngIf="stockAvailability.decAvailable <= 0">
              <span class="w-1.5 h-4 bg-state1"></span>
              <span class="w-1.5 h-4 bg-gray-700"></span>
              <span class="w-1.5 h-4 bg-gray-700"></span>
            </ng-container>

            <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable < quantity">
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-gray-700"></span>
            </ng-container>

            <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable >= quantity">
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-available"></span>
              <span class="w-1.5 h-4 bg-available"></span>
            </ng-container>

            <p
              [ngClass]="showPopoverTop ? '-translate-y-4 translate-x-1/2' : 'translate-y-12 translate-x-20'"
              class="absolute bg-white z-10 -top-full right-0 text-xs font-bold flex items-center border rounded-md border-secondary shadow-sm py-1 px-2 whitespace-nowrap"
            >
              <ng-container *ngIf="stockAvailability.decAvailable <= 0">
                {{ t("core.article-availability.out-of-stock") }}
              </ng-container>

              <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable < quantity">
                {{ t("core.article-availability.partial-delivery") }}
              </ng-container>

              <ng-container *ngIf="stockAvailability.decAvailable > 0 && stockAvailability.decAvailable >= quantity">
                {{ t("core.article-availability.available") }}
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
