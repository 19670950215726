import { Article, SearchResponseHit, SearchResponseHits } from '@lobos/library';

export function buildSearchQuery(searchString: string) {
  return {
    bool: {
      should: [
        {
          multi_match: {
            query: searchString,
            fields: [
              'sArticleID.keyword^5',
              'sEAN.keyword^5',
              'oProductInfo.keywords.keyword^1',
              'sArticleCode1.keyword^6',
              'sForerunner.keyword^1',
              'sPL1ETestNumber.keyword^4',
              'sExternalArticleID.keyword^5',
              'sCustomerArticleID.keyword^4',
            ],
            boost: 2.0,
          },
        },
        {
          multi_match: {
            query: searchString,
            fields: [
              'sArticleID.autocomplete^4',
              'sName.clean^6',
              'sCustomerArticleID.autocomplete^4',
              'sUniformNumber^4',
              'oProductInfo.sTitle^2',
            ],
            // fuzziness: 'auto',
          },
        },
      ],
    },
  };
}

export function buildCategorySearchQuery(searchString: string) {
  return {
    multi_match: {
      query: searchString,
      fields: ['sTitle^5', 'keywords^2'],
      fuzziness: 'AUTO',
      minimum_should_match: '66%',
    },
  };
}

export function buildMultiSearchQuery(searchString: string, size = 5, articleIndex: string, categoryIndex: string) {
  return [
    { index: articleIndex },
    {
      size: size,
      query: buildSearchQuery(searchString),
    },
    { index: categoryIndex },
    {
      size: size,
      query: buildCategorySearchQuery(searchString),
    },
  ];
}

export function parseHits(hits: SearchResponseHits<Article>) {
  const articles = hits.hits.map((article: SearchResponseHit<Article>) => {
    return { score: article._score, ...article._source };
  });
  return { total: hits.total.value, hits: articles };
}
