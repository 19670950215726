import { Component, Input } from '@angular/core';
import { catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { ScannerAdapterAbstract } from '@lobos/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScanNotificationService } from '../scan-notification.service';
import { ScannerComponent } from '../scanner/scanner.component';
import { SimpleModalService } from 'ngx-simple-modal';

@UntilDestroy()
@Component({
  selector: 'app-scanner-button',
  templateUrl: './scanner-button.component.html',
  styleUrls: ['./scanner-button.component.scss'],
})
export class ScannerButtonComponent {
  @Input() classes: string = '';

  constructor(
    private scannerAdapter: ScannerAdapterAbstract,
    private dialog: SimpleModalService,
    private scanNotification: ScanNotificationService,
  ) {}

  public openScanner(): void {
    this.scanNotification.prepare();
    this.scannerAdapter
      .askForPermission()
      .pipe(
        catchError((error: Error) => of(!this.scannerAdapter.handleError(error))),
        filter((result: boolean) => result),
        untilDestroyed(this),
      )
      .subscribe(() => this.dialog.addModal(ScannerComponent, undefined, { closeOnClickOutside: true }));
  }
}
