import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ObjectHelperService {
  /**
   * Remove nullish values from an object
   */
  public cleanObject(obj: Record<string, unknown>): Record<string, unknown> {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }
}
