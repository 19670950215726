import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsUiDynamicContentService } from '@lobos/library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { SuedoCmsUiDynamicContentPage } from './cms.models';

@Injectable({ providedIn: 'root' })
export class SuedoCmsUiDynamicContentService extends CmsUiDynamicContentService {
  override getCMSPage(id: number, ignoreLocale: boolean = false): Observable<SuedoCmsUiDynamicContentPage> {
    const selector = `/cms/pages/${id}`;

    return this.http
      .get<SuedoCmsUiDynamicContentPage>(`${this.config.apiUrl}/pages/${id}`, {
        params: { _context: environment.cms.cmsMainMenu },
        headers: ignoreLocale ? new HttpHeaders({ ignoreLocale: 'true' }) : undefined,
      })
      .pipe(
        tap((response: SuedoCmsUiDynamicContentPage) => {
          this.store.update({ [selector]: response });
        }),
      );
  }
}
