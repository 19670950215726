import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CmsUiDynamicContentMenu,
  CmsUiDynamicContentPage,
  CmsUiDynamicContentService,
  CmsUiDynamicContentStore,
  Footer,
  STRAPI_CMS_CONFIG,
  StrapiCmsConfig,
} from '@lobos/library';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CmsDownload } from './model/cms-download';

@Injectable({ providedIn: 'root' })
export class CmsSuedoService extends CmsUiDynamicContentService {
  constructor(store: CmsUiDynamicContentStore, http: HttpClient, @Inject(STRAPI_CMS_CONFIG) config: StrapiCmsConfig) {
    super(store, http, config);
  }

  override getHomePage() {
    return this.http.get<CmsUiDynamicContentPage>(this.config.apiUrl + '/' + environment.cms.homepage).pipe(
      tap((response: CmsUiDynamicContentPage) => {
        this.store.update({ homePage: response });
      }),
    );
  }

  override getFooter() {
    const selector = `${this.config.apiUrl}/${environment.cms.footer}`;

    return this.http.get<Footer>(selector).pipe(
      tap((response: Footer) => {
        this.store.update({ footer: response });
      }),
    );
  }

  override getErrorPage(): Observable<CmsUiDynamicContentPage> {
    return this.http.get<CmsUiDynamicContentPage>(this.config.apiUrl + '/' + environment.cms.error).pipe(
      tap((response: CmsUiDynamicContentPage) => {
        this.store.update({ errorPage: response });
      }),
    );
  }

  override getCmsMainMenu(): Observable<CmsUiDynamicContentMenu> {
    return this.http
      .get<CmsUiDynamicContentMenu>(this.config.apiUrl + '/' + environment.cms.cmsMainMenu)
      .pipe(tap((response: CmsUiDynamicContentMenu) => this.store.update({ cmsMainMenu: response })));
  }

  getDownloads(): Observable<CmsDownload[]> {
    return this.http.get<CmsDownload[]>(this.config.apiUrl + '/downloads');
  }
}
