import { Pipe, PipeTransform } from '@angular/core';
import { Article, Feature, Product } from '@lobos/library';

@Pipe({
  name: 'productFeatures',
})
export class ProductFeaturesPipe implements PipeTransform {
  public transform(product: Product): Feature[] {
    const firstArticleFeatures: Feature[] = product.oArticles[0]?.oFeatures;

    if (!firstArticleFeatures) {
      return [];
    }

    if (product.oArticles.length === 1) {
      return product.oArticles[0].oFeatures.filter((feature: Feature) => ![1, 2].includes(feature.lngFeatureID));
    }

    const articles = product.oArticles.slice(1);

    const featuresOfAllArticles: Feature[] = articles
      .map((article: Article) => article.oFeatures)
      .reduce((prev, current) => [...prev, ...current]);

    const intersectingFeatures = firstArticleFeatures.filter(
      (feature) =>
        featuresOfAllArticles.filter((allFeature) => allFeature.sName === feature.sName && allFeature.sValue === feature.sValue).length ===
        articles.length,
    );

    return intersectingFeatures.filter((feature: Feature) => ![1, 2].includes(feature.lngFeatureID));
  }
}
