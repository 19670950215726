import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() config!: PaginationInstance;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPageChange = new EventEmitter<any>();

  pageChangeHandler(value: any) {
    this.onPageChange.emit(value);
    window.scroll({ top: 0, behavior: 'smooth' });
  }
}
