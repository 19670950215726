import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalComponent } from 'ngx-simple-modal';
import {
  AuthService,
  CartHeader,
  CartService,
  createCartHeader,
  ShippingAddress,
  ShippingAddressQuery,
  ShippingAddressService,
  SteelCartItem,
  SteelCreateCartItemInterface,
  User,
} from '@lobos/library';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { iif, Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { AddressHelperService } from '../../../../../../services/catalog/address-helper.service';
import { ObjectHelperService } from '../../../../../../services/object/object-helper.service';

export interface CartHeaderData {
  cartHeader: CartHeader | null | undefined;
}

@UntilDestroy()
@Component({
  selector: 'app-add-edit-cart-modal',
  templateUrl: './add-edit-cart-modal.component.html',
  styleUrls: ['./add-edit-cart-modal.component.scss'],
})
export class AddEditCartModalComponent extends SimpleModalComponent<CartHeaderData, null> implements CartHeaderData, OnInit {
  cartHeader: CartHeader | null | undefined;
  public form: UntypedFormGroup = this.fb.group({
    sCartName: ['', Validators.required],
    lngAddressID: [null],
    sMemo: [''],
  });

  public shippingAddresses$: Observable<ShippingAddress[]> = of(undefined).pipe(
    switchMap(() =>
      iif(
        () => this.shippingAddressQuery.getHasCache(),
        this.shippingAddressQuery.selectAll(),
        this.shippingAddressService.getSimple().pipe(switchMap(() => this.shippingAddressQuery.selectAll())),
      ),
    ),
  );

  public loading: boolean = false;

  constructor(
    private cartService: CartService<CartHeader, SteelCartItem, SteelCreateCartItemInterface>,
    private fb: UntypedFormBuilder,
    private shippingAddressService: ShippingAddressService<ShippingAddress>,
    private shippingAddressQuery: ShippingAddressQuery<ShippingAddress>,
    private authService: AuthService,
    private objectHelper: ObjectHelperService,
    private addressHelper: AddressHelperService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.cartHeader) {
      this.form.patchValue({ ...this.cartHeader, lngAddressID: this.cartHeader.oDeliveryAddress.lngAddressID || null });
    }

    this.shippingAddresses$.pipe(first(), untilDestroyed(this)).subscribe((shippingAddresses: ShippingAddress[]) => {
      if (this.cartHeader) {
        const address: ShippingAddress | undefined = shippingAddresses.find((shippingAddress: ShippingAddress) =>
          this.addressHelper.isEqual(shippingAddress, this.cartHeader!.oDeliveryAddress),
        );
        if (address) {
          this.form.patchValue({ lngAddressID: address.lngAddressID });
        }
      }
    });
  }

  public save(cart: CartHeader | undefined | null): void {
    this.loading = true;
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    of(this.form.value)
      .pipe(
        map((formValues: Record<string, unknown>) => this.objectHelper.cleanObject(formValues)),
        map((formValues: Record<string, unknown>) => ({
          ...formValues,
          oDeliveryAddress: this.shippingAddressQuery.getEntity(formValues['lngAddressID']),
        })),
        switchMap((formValues: Record<string, unknown>) =>
          iif(
            () => !!this.cartHeader,
            // true
            this.cartService.updateCartHeader({ ...cart!, ...formValues }),

            // false
            this.authService.authUser$.pipe(
              filter((user: User | undefined) => !!user),
              switchMap((user: User | undefined) =>
                this.cartService.createCartHeader({
                  ...createCartHeader({ sCartName: '' }),
                  ...formValues,
                  lngContactID: user!.lngContactID,
                  lngCustomerID: user!.lngCustomerID,
                }),
              ),
            ),
          ),
        ),
        first(),
        untilDestroyed(this),
      )
      .subscribe(() => this.close());
  }
}
