import { Injectable } from '@angular/core';
import { ErrorHook, ErrorInterface, HookEvent, HookHandlerAbstract, SentryService } from '@lobos/library';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

type type = ErrorHook;

@Injectable({
  providedIn: 'root',
})
export class ErrorSendToSentryHandler extends HookHandlerAbstract<type, Observable<ErrorInterface>> {
  constructor(private sentry: SentryService) {
    super();
  }

  private authErrors = ['Ungültige Sitzung', 'Ungültiges Passwort', 'Benutzer ungültig'];

  ids(): type[] {
    return [{ type: 'ErrorHook' }];
  }

  override after(event: HookEvent<type, Observable<ErrorInterface>>, result: Observable<ErrorInterface>): Observable<ErrorInterface> {
    return super.after(event, result).pipe(
      filter(
        (error: ErrorInterface) =>
          !(error.error && error.error.error && error.error.error.text && this.authErrors.includes(error.error.error.text)),
      ),
      tap((error: ErrorInterface) => (error.ignore ? undefined : this.sentry.captureException(error))),
    );
  }
}
