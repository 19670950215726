import { Cart, CartHeader, CartItem, CartService, CreateCartItemInterface } from '@lobos/library';
import { Observable, of } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { QtyModalComponent } from '../../../../components/qty-modal/qty-modal.component';
import { switchMap } from 'rxjs/operators';

export function IsPartialPackingUnitNeeded(oCartItem: CreateCartItemInterface) {
  if (oCartItem.oArticle.decQuantityPackage > 0) {
    let decQuantityDown = oCartItem.decQuantity / oCartItem.oArticle.decQuantityPackage;
    decQuantityDown = Math.floor(decQuantityDown);
    // rounding up
    let decQuantityUp = oCartItem.decQuantity / oCartItem.oArticle.decQuantityPackage;
    decQuantityUp = Math.ceil(decQuantityUp);

    if (decQuantityUp < oCartItem.oArticle.decQuantityPackage) {
      decQuantityUp = oCartItem.oArticle.decQuantityPackage;
    }
    if (
      oCartItem.decQuantity != decQuantityDown * oCartItem.oArticle.decQuantityPackage &&
      oCartItem.decQuantity != decQuantityUp * oCartItem.oArticle.decQuantityPackage
    ) {
      return true;
    }
  }
  return false;
}

export function HandleQtyInput(
  oCartItem: CreateCartItemInterface,
  callable: (params?: any[]) => Observable<Cart<CartHeader, CartItem> | undefined>,
  modal: SimpleModalService,
  cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
  bCreateMode: boolean,
): (params?: any[]) => Observable<Cart<CartHeader, CartItem> | undefined> {
  if (IsPartialPackingUnitNeeded(oCartItem)) {
    return () =>
      modal.addModal<CreateCartItemInterface | CartItem, CreateCartItemInterface | CartItem | undefined>(QtyModalComponent, oCartItem).pipe(
        switchMap((cartItem: CreateCartItemInterface | CartItem | undefined) => {
          if (!!cartItem && bCreateMode) {
            return cartService.createCartItem({
              ...(cartItem as CreateCartItemInterface),
            });
          }

          if (!!cartItem && !bCreateMode) {
            return cartService.updateCartItem({
              ...(cartItem as CartItem),
            });
          }
          return of(undefined);
        }),
      );
  } else {
    return callable;
  }
}
