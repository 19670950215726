import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Catch } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageHelperService } from '../../../services/language/language-helper.service';
import { NavigationInterface } from '../header.component';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
})
export class MobileNavigationComponent {
  @Input() navigationMenu: NavigationInterface[] | undefined | null;
  @Output() closeMobileMenu: EventEmitter<Event> = new EventEmitter<Event>();

  public alternative$ = this.languageHelper.alternative$;
  public currentVersionKey$ = this.transloco.langChanges$;

  constructor(private transloco: TranslocoService, private languageHelper: LanguageHelperService) {}

  @Catch({ label: 'MobileNavigationComponent.changeVersion()' })
  public async changeVersion(versionKey: string): Promise<void> {
    await this.languageHelper.changeVersion(versionKey);
  }
}
