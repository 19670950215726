<!-- start modal -->
<ng-container *transloco="let t; scope: 'shared'">
  <div class="js-login-modal fixed z-60 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 bg-gray-200 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <form
        class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="block absolute top-0 right-0 pt-4 pr-4 z-10">
          <button
            (click)="cancel()"
            type="button"
            class="js-close-modal bg-white p-2 inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary rounded"
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div class="flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
            <h1 class="text-lg leading-6 font-bold" id="modal-title">
              {{ t("shared.qty-modal.header") }}
            </h1>
            <div class="mt-2">
              <h3 class="text-lg hover:text-primary">
                {{ oCartItem.oArticle.sName }}
              </h3>
              <p class="text-sm mt-2">
                <span *ngIf="!(oCartItem.oArticle.shtNoPackageBreak === 4 || oCartItem.oArticle.shtNoPackageBreak === 5)">
                  {{
                    t("shared.qty-modal.msg", {
                      decQuantityDefault: decQuantityDefault,
                      sQuantityUnit: oCartItem.sQuantityUnit
                    })
                  }}
                </span>
                <span *ngIf="oCartItem.oArticle.shtNoPackageBreak === 4 || oCartItem.oArticle.shtNoPackageBreak === 5">
                  {{
                    t("shared.qty-modal.msg-rounded", {
                      decQuantityDefault: decQuantityDefault,
                      sQuantityUnit: oCartItem.sQuantityUnit
                    })
                  }}
                </span>
              </p>
            </div>
            <div class="mt-5 sm:mt-4 flex flex-row justify-between space-x-4">
              <button
                *ngIf="decQuantityDown > 0 && [0, 1, 3, 5].includes(oCartItem.oArticle.shtNoPackageBreak)"
                (click)="roundDown()"
                type="button"
                class="w-auto mt-2 inline-flex items-center px-1.5 md:px-3.5 py-1.5 border border-transparent text-xs font-bold rounded shadow-sm text-primary bg-primary text-white hover:bg-secondary group focus:bg-secondary"
              >
                <span class="text-sm xl:text-base font-bold" *ngIf="oCartItem.oArticle.shtNoPackageBreak !== 5">
                  {{ t("shared.qty-modal.round-off") }} {{ decQuantityDown }} {{ oCartItem.sQuantityUnit }}
                </span>
                <span class="text-sm xl:text-base font-bold" *ngIf="oCartItem.oArticle.shtNoPackageBreak === 5">
                  {{ t("shared.qty-modal.round-off-always") }} {{ decQuantityDown }} {{ oCartItem.sQuantityUnit }}
                </span>
              </button>
              <span *ngIf="decQuantityDown === 0">&nbsp;</span>
              <button
                *ngIf="decQuantityUp > 0 && [0, 1, 2, 4].includes(oCartItem.oArticle.shtNoPackageBreak)"
                (click)="roundUp()"
                type="button"
                class="w-auto mt-2 inline-flex items-center px-1.5 md:px-3.5 py-1.5 border border-transparent text-xs font-bold rounded shadow-sm text-primary bg-primary text-white hover:bg-secondary group focus:bg-secondary"
              >
                <span class="text-sm xl:text-base font-bold" *ngIf="oCartItem.oArticle.shtNoPackageBreak !== 4">
                  {{ t("shared.qty-modal.round-up") }} {{ decQuantityUp }} {{ oCartItem.sQuantityUnit }}
                </span>
                <span class="text-sm xl:text-base font-bold" *ngIf="oCartItem.oArticle.shtNoPackageBreak === 4">
                  {{ t("shared.qty-modal.round-up-always") }} {{ decQuantityUp }} {{ oCartItem.sQuantityUnit }}
                </span>
              </button>
              <span *ngIf="decQuantityUp === 0">&nbsp;</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
<!-- end modal -->
