import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cart-error-modal',
  templateUrl: './cart-error-modal.component.html',
  styleUrls: ['./cart-error-modal.component.scss'],
})
export class CartErrorModalComponent extends SimpleModalComponent<null, null> {
  constructor() {
    super();
  }

  protected readonly environment = environment;
}
