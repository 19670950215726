<div class="mt-4">
  <label [for]="name" class="block text-sm font-bold" [class.text-error]="hasError">
    {{ label }}
    <ng-container *ngIf="required">*</ng-container>
  </label>
  <div class="mt-1">
    <ng-container *ngIf="form" [formGroup]="form">
      <input
        [maxlength]="maxlength"
        [type]="type"
        [name]="name"
        [attr.min]="min"
        [placeholder]="placeholder"
        [required]="required"
        [formControlName]="name"
        [class.border-error]="hasError"
        [disabled]="disabled"
        tabindex="0"
        autocapitalize="none"
        class="rounded shadow-sm px-4 py-3 block w-full sm:text-sm placeholder:text-gray-700 focus:ring-1 focus:ring-primary focus:border-primary border"
      />
    </ng-container>

    <input
      [maxlength]="maxlength"
      *ngIf="!form"
      [type]="type"
      [name]="name"
      [attr.min]="min"
      [placeholder]="placeholder"
      [required]="required"
      [(ngModel)]="value"
      (ngModelChange)="valueChangeDebouncer.next(value)"
      [class.border-error]="hasError"
      [disabled]="disabled"
      tabindex="0"
      autocapitalize="none"
      class="rounded shadow-sm px-4 py-3 block w-full sm:text-sm placeholder:text-gray-700 focus:ring-1 focus:ring-primary focus:border-primary border"
    />
  </div>
  <button
    *ngIf="enableEditButtonLabel"
    (click)="editButtonClick.emit()"
    class="block text-sm text-black hover:text-primary my-2 flex items-center"
  >
    {{ enableEditButtonLabel }}
  </button>
</div>
<p *ngIf="hasError && errorText" class="font-primary text-sm text-error my-2">{{ errorText }}</p>
