import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  Provider,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_SELECT_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomSelectComponent),
  multi: true,
};
@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_SELECT_VALUE_ACCESSOR],
})
export class CustomSelectComponent implements ControlValueAccessor {
  @Input() public options: { key: string | number; value: string }[] = [];

  @Input() public label: string = '';
  @Input() public additionalLabel: string = '';
  @Input() public enableEditButtonLabel: string = '';
  @Input() public value: string | number | null = '';

  @Input() public placeholder: string = '';

  @Input() public required: boolean = false;

  @Input() public hasError: boolean = false;
  @Input() public errorText: string = '';

  @Input() public clearable: boolean = false;
  @Input() public translateValue: boolean = false;
  @Input() public nullValue: string = '';

  @Output() public valueChange: EventEmitter<string | number> = new EventEmitter<string | number>();
  @Output() public clearAll: EventEmitter<void> = new EventEmitter<void>();

  @Output() public editButtonClick: EventEmitter<void> = new EventEmitter<void>();

  public isOpen: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  private onChanged = (_: any) => {};

  @HostListener('document:click', ['$event'])
  clickOut(event: Event) {
    if (!this.eRef.nativeElement.contains(event.target) && this.isOpen) {
      this.toggleIsOpen();
    }
  }

  constructor(private eRef: ElementRef) {}

  changeOption(selected: string | number | null) {
    this.onTouched();
    this.value = selected;
    this.onChanged(selected);
    if (typeof selected !== null) {
      this.valueChange.emit(selected as string | number);
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  toggleIsOpen(): void {
    this.isOpen = !this.isOpen;
  }
  resetValue(): void {
    this.value = '';
  }
}
