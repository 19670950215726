<ng-container *transloco="let t; scope: 'shared'">
  <pagination-template class="pagination" #p="paginationApi" [id]="$any(config.id)" (pageChange)="pageChangeHandler($event)">
    <nav class="py-3 items-center border-t border-gray-200" aria-label="Pagination">
      <div>
        <a
          (click)="p.previous()"
          *ngIf="!p.isFirstPage()"
          class="mr-5 relative inline-flex items-center px-4 py-2 border border-primary text-sm rounded-md text-primary bg-white hover:border-secondary hover:text-secondary"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7"></path>
          </svg>
        </a>
        <a
          [class.disabled]="p.isLastPage()"
          (click)="p.next()"
          *ngIf="!p.isLastPage()"
          class="relative inline-flex items-center px-4 py-2 border border-primary text-sm rounded-md text-primary bg-white hover:border-secondary hover:text-secondary"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>

      <div class="hidden sm:block mt-5">
        <p class="text-sm">
          {{ t("shared.pagination.showing") }}
          <span class="font-bold">{{ 1 + config.itemsPerPage * (p.getCurrent() - 1) }}</span>
          {{ t("shared.pagination.to") }}
          <span class="font-bold">{{
            config.itemsPerPage * p.getCurrent() > p.getTotalItems() ? p.getTotalItems() : config.itemsPerPage * p.getCurrent()
          }}</span>
          {{ t("shared.pagination.of") }}
          <span class="font-bold">{{ p.getTotalItems() }}</span>
          {{ t("shared.pagination.results") }}
        </p>
      </div>
    </nav>
  </pagination-template>
</ng-container>
