<div class="fixed z-60 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div
      class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
    >
      <div class="w-full flex items-end flex-col">
        <button
          (click)="stop()"
          type="button"
          class="js-close-filter inline-flex items-center justify-center hover:text-primary hover:bg-gray-100 focus:text-primary"
        >
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div class="sm:flex sm:items-start">
        <div class="mt-3 text-center sm:mt-0 sm:text-left">
          <div class="mt-2">
            <div class="scanner" [class.ready]="scannerReady$ | async" [class.scan-complete]="articles">
              <div class="choose-camera" *ngIf="cameras$ | async as cameras">
                <app-custom-select
                  *transloco="let t; scope: 'scanner'"
                  [options]="cameras"
                  (valueChange)="selectCamera($event)"
                  [placeholder]="t('scanner.ui-scanner.select.placeholder')"
                ></app-custom-select>
              </div>

              <div class="scanner-preview">
                <div #preview class="scanner-container"></div>
                <div *ngIf="(scannerReady$ | async) === true"></div>
              </div>

              <div class="scanner-result text-centered" *ngIf="articles">
                <app-scanner-results [articles]="articles"></app-scanner-results>

                <ng-container *transloco="let t; scope: 'scanner'">
                  <app-text-button [text]="t('scanner.ui-scanner.button.rescan')" (click)="rescan()"></app-text-button>

                  <br />
                  <app-text-button [text]="t('scanner.ui-scanner.button.stop')" (click)="stop()"></app-text-button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex space-x-4"></div>
    </div>
  </div>
</div>
