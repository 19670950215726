import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import {
  AuthService,
  CartHeader,
  CartItem,
  CartService,
  CreateCartItemInterface,
  FavoriteItem,
  FavoriteItemQuery,
  FavoriteList,
  FavoriteListQuery,
  FavoriteService,
} from '@lobos/library';
import { merge, Observable, of, Subject } from 'rxjs';
import { filter, finalize, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ID } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalService } from 'ngx-simple-modal';
import { AddEditFavoriteModalComponent } from '../../../../features/profile/profile-features/favorites/favorites-components/add-edit-favorite-modal/add-edit-favorite-modal.component';
import { isPlatformBrowser } from '@angular/common';
import { CartBulkAddService } from '../../../../services/cart/cart-bulk-add.service';

@UntilDestroy()
@Component({
  selector: 'app-favorites-flyout',
  templateUrl: './favorites-flyout.component.html',
  styleUrls: ['./favorites-flyout.component.scss'],
})
export class FavoritesFlyoutComponent implements OnInit {
  @Input()
  public classes: string = '';
  public isLoading: boolean = false;
  public mobile: boolean | undefined;
  public isLoggedIn$: Observable<boolean> = this.authService.isLoggedIn$;

  bulkAddLoading: boolean = false;

  @Output() closeMenu: EventEmitter<void> = new EventEmitter();

  activeFavoriteList$: Observable<FavoriteList | undefined> = this.favoriteListQuery.selectActive();
  activeFavoriteItems$: Observable<FavoriteItem[]> = this.favoriteListQuery.selectActiveId().pipe(
    filter((id: ID | null | undefined) => !!id),
    switchMap((id: ID | null | undefined) => this.favoriteItemQuery.selectFavoriteItems(id!)),
  );
  favoriteLists$: Observable<{ key: string; value: string }[]> = this.favoriteListQuery.selectAll().pipe(
    map((favorites: FavoriteList[]) =>
      favorites.map((favorite: FavoriteList) => ({
        key: favorite.gListID.toString(),
        value: favorite.sListname,
      })),
    ),
  );

  constructor(
    private favoriteListQuery: FavoriteListQuery<FavoriteList>,
    private favoriteItemQuery: FavoriteItemQuery<FavoriteItem>,
    private favoriteService: FavoriteService<FavoriteList, FavoriteItem>,
    private cartService: CartService<CartHeader, CartItem, CreateCartItemInterface>,
    private modal: SimpleModalService,
    private authService: AuthService,
    private cartBulkAddService: CartBulkAddService,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  setActiveFavoriteList(favoriteListId: string | number) {
    this.favoriteService.getFavoriteItems(favoriteListId).pipe(take(1), untilDestroyed(this)).subscribe();
    this.favoriteService.setActiveFavoriteId(favoriteListId);
  }

  addFavoriteList(): void {
    this.modal.addModal(AddEditFavoriteModalComponent);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId) && window.screen.width < 1279) {
      this.mobile = true;
    }
  }

  async bulkAddToCart(items: FavoriteItem[]) {
    try {
      this.bulkAddLoading = true;
      await this.cartBulkAddService.addAllToCart(items);
    } catch (error) {
      console.error('Error occurred while adding items to the cart:', error);
    } finally {
      this.bulkAddLoading = false;
    }
  }

  addAllToCart() {
    // end convert stream
    const end = new Subject<boolean>();
    const end$ = of(undefined).pipe(
      tap(() => {
        end.next(true);
        end.complete();
      }),
    );

    this.isLoading = true;
    // convert favorite items to cart items
    this.activeFavoriteItems$
      .pipe(
        switchMap((favorites: FavoriteItem[]) =>
          merge([
            ...favorites
              .filter((f: FavoriteItem) => f.decQuantity > 0)
              .map((favoriteItem: FavoriteItem) =>
                this.cartService.createCartItem({
                  decQuantity: favoriteItem.decQuantity,
                  sArticleID: favoriteItem.oArticle!.sArticleID as string,
                  sArticleName: favoriteItem.oArticle!.sName,
                  sQuantityUnit: favoriteItem.oArticle!.sQuantityUnitSales,
                  oArticle: favoriteItem.oArticle!,
                  emitHook: false,
                }),
              ),
            end$,
          ]),
        ),
        mergeMap(($req) => $req, 1),
        untilDestroyed(this),
        takeUntil(end),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
