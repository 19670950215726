import { Injectable } from '@angular/core';
import { CmsUiDynamicContentQuery } from '@lobos/library';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SuedoCmsUiDynamicContentPage } from './cms.models';

@Injectable({
  providedIn: 'root',
})
export class SuedoCmsUiDynamicContentQuery extends CmsUiDynamicContentQuery {
  public override page(id: number): Observable<SuedoCmsUiDynamicContentPage> {
    return this.select(`/cms/pages/${id}`).pipe(filter((data) => !!data));
  }
}
