<ng-container *ngIf="primary; else notPrimary">
  <button
    [type]="type"
    class="mt-2 inline-flex items-center px-1.5 md:px-3.5 py-1.5 text-white text-xs font-bold rounded shadow-sm bg-primary hover:bg-secondary group focus:bg-secondary"
    [ngClass]="classes"
    [disabled]="disabled"
  >
    <span class="text-sm xl:text-base font-bold">{{ text }}</span>
    <span [class.loading]="isLoading"></span>
  </button>
</ng-container>
<ng-template #notPrimary>
  <button
    [disabled]="disabled"
    [type]="type"
    class="mt-2 inline-flex items-center px-1.5 md:px-3.5 py-1.5 rounded shadow-sm bg-white border border-primary hover:border-secondary group focus:border-secondary"
    [ngClass]="classes"
  >
    <span class="text-sm xl:text-base font-bold text-primary group-focus:text-secondary group-hover:text-secondary">{{ text }}</span>
    <span [class.loading]="isLoading"></span>
  </button>
</ng-template>
