import { Component, Input } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UntypedFormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DatepickerComponent {
  @Input() public name: string = '';
  @Input() public shippingType: number | undefined;

  @Input() public hasError: boolean = false;
  @Input() public errorText: string = '';

  @Input() public form: UntypedFormGroup | undefined;

  constructor(private _adapter: DateAdapter<any>, private translocoService: TranslocoService) {
    translocoService.langChanges$
      .pipe(
        tap((lang: string) => this._adapter.setLocale(lang)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public disableWeekend(d: any): boolean {
    if (!d) {
      return true;
    }

    const day = d.weekday();
    return this.shippingType === 25 ? day !== 6 : day !== 5 && day !== 6;
  }

  boundDisableWeekend = this.disableWeekend.bind(this);

  getNextBusinessDay(): Date {
    const date = new Date();
    const dayOfWeek = date.getDay();

    if (this.shippingType === 25) {
      if (dayOfWeek === 5) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    }

    if (this.shippingType === 3) {
      return date;
    } else {
      do {
        date.setDate(date.getDate() + 1);
      } while (!(date.getDay() % 6));
      return date;
    }
  }
}
